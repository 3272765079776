import {
    Case_Overview,
    Patient_Details,
    VC_Case,
    VC_Consultation,
    VC_Diagnose,
    VC_Diagnostic,
    VC_Discharge,
    VC_LabReport,
    VC_Medical_History,
    VC_Pathogen,
    VC_Post_Treatment,
    VC_Task,
    VC_Therapy,
    VC_Visit_Record,
    VcPatientListItem,
    ViewContent
} from './../models/view-content.models/view-content.model';
import {Place} from '../models/view-content.models/view-content-organization-domain.model';

export const dummyVC_Case: VC_Case = {
    id: '850e8400-e29b-41d4-a716-446655440333',
    related_case_id: '850e8400-e29b-41d4-a716-446655440333',
    locator: 'case.850e8400-e29b-41d4-a716-446655440333',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['cardiology'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    data: {
        patient: {
            surname: 'Mustermann',
            name: 'Max',
            gender: 'männlich', // Translated gender
            birth_date: '1980-01-01',
            valid_from: '2024-01-01',
            valid_until: '2025-01-01'
        },
        admission_date: '2024-08-01',
        discharge_date: '2024-08-10',
        planned_discharge_date: '2024-08-10',
        department: {
            centers: [
                {
                    location: {
                        corporation: {
                            code: 'CORP01',
                            shortName: 'KonzernKurz', // Translated shortName
                            name: 'Konzernname', // Translated name
                            validSince: '2024-01-01'
                        },
                        code: 'LOC01',
                        shortName: 'OrtKurz', // Translated shortName
                        name: 'Ortsname', // Translated name
                        validSince: '2024-01-01'
                    },
                    code: 'CTR01',
                    shortName: 'ZentrumKurz', // Translated shortName
                    name: 'Zentrumname', // Translated name
                    validSince: '2024-01-01'
                }
            ],
            code: 'DEPT01',
            shortName: 'AbteilungKurz',
            name: 'Abteilungsname',
            validSince: '2024-01-01'
        },
        start: '2024-08-01T08:00:00Z',
        end: '2024-08-10T15:00:00Z'
    },
    history: []
};

export const dummyVC_Place: ViewContent<Place> = {
    id: '650e8400-e29b-41d4-a716-446655440111',
    related_case_id: 'related_case_id',
    locator: 'place-650e8400-e29b-41d4-a716-446655440111',
    owners: ['7edc1d64-c6f7-4b2d-8121-333cd9844573'],
    owner_departments: ['general'],
    main_owner_job_type: 'nurse',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',

    data: {
        room: {
            service_unit: {
                departments: [
                    {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP01',
                                        shortName: 'KonzernKurz',
                                        name: 'Konzernname',
                                        validSince: '2024-01-01'
                                    },
                                    code: 'LOC01',
                                    shortName: 'OrtKurz',
                                    name: 'Ortsname',
                                    validSince: '2024-01-01'
                                },
                                code: 'CTR01',
                                shortName: 'ZentrumKurz',
                                name: 'Zentrumname',
                                validSince: '2024-01-01'
                            }
                        ],
                        code: 'DEPT01',
                        shortName: 'AbteilungKurz',
                        name: 'Abteilungsname',
                        validSince: '2024-01-01'
                    }
                ],
                code: 'SU01',
                short_text: 'SUEinheitKurz',
                long_text: 'Dienstleistungseinheit Name',
                valid_since: '2024-01-01'
            },
            code: 'ROOM01',
            short_text: 'ZimmerKurz',
            long_text: '201-C',
            valid_since: '2024-01-01'
        },
        code: 'PLACE01',
        shortName: 'OrtKurz',
        name: 'Bett 01',
        validSince: '2024-01-01'
    },

    history: []
};

export const dummyVC_Diagnose: VC_Diagnose = {
    id: 'a50e8400-e29b-41d4-a716-446655440555',
    locator: 'diagnose-a50e8400-e29b-41d4-a716-446655440555',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['radiology'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    data: {
        diagnose: 'Meningitis, Hypertonie',
        created_at: '2024-04-12T09:20:00Z'
    },
    related_case_id: 'related_case_id',
    history: [
        {
            data: {
                diagnose: 'Meningitis',
                created_at: '2024-04-12T09:20:00Z'
            },

            modifiedAt: '2024-04-12T09:20:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            }
        },
        {
            data: {
                diagnose: 'Meningitis, Hypertonie',
                created_at: '2024-04-12T09:20:00Z'
            },

            modifiedAt: '2024-04-13T09:20:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            }
        }
    ]
};

export const dummyVC_Patient_Details: Patient_Details = {
    case: dummyVC_Case,
    patient: {
        id: '550e8400-e29b-41d4-a716-446655440000',
        locator: 'patient.550e8400-e29b-41d4-a716-446655440000',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['cardiology'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T12:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            surname: 'Mustermann',
            name: 'Max',
            gender: 'male',
            birth_date: '1980-01-01',
            valid_from: '2024-01-01',
            valid_until: '2025-01-01'
        },
        history: [],
        i18n: {
            de: {
                referenceId: 'Referenz-ID',
                medical_history: 'Anamnese',
                date: 'Datum',
                modifiedAt: 'Geändert am',
                modifiedBy: 'Geändert von',
                showThisAnamnesis: 'Diese Anamnese anzeigen',
                title: 'Titel',
                details: 'Details',
                task: 'Aufgabe',
                teams: 'Teams',
                registered: 'Registriert',
                conducted: 'Durchgeführt',
                goalDate: 'Zieldatum',
                goalDateOn: 'Zieldatum am',
                showDayCountForGoalDateAs:
                    'Zeige Tageszählung für Zieldatum als',
                dayCountDeactivate: 'Tageszählung deaktivieren',
                showJustInCurve: 'Nur in Kurve anzeigen',
                postTreatment: 'Nachbehandlung',
                consultations: 'Beratungen',
                performed: 'Durchgeführt',
                medication: 'Medikament',
                dosage: 'Dosierung',
                route: 'Verabreichungsweg',
                frequency: 'Häufigkeit',
                specialist: 'Spezialist',
                note: 'Notizen',
                setInCheckpad: 'Im Checkpad festlegen',
                dismissalExpectedOn: 'Entlassung erwartet am',
                comment: 'Kommentar',
                type: 'Typ',
                name: 'Name',
                dayCountForGoalDateShowAs:
                    'Tageszählung für Zieldatum anzeigen als',
                dayCountDeactivateOn: 'Tageszählung deaktivieren am',
                showOnlyInCurve: 'Nur in Kurve anzeigen',
                editor: 'Bearbeiter',
                created_at: 'Erstellt am',
                plannedDischarge: 'Geplante Entlassung',
                actualDischarge: 'Tatsächliche Entlassung',
                teams_labor: 'Labor',
                teams_punktion: 'Punktion',
                teams_doctors: 'Ärzte',
                teams_tx: 'TX',
                teams_nurse: 'Pflege',
                goal_date: 'Zieldatum',
                task_name: 'Aufgabe',
                priority: 'Priorität',
                done: 'Erledigt',
                contractor: 'Auftragnehmer',
                notes: 'Notiz',
                editor_surname: 'Nachname des Bearbeiters',
                editor_name: 'Vorname der Bearbeiters',
                contractor_surname: 'Nachname des Auftragnehmers',
                contractor_name: 'Vorname der Auftragnehmers',
                date_and_time: 'Datum und Zeit',
                diagnose: 'Diagnose'
            },
            en: {
                referenceId: 'Reference ID',
                medical_history: 'Medical history',
                date: 'Date',
                modifiedAt: 'Modified At',
                modifiedBy: 'Modified By',
                showThisAnamnesis: 'Show This Anamnesis',
                title: 'Title',
                details: 'Details',
                task: 'Task',
                teams: 'Teams',
                registered: 'Registered',
                conducted: 'Conducted',
                goalDate: 'Goal Date',
                goalDateOn: 'Goal Date On',
                showDayCountForGoalDateAs: 'Show Day Count For Goal Date As',
                dayCountDeactivate: 'Day Count Deactivate',
                showJustInCurve: 'Show Just In Curve',
                postTreatment: 'Post Treatment',
                consultations: 'Consultations',
                performed: 'Performed',
                medication: 'Medication',
                dosage: 'Dosage',
                route: 'Route',
                frequency: 'Frequency',
                specialist: 'Specialist',
                note: 'note',
                setInCheckpad: 'Set In Checkpad',
                dismissalExpectedOn: 'Dismissal Expected On',
                comment: 'Comment',
                type: 'Type',
                name: 'Name',
                dayCountForGoalDateShowAs: 'Day Count For Goal Date Show As',
                dayCountDeactivateOn: 'Day Count Deactivate On',
                showOnlyInCurve: 'Show Only In Curve',
                editor: 'Editor',
                created_at: 'Created at',
                plannedDischarge: 'Planned Discharge',
                actualDischarge: 'Actual Discharge',
                teams_labor: 'Labor',
                teams_punktion: 'Punktion',
                teams_doctors: 'Doctors',
                teams_tx: 'TX',
                teams_nurse: 'Nurse',
                goal_date: 'Goal Date',
                task_name: 'Task',
                priority: 'Priority',
                done: 'done',
                notes: 'Note',
                contractor: 'Contractor',
                editor_surname: 'Editor surname',
                editor_name: 'Editor name',
                contractor_surname: 'Contractor surname',
                contractor_name: 'Contractor name',
                date_and_time: 'Date and time',
                diagnose: 'Diagnose'
            }
        }
    },
    current_place: dummyVC_Place,
    last_diagnosis: dummyVC_Diagnose
};

export const dummyVC_Medical_History: VC_Medical_History = {
    id: '950e8400-e29b-41d4-a716-446655440444',
    locator: 'medical-history-950e8400-e29b-41d4-a716-446655440444',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['general_medicine'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T11:20:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    related_case_id: '',
    data: {
        medical_history:
            'Zunehmende Kopfschmerzen, Müdigkeit, Erbrechen und Durchfall seit 2 Tagen.',
        date: '2024-04-12',
        showThisAnamnesis: true,
        created_at: '2024-03-10T10:00:00Z'
    },
    history: [
        {
            data: {
                medical_history: 'Kopfschmerzen und Schwindel seit 3 Tagen.',
                date: '2024-03-10',
                showThisAnamnesis: true,
                created_at: '2024-03-10T10:00:00Z'
            },
            modifiedAt: '2024-03-10T11:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            }
        },
        {
            data: {
                medical_history: 'Kopfschmerzen und Schwindel seit 6 Tagen.',
                date: '2024-03-10',
                showThisAnamnesis: true,
                created_at: '2024-03-10T10:00:00Z'
            },
            modifiedAt: '2024-03-13T11:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            }
        },
        {
            data: {
                medical_history: 'Kopfschmerzen und Schwindel seit 1 Tag.',
                date: '2024-03-10',
                showThisAnamnesis: true,
                created_at: '2024-03-10T10:00:00Z'
            },
            modifiedAt: '2024-03-12T11:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            }
        },
        {
            data: {
                medical_history: 'Kopfschmerzen und Schwindel seit 2 Tagen.',
                date: '2024-03-10',
                showThisAnamnesis: true,
                created_at: '2024-03-10T10:00:00Z'
            },
            modifiedAt: '2024-03-14T11:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            }
        }
    ]
};

export const dummyVC_Therapy: VC_Therapy[] = [
    {
        id: 'b60e8400-e29b-41d4-a716-446655440666',
        locator: 'therapy-b60e8400-e29b-41d4-a716-446655440666',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T12:30:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',

        data: {
            title: 'Antibiotikatherapie',
            details:
                'Verabreicht zur Behandlung einer bakteriellen Infektion, die bei Meningitis vermutet wird, und 3-mal tägliche Temperaturkontrolle.',
            task: true,
            editor: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            },
            registered: true,
            conducted: false,
            goalDateOn: '2024-04-13T10:00:00Z',
            showDayCountForGoalDateAs: 'day0',
            dayCountDeactivateOn: '2024-04-14T10:00:00Z',
            showOnlyInCurve: false,
            created_at: '2024-04-12T09:30:00Z'
        },
        history: [
            {
                data: {
                    title: 'Antibiotikatherapie',
                    details:
                        'Verabreicht zur Behandlung einer bakteriellen Infektion, die bei Meningitis vermutet wird.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    registered: true,
                    conducted: true,
                    goalDateOn: '2024-04-13T10:00:00Z',
                    showDayCountForGoalDateAs: 'day0',
                    dayCountDeactivateOn: '2024-04-14T10:00:00Z',
                    showOnlyInCurve: false,
                    created_at: '2024-04-12T09:30:00Z'
                },
                modifiedAt: '2024-04-12T09:30:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            },
            {
                data: {
                    title: 'Antibiotikatherapie',
                    details:
                        'Verabreicht zur Behandlung einer bakteriellen Infektion, die bei Meningitis vermutet wird, und 3-mal tägliche Temperaturkontrolle.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    registered: true,
                    conducted: false,
                    goalDateOn: '2024-04-13T10:00:00Z',
                    showDayCountForGoalDateAs: 'day0',
                    dayCountDeactivateOn: '2024-04-14T10:00:00Z',
                    showOnlyInCurve: false,
                    created_at: '2024-04-12T09:30:00Z'
                },
                modifiedAt: '2024-04-12T11:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            }
        ]
    },
    {
        id: 'b60e8400-e29b-41d4-a716-446655440666',
        locator: 'therapy-b60e8400-e29b-41d4-a716-446655440666',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-04-15T09:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: '',
        data: {
            title: 'Antibiotikatherapie',
            details:
                'Verabreicht zur Behandlung einer bakteriellen Infektion, die bei Meningitis vermutet wird, und 3-mal tägliche Temperaturkontrolle.',
            task: true,
            editor: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            },
            registered: true,
            conducted: false,
            goalDateOn: '2024-04-13T10:00:00Z',
            showDayCountForGoalDateAs: 'day0',
            dayCountDeactivateOn: '2024-04-14T10:00:00Z',
            showOnlyInCurve: false,
            created_at: '2024-04-12T09:30:00Z'
        },
        history: [
            {
                data: {
                    title: 'Antibiotikatherapie',
                    details:
                        'Verabreicht antivirale Medikamente zur Behandlung einer Virusinfektion.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    registered: true,
                    conducted: true,
                    goalDateOn: '2024-04-13T10:00:00Z',
                    showDayCountForGoalDateAs: 'day0',
                    dayCountDeactivateOn: '2024-04-14T10:00:00Z',
                    showOnlyInCurve: false,
                    created_at: '2024-04-12T09:30:00Z'
                },
                modifiedAt: '2024-04-15T09:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            },
            {
                data: {
                    title: 'Antibiotikatherapie',
                    details:
                        'Verabreicht antivirale Medikamente zur Behandlung einer Virusinfektion.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    registered: true,
                    conducted: false,
                    goalDateOn: '2024-04-13T10:00:00Z',
                    showDayCountForGoalDateAs: 'day0',
                    dayCountDeactivateOn: '2024-04-14T10:00:00Z',
                    showOnlyInCurve: false,
                    created_at: '2024-04-12T09:30:00Z'
                },
                modifiedAt: '2024-04-12T11:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            }
        ]
    }
];

export const dummyVC_Visit_Record: VC_Visit_Record = {
    id: 'c70e8400-e29b-41d4-a716-446655440777',
    locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440777',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['general_medicine'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T13:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    related_case_id: '',
    data: {
        note: 'Patient spricht gut auf die Behandlung an. Fortsetzung der aktuellen Therapie.',
        date_and_time: '2024-04-13T10:00:00Z'
    },
    history: [
        {
            data: {
                note: 'Patient spricht gut auf die Behandlung an. Fortsetzung der aktuellen Therapie.',
                date_and_time: '2024-04-13T10:00:00Z'
            },
            modifiedAt: '2024-04-13T10:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            }
        },
        {
            data: {
                note: 'Patient spricht gut auf die Behandlung an.',
                date_and_time: '2024-04-13T06:00:00Z'
            },
            modifiedAt: '2024-04-13T06:00:00Z',
            modifiedBy: {
                userId: 'dummy_user_id',
                surname: 'Bauer',
                name: 'Thomas',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            }
        }
    ]
};

export const dummyVC_Diagnostic: VC_Diagnostic[] = [
    {
        id: 'd80e8400-e29b-41d4-a716-446655440888',
        locator: 'diagnostic-d80e8400-e29b-41d4-a716-446655440888',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['radiology'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T13:30:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            title: 'Blutkulturanalyse',
            details:
                'Analyse zeigt erhöhte Anzahl weißer Blutkörperchen, was auf eine mögliche bakterielle Infektion hinweist.',
            task: true,
            teams: {
                microbiology: true,
                hematology: false
            },
            registered: true,
            conducted: true,
            goalDateOn: '2024-04-14T09:00:00Z',
            showDayCountForGoalDateAs: 'day1',
            dayCountDeactivate: '2024-04-15T12:00:00Z',
            showJustInCurve: false
        },
        history: [
            {
                data: {
                    title: 'Blutkulturanalyse',
                    details:
                        'Analyse zeigt erhöhte Anzahl weißer Blutkörperchen, was auf eine mögliche bakterielle Infektion hinweist.',
                    task: true,
                    teams: {
                        microbiology: true,
                        hematology: false
                    },
                    registered: true,
                    conducted: true,
                    goalDateOn: '2024-04-14T09:00:00Z',
                    showDayCountForGoalDateAs: 'day1',
                    dayCountDeactivate: '2024-04-15T12:00:00Z',
                    showJustInCurve: false
                },
                modifiedAt: '2024-04-14T11:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            }
        ]
    }
];

export const dummyVC_Consultation: VC_Consultation[] = [
    {
        id: 'e90e8400-e29b-41d4-a716-446655440999',
        locator: 'consultation-e90e8400-e29b-41d4-a716-446655440999',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['cardiology'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T14:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            title: 'Kardiologische Konsultation',
            details:
                'Konsultation erforderlich, um das Bluthochdruckmanagement des Patienten zu bewerten.',
            task: true,
            editor: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            },
            registered: true,
            performed: true,
            goalDateOn: '2024-04-14T10:00:00Z',
            dayCountForGoalDateShowAs: 'day1',
            dayCountDeactivate: 'on',
            dayCountDeactivateOn: '2024-04-15T12:00:00Z',
            showOnlyInCurve: false
        },
        history: [
            {
                data: {
                    title: 'Kardiologische Konsultation',
                    details:
                        'Konsultation erforderlich, um das Bluthochdruckmanagement des Patienten zu bewerten.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    registered: true,
                    performed: true,
                    goalDateOn: '2024-04-14T10:00:00Z',
                    dayCountForGoalDateShowAs: 'day1',
                    dayCountDeactivate: 'on',
                    dayCountDeactivateOn: '2024-04-15T12:00:00Z',
                    showOnlyInCurve: false
                },
                modifiedAt: '2024-04-14T12:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            },
            {
                data: {
                    title: 'Kardiologische Konsultation',
                    details:
                        'Konsultation erforderlich, um das Bluthochdruckmanagement und den Blutdruck des Patienten zu bewerten.',
                    task: true,
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    registered: true,
                    performed: true,
                    goalDateOn: '2024-04-15T10:00:00Z',
                    dayCountForGoalDateShowAs: 'day1',
                    dayCountDeactivate: 'on',
                    dayCountDeactivateOn: '2024-04-15T12:00:00Z',
                    showOnlyInCurve: false
                },
                modifiedAt: '2024-04-14T16:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            }
        ]
    }
];

export const dummyVC_Task: VC_Task[] = [
    {
        id: 'f10e8400-e29b-41d4-a716-446655441111',
        locator: 'task-f10e8400-e29b-41d4-a716-446655441111',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T14:30:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            task_name: 'Überwachung der Vitalfunktionen',
            details:
                'Überwachen Sie die Vitalfunktionen alle 4 Stunden aufgrund des Verdachts auf Meningitis.',
            priority: 'high',
            editor: {
                userId: 'dummy_user_id',
                surname: 'Müller',
                name: 'Erika',
                validSince: '2020-01-01',
                validUntil: '2025-01-01'
            },
            goalDateOn: '2024-04-13T12:00:00Z',
            goal_date: 'on',
            done: true,
            contractor: {
                userId: 'dummy_user_id',
                surname: 'Schmidt',
                name: 'Hans',
                validSince: '2021-01-01',
                validUntil: '2026-01-01'
            }
        },

        history: [
            {
                data: {
                    task_name: 'Überwachung der Vitalfunktionen',
                    details:
                        'Überwachen Sie die Vitalfunktionen alle 4 Stunden aufgrund des Verdachts auf Meningitis.',
                    priority: 'high',
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    goalDateOn: '2024-04-13T12:00:00Z',
                    goal_date: 'on',
                    done: true,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Schmidt',
                        name: 'Hans',
                        validSince: '2021-01-01',
                        validUntil: '2026-01-01'
                    }
                },
                modifiedAt: '2024-04-13T14:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            },
            {
                data: {
                    task_name: 'Überwachung der Vitalfunktionen',
                    details:
                        'Überwachen Sie die Vitalfunktionen alle & Stunden aufgrund des Verdachts auf Meningitis.',
                    priority: 'high',
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller',
                        name: 'Erika',
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    goalDateOn: '2024-04-13T12:00:00Z',
                    goal_date: 'on',
                    done: true,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Schmidt',
                        name: 'Hans',
                        validSince: '2021-01-01',
                        validUntil: '2026-01-01'
                    }
                },
                modifiedAt: '2024-04-14T14:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            }
        ]
    }
];

export const dummyVC_Discharge: VC_Discharge[] = [
    {
        id: 'g20e8400-e29b-41d4-a716-446655442222',
        locator: 'discharge-g20e8400-e29b-41d4-a716-446655442222',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T15:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        data: {
            setInCheckpad: true,
            dismissalExpectedOn: '2024-04-15',
            comment:
                'Der Patient kann entlassen werden, keine weitere Behandlung erforderlich.'
        },
        related_case_id: 'related_case_id',
        history: [
            {
                data: {
                    setInCheckpad: true,
                    dismissalExpectedOn: '2024-04-15',
                    comment:
                        'Der Patient kann entlassen werden, keine weitere Behandlung erforderlich.'
                },
                modifiedAt: '2024-04-14T10:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            }
        ]
    }
];

export const dummyVC_Post_Treatment: VC_Post_Treatment[] = [
    {
        id: 'h30e8400-e29b-41d4-a716-446655443333',
        locator: 'post-treatment-h30e8400-e29b-41d4-a716-446655443333',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['general_medicine'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T15:30:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        data: {
            postTreatment:
                'Patient wurde geraten, eine natriumarme Diät einzuhalten und den Blutdruck regelmäßig zu überwachen.'
        },
        history: [
            {
                data: {
                    postTreatment:
                        'Patient wurde geraten, eine natriumarme Diät einzuhalten und den Blutdruck regelmäßig zu überwachen.'
                },
                modifiedAt: '2024-04-15T12:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            }
        ],

        related_case_id: 'related_case_id'
    }
];

export const dummyVC_Pathogen: VC_Pathogen[] = [
    {
        id: 'i40e8400-e29b-41d4-a716-446655444444',
        locator: 'pathogen-i40e8400-e29b-41d4-a716-446655444444',
        owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
        owner_departments: ['microbiology'],
        main_owner_job_type: 'doctor',
        created_at: '2024-08-09T16:00:00Z',
        status: 'final',
        related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
        related_case_id: 'related_case_id',
        data: {
            name: 'Streptococcus pneumoniae',
            type: 'Bakterien',
            date: '2024-04-13'
        },
        history: [
            {
                data: {
                    name: 'Streptococcus pneumoniae',
                    type: 'Bakterien',
                    date: '2024-04-13'
                },
                modifiedAt: '2024-04-14T10:00:00Z',
                modifiedBy: {
                    userId: 'dummy_user_id',
                    surname: 'Müller',
                    name: 'Erika',
                    validSince: '2020-01-01',
                    validUntil: '2025-01-01'
                }
            }
        ]
    }
];

export const dummyVC_Case_Overview: Case_Overview = {
    case: dummyVC_Case,
    medical_history: dummyVC_Medical_History,
    diagnose: dummyVC_Diagnose,
    therapy: dummyVC_Therapy,
    visit_record: dummyVC_Visit_Record,
    diagnostic: dummyVC_Diagnostic,
    consultation: dummyVC_Consultation,
    task: dummyVC_Task,
    discharge: dummyVC_Discharge,
    post_treatment: dummyVC_Post_Treatment,
    pathogen: dummyVC_Pathogen
};

export const dummyVC_PatientList: VcPatientListItem[] = [
    {
        patient_details: {
            case: {
                id: '950e8400-e29b-41d4-a716-446655440444',
                locator: 'case.950e8400-e29b-41d4-a716-446655440444',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurologie'],
                main_owner_job_type: 'doctor',
                created_at: '2024-08-11T12:00:00Z',
                status: 'final',
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                data: {
                    patient: {
                        surname: 'Müller',
                        name: 'Klara',
                        gender: 'female',
                        birth_date: '1980-08-12',
                        valid_from: '2024-02-01',
                        valid_until: '2025-02-01'
                    },
                    admission_date: '2024-08-11',
                    discharge_date: '2024-08-15',
                    planned_discharge_date: '2024-08-15',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP02',
                                        shortName: 'CorpNeu',
                                        name: 'Neurologie Gesellschaft',
                                        validSince: '2024-02-01'
                                    },
                                    code: 'LOC02',
                                    shortName: 'LocNeu',
                                    name: 'Neurologie Standort',
                                    validSince: '2024-02-01'
                                },
                                code: 'CTR02',
                                shortName: 'ZentrumNeu',
                                name: 'Neurologie Zentrum',
                                validSince: '2024-02-01'
                            }
                        ],
                        code: 'DEPT02',
                        shortName: 'AbtNeu',
                        name: 'Abteilung für Neurologie',
                        validSince: '2024-02-01'
                    },
                    start: '2024-08-11T08:00:00Z',
                    end: '2024-08-15T15:00:00Z'
                },
                history: []
            },
            patient: {
                id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                locator: 'patient.9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurologie'],
                main_owner_job_type: 'doctor',
                created_at: '2024-04-09T10:00:00Z',
                status: 'final',
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                data: {
                    surname: 'Müller',
                    name: 'Klara',
                    gender: 'female',
                    birth_date: '1980-08-12',
                    valid_from: '2024-02-01',
                    valid_until: '2025-02-01'
                },
                history: [],
                i18n: {}
            },
            current_place: {
                id: '650e8400-e29b-41d4-a716-446655440111',
                related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                locator: 'place-650e8400-e29b-41d4-a716-446655440111',
                owners: ['7edc1d64-c6f7-4b2d-8121-333cd9844573'],
                owner_departments: ['allgemeinmedizin'],
                main_owner_job_type: 'nurse',
                created_at: '2024-08-09T12:00:00Z',
                status: 'final',
                related_patient_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                data: {
                    room: {
                        service_unit: {
                            departments: [
                                {
                                    centers: [
                                        {
                                            location: {
                                                corporation: {
                                                    code: 'CORP01',
                                                    shortName: 'CorpKurz',
                                                    name: 'Konzernname',
                                                    validSince: '2024-01-01'
                                                },
                                                code: 'LOC01',
                                                shortName: 'LocKurz',
                                                name: 'Standortname',
                                                validSince: '2024-01-01'
                                            },
                                            code: 'CTR01',
                                            shortName: 'ZentrumKurz',
                                            name: 'Zentrumsname',
                                            validSince: '2024-01-01'
                                        }
                                    ],
                                    code: 'DEPT01',
                                    shortName: 'AbtKurz',
                                    name: 'Abteilungsname',
                                    validSince: '2024-01-01'
                                }
                            ],
                            code: 'SU01',
                            short_text: 'SUKurz',
                            long_text: 'Dienstleistungseinheit 2',
                            valid_since: '2024-01-01'
                        },
                        code: 'ROOM01',
                        short_text: 'ZimmerKurz',
                        long_text: '201-C',
                        valid_since: '2024-01-01'
                    },
                    code: 'PLACE01',
                    shortName: 'OrtKurz',
                    name: 'Bett 01',
                    validSince: '2024-01-01'
                },
                history: []
            },
            last_diagnosis: dummyVC_Diagnose
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440779',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440779',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
            owner_departments: ['neurologie'],
            main_owner_job_type: 'doctor',
            created_at: '2024-08-10T13:00:00Z',
            status: 'final',
            related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
            related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
            data: {
                note: 'Patient berichtete von Verbesserungen nach der Medikation.',
                date_and_time: '2024-04-14T11:00:00Z'
            },
            history: [
                {
                    data: {
                        note: 'Patient berichtete von Verbesserungen nach der Medikation.',
                        date_and_time: ''
                    },
                    modifiedAt: '2024-04-14T11:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Braun',
                        name: 'Emilia',
                        validSince: '2021-02-01',
                        validUntil: '2026-02-01'
                    }
                }
            ],
            i18n: {
                de: {note: 'Notizen', date_and_time: 'Datum und Zeit'},
                en: {note: 'Note', date_and_time: 'Date and time'}
            }
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441113',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441113',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurologie'],
                main_owner_job_type: 'doctor',
                created_at: '2024-08-10T14:30:00Z',
                status: 'final',
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: '2efd3469-d287-4a39-b8dd-90efc644f825',
                data: {
                    task_name: 'Tägliche Kopfschmerzüberwachung',
                    details:
                        'Überwachen Sie die Schwere und Häufigkeit der Kopfschmerzen.',
                    priority: 'high',
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Braun',
                        name: 'Emilia',
                        validSince: '2021-02-01',
                        validUntil: '2026-02-01'
                    },
                    goalDateOn: '2024-04-14T12:00:00Z',
                    goal_date: 'on',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Schneider',
                        name: 'Sara',
                        validSince: '2022-01-01',
                        validUntil: '2027-01-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Tägliche Kopfschmerzüberwachung',
                            details:
                                'Überwachen Sie die Schwere und Häufigkeit der Kopfschmerzen.',
                            priority: 'high',
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Braun',
                                name: 'Emilia',
                                validSince: '2021-02-01',
                                validUntil: '2026-02-01'
                            },
                            goalDateOn: '2024-04-14T12:00:00Z',
                            goal_date: 'on',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Schneider',
                                name: 'Sara',
                                validSince: '2022-01-01',
                                validUntil: '2027-01-01'
                            }
                        },
                        modifiedAt: '2024-04-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Braun',
                            name: 'Emilia',
                            validSince: '2021-02-01',
                            validUntil: '2026-02-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Notizen',
                        details: 'Datum und Zeit',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers'
                    },
                    en: {
                        task_name: 'Note',
                        details: 'Date and time',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            }
        ]
    },
    {
        patient_details: {
            case: {
                id: '950e8400-e29b-41d4-a716-446655440444',
                locator: 'case.950e8400-e29b-41d4-a716-446655440444',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-11T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: 'cd6f1b50-9823-4c02-989a-13a38be8dfdd',
                data: {
                    patient: {
                        surname: 'Schmidt', // Translated surname
                        name: 'Jane', // No need to translate
                        gender: 'female', // Translated gender
                        birth_date: '1980-08-12',
                        valid_from: '2024-02-01',
                        valid_until: '2025-02-01'
                    },
                    admission_date: '2024-08-11',
                    discharge_date: '2024-08-15',
                    planned_discharge_date: '2024-08-15',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP02',
                                        shortName: 'CorpNeu',
                                        name: 'Neurologische Gesellschaft', // Translated name
                                        validSince: '2024-02-01'
                                    },
                                    code: 'LOC02',
                                    shortName: 'LocNeu',
                                    name: 'Neurologischer Standort', // Translated name
                                    validSince: '2024-02-01'
                                },
                                code: 'CTR02',
                                shortName: 'ZentrumNeu', // Translated shortName
                                name: 'Neurologisches Zentrum', // Translated name
                                validSince: '2024-02-01'
                            }
                        ],
                        code: 'DEPT02',
                        shortName: 'AbtNeu', // Translated shortName
                        name: 'Abteilung für Neurologie', // Translated name
                        validSince: '2024-02-01'
                    },
                    start: '2024-08-11T08:00:00Z',
                    end: '2024-08-15T15:00:00Z'
                },
                history: []
            },
            patient: {
                id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                locator: 'patient.9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-09T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: 'cd6f1b50-9823-4c02-989a-13a38be8dfdd',
                data: {
                    surname: 'Schmidt', // Translated surname
                    name: 'Jane', // No need to translate
                    gender: 'female', // Translated gender
                    birth_date: '1980-08-12',
                    valid_from: '2024-02-01',
                    valid_until: '2025-02-01'
                },
                history: [],
                i18n: {}
            },
            current_place: dummyVC_Place, // No changes, as per previous pattern
            last_diagnosis: dummyVC_Diagnose // No changes, as per previous pattern
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440779',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440779',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
            owner_departments: ['neurology'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-10T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
            related_case_id: 'cd6f1b50-9823-4c02-989a-13a38be8dfdd',
            data: {
                note: 'Patientin berichtete von einer Besserung nach der Medikation.', // Translated note
                date_and_time: '2024-08-10T13:00:00Z'
            },
            history: [
                {
                    data: {
                        note: 'Patientin berichtete von einer Besserung nach der Medikation.', // Translated note
                        date_and_time: ''
                    },
                    modifiedAt: '2024-04-14T11:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Braun', // Translated surname
                        name: 'Emilia', // Translated name
                        validSince: '2021-02-01',
                        validUntil: '2026-02-01'
                    }
                }
            ],
            i18n: {
                de: {note: 'Notizen', date_and_time: 'Datum und Zeit'},
                en: {note: 'Note', date_and_time: 'Date and time'}
            }
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441113',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441113',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844563'],
                owner_departments: ['neurology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-10T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: '9bb91e17-6079-4d1a-a931-dc631a3bf95c',
                related_case_id: 'cd6f1b50-9823-4c02-989a-13a38be8dfdd',
                data: {
                    task_name: 'Tägliche Überwachung der Kopfschmerzen', // Translated task name
                    details:
                        'Überwachung der Schwere und Häufigkeit der Kopfschmerzen.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Braun', // Translated surname
                        name: 'Emilia', // Translated name
                        validSince: '2021-02-01',
                        validUntil: '2026-02-01'
                    },
                    goalDateOn: '2024-04-14T12:00:00Z',
                    goal_date: 'on',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Johansson', // Translated surname
                        name: 'Sarah', // No need to translate
                        validSince: '2022-01-01',
                        validUntil: '2027-01-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Tägliche Überwachung der Kopfschmerzen', // Translated task name
                            details:
                                'Überwachung der Schwere und Häufigkeit der Kopfschmerzen.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Braun', // Translated surname
                                name: 'Emilia', // Translated name
                                validSince: '2021-02-01',
                                validUntil: '2026-02-01'
                            },
                            goalDateOn: '2024-04-14T12:00:00Z',
                            goal_date: 'on',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Johansson', // Translated surname
                                name: 'Sarah', // No need to translate
                                validSince: '2022-01-01',
                                validUntil: '2027-01-01'
                            }
                        },
                        modifiedAt: '2024-04-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Braun', // Translated surname
                            name: 'Emilia', // Translated name
                            validSince: '2021-02-01',
                            validUntil: '2026-02-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Aufgabe', // Translated
                        details: 'Details', // Translated
                        priority: 'Priorität', // Translated
                        goal_date: 'Zieldatum', // Translated
                        done: 'Erledigt', // Translated
                        notes: 'Notizen', // Translated
                        contractor: 'Auftragnehmer', // Translated
                        editor_surname: 'Nachname des Bearbeiters', // Translated
                        editor_name: 'Vorname des Bearbeiters', // Translated
                        contractor_surname: 'Nachname des Auftragnehmers', // Translated
                        contractor_name: 'Vorname des Auftragnehmers' // Translated
                    },
                    en: {
                        task_name: 'Task name',
                        details: 'Details',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            }
        ]
    },
    {
        patient_details: {
            case: {
                id: 'a60e8400-e29b-41d4-a716-446655440555',
                locator: 'case.a60e8400-e29b-41d4-a716-446655440555',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-12T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
                data: {
                    patient: {
                        surname: 'Johannsen', // Translated surname
                        name: 'David', // No need to translate
                        gender: 'male', // Translated gender
                        birth_date: '1980-02-28',
                        valid_from: '2024-03-01',
                        valid_until: '2025-03-01'
                    },
                    admission_date: '2024-08-12',
                    discharge_date: '2024-08-20',
                    planned_discharge_date: '2024-08-20',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP03',
                                        shortName: 'CorpOrtho',
                                        name: 'Orthopädische Gesellschaft', // Translated name
                                        validSince: '2024-03-01'
                                    },
                                    code: 'LOC03',
                                    shortName: 'LocOrtho',
                                    name: 'Orthopädischer Standort', // Translated name
                                    validSince: '2024-03-01'
                                },
                                code: 'CTR03',
                                shortName: 'ZentrumOrtho', // Translated shortName
                                name: 'Orthopädisches Zentrum', // Translated name
                                validSince: '2024-03-01'
                            }
                        ],
                        code: 'DEPT03',
                        shortName: 'AbtOrtho', // Translated shortName
                        name: 'Abteilung für Orthopädie', // Translated name
                        validSince: '2024-03-01'
                    },
                    start: '2024-08-12T08:00:00Z',
                    end: '2024-08-20T15:00:00Z'
                },
                history: []
            },
            patient: {
                id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                locator: 'patient.fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-08T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
                data: {
                    surname: 'Johannsen', // Translated surname
                    name: 'David', // No need to translate
                    gender: 'male', // Translated gender
                    birth_date: '1980-02-28',
                    valid_from: '2024-03-01',
                    valid_until: '2025-03-01'
                },
                history: [],
                i18n: {}
            },
            current_place: {
                id: '650e8400-e29b-41d4-a716-446655440111',
                related_case_id: 'related_case_id',
                locator: 'place-650e8400-e29b-41d4-a716-446655440111',
                owners: ['7edc1d64-c6f7-4b2d-8121-333cd9844573'],
                owner_departments: ['general'], // Not translated
                main_owner_job_type: 'nurse', // Not translated
                created_at: '2024-08-09T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
                data: {
                    room: {
                        service_unit: {
                            departments: [
                                {
                                    centers: [
                                        {
                                            location: {
                                                corporation: {
                                                    code: 'CORP01',
                                                    shortName: 'CorpKurz', // Translated shortName
                                                    name: 'Konzernname', // Translated name
                                                    validSince: '2024-01-01'
                                                },
                                                code: 'LOC01',
                                                shortName: 'LocKurz', // Translated shortName
                                                name: 'Standortname', // Translated name
                                                validSince: '2024-01-01'
                                            },
                                            code: 'CTR01',
                                            shortName: 'ZentrumKurz', // Translated shortName
                                            name: 'Zentrumsname', // Translated name
                                            validSince: '2024-01-01'
                                        }
                                    ],
                                    code: 'DEPT01',
                                    shortName: 'AbtKurz', // Translated shortName
                                    name: 'Abteilungsname', // Translated name
                                    validSince: '2024-01-01'
                                }
                            ],
                            code: 'SU01',
                            short_text: 'SUKurz', // Translated shortName
                            long_text: 'Dienstleistungseinheit 3', // Translated name
                            valid_since: '2024-01-01'
                        },
                        code: 'ROOM01',
                        short_text: 'ZimmerKurz', // Translated shortName
                        long_text: '201-C',
                        valid_since: '2024-01-01'
                    },
                    code: 'PLACE01',
                    shortName: 'OrtKurz', // Translated shortName
                    name: 'Bett 01',
                    validSince: '2024-01-01'
                },
                history: []
            },
            last_diagnosis: dummyVC_Diagnose // Assuming diagnosis remains unchanged
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440780',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440780',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
            owner_departments: ['orthopedics'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-13T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
            related_case_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
            data: {
                note: 'Der Patient wurde erfolgreich operiert.', // Translated note
                date_and_time: '2024-08-13T13:00:00Z'
            },
            history: [
                {
                    data: {
                        note: 'Der Patient wurde erfolgreich operiert.', // Translated note
                        date_and_time: ''
                    },
                    modifiedAt: '2024-08-14T11:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Weiß', // Translated surname
                        name: 'Jakob', // Translated name
                        validSince: '2021-03-01',
                        validUntil: '2026-03-01'
                    }
                }
            ],
            i18n: {
                de: {note: 'Notizen', date_and_time: 'Datum und Zeit'},
                en: {note: 'Note', date_and_time: 'Date and time'}
            }
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441114',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441114',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-13T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '80b09180-0fb5-475c-afbc-043513cee4b9',
                data: {
                    task_name: 'Rehabilitation nach der Operation', // Translated task name
                    details: 'Physiotherapiesitzungen planen und überwachen.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Weiß', // Translated surname
                        name: 'Jakob', // Translated name
                        validSince: '2021-03-01',
                        validUntil: '2026-03-01'
                    },
                    goalDateOn: '2024-08-20T12:00:00Z',
                    goal_date: 'on',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Braun', // Translated surname
                        name: 'Thomas', // Translated name
                        validSince: '2022-02-01',
                        validUntil: '2027-02-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Rehabilitation nach der Operation', // Translated task name
                            details:
                                'Physiotherapiesitzungen planen und überwachen.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Weiß', // Translated surname
                                name: 'Jakob', // Translated name
                                validSince: '2021-03-01',
                                validUntil: '2026-03-01'
                            },
                            goalDateOn: '2024-08-20T12:00:00Z',
                            goal_date: 'on',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Braun', // Translated surname
                                name: 'Thomas', // Translated name
                                validSince: '2022-02-01',
                                validUntil: '2027-02-01'
                            }
                        },
                        modifiedAt: '2024-08-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Weiß', // Translated surname
                            name: 'Jakob', // Translated name
                            validSince: '2021-03-01',
                            validUntil: '2026-03-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Notizen',
                        details: 'Datum und Zeit',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum', // Translated
                        done: 'Erledigt', // Translated
                        notes: 'Notizen', // Translated
                        contractor: 'Auftragnehmer', // Translated
                        editor_surname: 'Nachname des Bearbeiters', // Translated
                        editor_name: 'Vorname des Bearbeiters', // Translated
                        contractor_surname: 'Nachname des Auftragnehmers', // Translated
                        contractor_name: 'Vorname des Auftragnehmers' // Translated
                    },
                    en: {
                        task_name: 'Note',
                        details: 'Date and time',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            }
        ]
    },
    {
        patient_details: {
            case: {
                id: 'a60e8400-e29b-41d4-a716-446655440555',
                locator: 'case.a60e8400-e29b-41d4-a716-446655440555',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-12T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '7e8bf7a6-7dca-41c8-af11-9346da764c1e',
                data: {
                    patient: {
                        surname: 'Johansson', // Translated surname
                        name: 'David', // No need to translate
                        gender: 'male', // Translated gender
                        birth_date: '1980-02-28',
                        valid_from: '2024-03-01',
                        valid_until: '2025-03-01'
                    },
                    admission_date: '2024-08-12',
                    discharge_date: '2024-08-20',
                    planned_discharge_date: '2024-08-20',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP03',
                                        shortName: 'CorpOrtho',
                                        name: 'Orthopädische Gesellschaft', // Translated name
                                        validSince: '2024-03-01'
                                    },
                                    code: 'LOC03',
                                    shortName: 'LocOrtho',
                                    name: 'Orthopädischer Standort', // Translated name
                                    validSince: '2024-03-01'
                                },
                                code: 'CTR03',
                                shortName: 'ZentrumOrtho', // Translated shortName
                                name: 'Orthopädisches Zentrum', // Translated name
                                validSince: '2024-03-01'
                            }
                        ],
                        code: 'DEPT03',
                        shortName: 'AbtOrtho', // Translated shortName
                        name: 'Abteilung für Orthopädie', // Translated name
                        validSince: '2024-03-01'
                    },
                    start: '2024-08-12T08:00:00Z',
                    end: '2024-08-20T15:00:00Z'
                },
                history: []
            },
            patient: {
                id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                locator: 'patient.fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-08T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '7e8bf7a6-7dca-41c8-af11-9346da764c1e',
                data: {
                    surname: 'Johansson', // Translated surname
                    name: 'David', // No need to translate
                    gender: 'male', // Translated gender
                    birth_date: '1980-02-28',
                    valid_from: '2024-03-01',
                    valid_until: '2025-03-01'
                },
                history: [],
                i18n: {}
            },
            current_place: dummyVC_Place, // No changes, as per previous pattern
            last_diagnosis: dummyVC_Diagnose // No changes, as per previous pattern
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440780',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440780',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
            owner_departments: ['orthopedics'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-13T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
            related_case_id: '7e8bf7a6-7dca-41c8-af11-9346da764c1e',
            data: {
                note: 'Patient wurde erfolgreich operiert.', // Translated note
                date_and_time: '2024-08-13T13:00:00Z'
            },
            history: [
                {
                    data: {
                        note: 'Patient wurde erfolgreich operiert.', // Translated note
                        date_and_time: ''
                    },
                    modifiedAt: '2024-08-13T13:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Weiß', // Translated surname
                        name: 'Jakob', // Translated name
                        validSince: '2021-03-01',
                        validUntil: '2026-03-01'
                    }
                }
            ],
            i18n: {
                de: {note: 'Notizen', date_and_time: 'Datum und Zeit'},
                en: {note: 'Note', date_and_time: 'Date and time'}
            }
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441114',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441114',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844564'],
                owner_departments: ['orthopedics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-13T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'fd4d1ade-7605-45d3-a495-c98a2bdabd11',
                related_case_id: '7e8bf7a6-7dca-41c8-af11-9346da764c1e',
                data: {
                    task_name: 'Rehabilitation nach der Operation', // Translated task name
                    details:
                        'Planung und Überwachung der Physiotherapie-Sitzungen.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Weiß', // Translated surname
                        name: 'Jakob', // Translated name
                        validSince: '2021-03-01',
                        validUntil: '2026-03-01'
                    },
                    goalDateOn: '2024-08-20T12:00:00Z',
                    goal_date: 'on',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Braun', // Translated surname
                        name: 'Thomas', // No need to translate
                        validSince: '2022-02-01',
                        validUntil: '2027-02-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Rehabilitation nach der Operation', // Translated task name
                            details:
                                'Planung und Überwachung der Physiotherapie-Sitzungen.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Weiß', // Translated surname
                                name: 'Jakob', // Translated name
                                validSince: '2021-03-01',
                                validUntil: '2026-03-01'
                            },
                            goalDateOn: '2024-08-20T12:00:00Z',
                            goal_date: 'on',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Braun', // Translated surname
                                name: 'Thomas', // No need to translate
                                validSince: '2022-02-01',
                                validUntil: '2027-02-01'
                            }
                        },
                        modifiedAt: '2024-08-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Weiß', // Translated surname
                            name: 'Jakob', // Translated name
                            validSince: '2021-03-01',
                            validUntil: '2026-03-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Aufgabe',
                        details: 'Details',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers'
                    },
                    en: {
                        task_name: 'Task name',
                        details: 'Details',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            }
        ]
    },
    {
        patient_details: {
            case: {
                id: 'c70e8400-e29b-41d4-a716-446655440881',
                locator: 'case.c70e8400-e29b-41d4-a716-446655440881',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844565'],
                owner_departments: ['cardiology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-15T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                related_case_id: '50ade231-58f5-42e6-8880-387f15d90945',
                data: {
                    patient: {
                        surname: 'Martínez', // Translated surname
                        name: 'Sophia', // No need to translate
                        gender: 'female', // Translated gender
                        birth_date: '1980-11-07',
                        valid_from: '2024-03-01',
                        valid_until: '2025-03-01'
                    },
                    admission_date: '2024-08-15',
                    discharge_date: '2024-08-22',
                    planned_discharge_date: '2024-08-22',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP04',
                                        shortName: 'CorpCardio',
                                        name: 'Kardiologie Gesellschaft', // Translated name
                                        validSince: '2024-03-01'
                                    },
                                    code: 'LOC04',
                                    shortName: 'LocCardio',
                                    name: 'Kardiologie Standort', // Translated name
                                    validSince: '2024-03-01'
                                },
                                code: 'CTR04',
                                shortName: 'ZentrumCardio', // Translated shortName
                                name: 'Kardiologie Zentrum', // Translated name
                                validSince: '2024-03-01'
                            }
                        ],
                        code: 'DEPT04',
                        shortName: 'AbtCardio', // Translated shortName
                        name: 'Abteilung für Kardiologie', // Translated name
                        validSince: '2024-03-01'
                    },
                    start: '2024-08-15T08:00:00Z',
                    end: '2024-08-22T15:00:00Z'
                },
                history: []
            },
            patient: {
                id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                locator: 'patient.c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844565'],
                owner_departments: ['cardiology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-09T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
                data: {
                    surname: 'Martínez', // Translated surname
                    name: 'Sophia', // No need to translate
                    gender: 'female', // Translated gender
                    birth_date: '1980-11-07',
                    valid_from: '2024-03-01',
                    valid_until: '2025-03-01'
                },
                history: [],
                i18n: {}
            },
            current_place: dummyVC_Place, // No changes, as per previous pattern
            last_diagnosis: dummyVC_Diagnose // No changes, as per previous pattern
        },
        visit_record: {
            id: 'c70e8400-e29b-41d4-a716-446655440882',
            locator: 'visit-record-c70e8400-e29b-41d4-a716-446655440882',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844565'],
            owner_departments: ['cardiology'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-16T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
            related_case_id: '50ade231-58f5-42e6-8880-387f15d90945',
            data: {
                note: 'Der Zustand des Patienten ist mit der aktuellen Behandlung stabil.', // Translated note
                date_and_time: '2024-08-16T13:00:00Z'
            },
            history: [
                {
                    data: {
                        note: 'Der Zustand des Patienten ist mit der aktuellen Behandlung stabil.', // Translated note
                        date_and_time: ''
                    },
                    modifiedAt: '2024-08-16T13:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Davis', // No need to translate
                        name: 'Michael', // No need to translate
                        validSince: '2021-04-01',
                        validUntil: '2026-04-01'
                    }
                }
            ],
            i18n: {
                de: {note: 'Notizen', date_and_time: 'Datum und Zeit'},
                en: {note: 'Note', date_and_time: 'Date and time'}
            }
        },
        tasks: [
            {
                id: 'f10e8400-e29b-41d4-a716-446655441115',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441115',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844565'],
                owner_departments: ['cardiology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-16T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'c4d5e6f7-88g9-4h0i-j1kl-2m34n567o890',
                related_case_id: '50ade231-58f5-42e6-8880-387f15d90945',
                data: {
                    task_name: 'Dialyse-Plan', // Translated task name
                    details:
                        'Stellen Sie sicher, dass der Patient die Dialyse gemäß dem Zeitplan erhält.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Davis', // No need to translate
                        name: 'Michael', // No need to translate
                        validSince: '2021-04-01',
                        validUntil: '2026-04-01'
                    },
                    goalDateOn: '2024-08-20T12:00:00Z',
                    goal_date: 'on',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Miller', // No need to translate
                        name: 'John', // No need to translate
                        validSince: '2022-03-01',
                        validUntil: '2027-03-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Dialyse-Plan', // Translated task name
                            details:
                                'Stellen Sie sicher, dass der Patient die Dialyse gemäß dem Zeitplan erhält.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Davis', // No need to translate
                                name: 'Michael', // No need to translate
                                validSince: '2021-04-01',
                                validUntil: '2026-04-01'
                            },
                            goalDateOn: '2024-08-20T12:00:00Z',
                            goal_date: 'on',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Miller', // No need to translate
                                name: 'John', // No need to translate
                                validSince: '2022-03-01',
                                validUntil: '2027-03-01'
                            }
                        },
                        modifiedAt: '2024-08-17T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Davis', // No need to translate
                            name: 'Michael', // No need to translate
                            validSince: '2021-04-01',
                            validUntil: '2026-04-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Aufgabe',
                        details: 'Details',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notiz',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers'
                    },
                    en: {
                        task_name: 'Task name',
                        details: 'Details',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            }
        ]
    },
    {
        patient_details: {
            case: {
                id: 'd70e8400-e29b-41d4-a716-446655440992',
                locator: 'case.d70e8400-e29b-41d4-a716-446655440992',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844566'],
                owner_departments: ['general_medicine'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-18T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                related_case_id: '6166a122-f7c2-4deb-add2-f6dfd3dc93f8',
                data: {
                    patient: {
                        surname: 'Garcia', // No need to translate
                        name: 'James', // No need to translate
                        gender: 'male', // Translated gender
                        birth_date: '1980-03-05',
                        valid_from: '2024-04-01',
                        valid_until: '2025-04-01'
                    },
                    admission_date: '2024-08-18',
                    discharge_date: '2024-08-24',
                    planned_discharge_date: '2024-08-24',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP05',
                                        shortName: 'CorpGenMed',
                                        name: 'Allgemeinmedizinische Gesellschaft', // Translated name
                                        validSince: '2024-04-01'
                                    },
                                    code: 'LOC05',
                                    shortName: 'LocGenMed',
                                    name: 'Allgemeinmedizinischer Standort', // Translated name
                                    validSince: '2024-04-01'
                                },
                                code: 'CTR05',
                                shortName: 'ZentrumGenMed', // Translated shortName
                                name: 'Allgemeinmedizinisches Zentrum', // Translated name
                                validSince: '2024-04-01'
                            }
                        ],
                        code: 'DEPT05',
                        shortName: 'AbtGenMed', // Translated shortName
                        name: 'Abteilung für Allgemeinmedizin', // Translated name
                        validSince: '2024-04-01'
                    },
                    start: '2024-08-18T08:00:00Z',
                    end: '2024-08-24T15:00:00Z'
                },
                history: []
            },
            patient: {
                id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                locator: 'patient.d7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844566'],
                owner_departments: ['general_medicine'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-10T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                related_case_id: '6166a122-f7c2-4deb-add2-f6dfd3dc93f8',
                data: {
                    surname: 'Garcia', // No need to translate
                    name: 'James', // No need to translate
                    gender: 'male', // Translated gender
                    birth_date: '1980-03-05',
                    valid_from: '2024-04-01',
                    valid_until: '2025-04-01'
                },
                history: [],
                i18n: {}
            },
            current_place: dummyVC_Place, // No changes as per previous example
            last_diagnosis: dummyVC_Diagnose // No changes as per previous example
        },
        visit_record: {
            id: 'e70e8400-e29b-41d4-a716-446655440993',
            locator: 'visit-record-e70e8400-e29b-41d4-a716-446655440993',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844566'],
            owner_departments: ['general_medicine'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-19T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
            related_case_id: '6166a122-f7c2-4deb-add2-f6dfd3dc93f8',
            data: {
                note: 'Der Patient erholt sich gut von der Fraktur.', // Translated note
                date_and_time: '2024-08-19T13:00:00Z'
            },
            history: [
                {
                    data: {
                        note: 'Der Patient erholt sich gut von der Fraktur.', // Translated note
                        date_and_time: ''
                    },
                    modifiedAt: '2024-08-19T13:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Lopez', // No need to translate
                        name: 'Maria', // No need to translate
                        validSince: '2021-05-01',
                        validUntil: '2026-05-01'
                    }
                }
            ],
            i18n: {
                de: {note: 'Notizen', date_and_time: 'Datum und Zeit'},
                en: {note: 'Note', date_and_time: 'Date and time'}
            }
        },
        tasks: [
            {
                id: 'g10e8400-e29b-41d4-a716-446655441116',
                locator: 'task-g10e8400-e29b-41d4-a716-446655441116',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844566'],
                owner_departments: ['general_medicine'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-19T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'd7e8f9fa-0b1c-2d3e-4f5g-6h7i8j9k0l1m',
                related_case_id: '6166a122-f7c2-4deb-add2-f6dfd3dc93f8',
                data: {
                    task_name: 'Nachuntersuchung der Fraktur', // Translated task name
                    details:
                        'Stellen Sie sicher, dass die Fraktur des Patienten wie erwartet heilt.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Lopez', // No need to translate
                        name: 'Maria', // No need to translate
                        validSince: '2021-05-01',
                        validUntil: '2026-05-01'
                    },
                    goalDateOn: '2024-08-23T12:00:00Z',
                    goal_date: 'on',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Martin', // No need to translate
                        name: 'Emily', // No need to translate
                        validSince: '2022-04-01',
                        validUntil: '2027-04-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Nachuntersuchung der Fraktur', // Translated task name
                            details:
                                'Stellen Sie sicher, dass die Fraktur des Patienten wie erwartet heilt.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Lopez', // No need to translate
                                name: 'Maria', // No need to translate
                                validSince: '2021-05-01',
                                validUntil: '2026-05-01'
                            },
                            goalDateOn: '2024-08-23T12:00:00Z',
                            goal_date: 'on',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Martin', // No need to translate
                                name: 'Emily', // No need to translate
                                validSince: '2022-04-01',
                                validUntil: '2027-04-01'
                            }
                        },
                        modifiedAt: '2024-08-20T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Lopez', // No need to translate
                            name: 'Maria', // No need to translate
                            validSince: '2021-05-01',
                            validUntil: '2026-05-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Notizen',
                        details: 'Datum und Zeit',
                        priority: 'Priorität',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notiz',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers'
                    },
                    en: {
                        task_name: 'Task name',
                        details: 'Details',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            }
        ]
    },
    {
        patient_details: {
            case: {
                id: 'f70e8400-e29b-41d4-a716-446655440994',
                locator: 'case.f70e8400-e29b-41d4-a716-446655440994',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844567'],
                owner_departments: ['pediatrics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-20T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                related_case_id: 'c85fe2e0-c9f0-4de3-9374-a75dd583d19c',
                data: {
                    patient: {
                        surname: 'Wilson', // No need to translate
                        name: 'Linda', // No need to translate
                        gender: 'female', // Translated gender
                        birth_date: '1980-09-29',
                        valid_from: '2024-05-01',
                        valid_until: '2025-05-01'
                    },
                    admission_date: '2024-08-20',
                    discharge_date: '2024-08-26',
                    planned_discharge_date: '2024-08-26',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP06',
                                        shortName: 'CorpPediatrics',
                                        name: 'Pädiatrie-Gesellschaft', // Translated name
                                        validSince: '2024-05-01'
                                    },
                                    code: 'LOC06',
                                    shortName: 'LocPediatrics',
                                    name: 'Pädiatrischer Standort', // Translated name
                                    validSince: '2024-05-01'
                                },
                                code: 'CTR06',
                                shortName: 'ZentrumPädiatrie', // Translated shortName
                                name: 'Pädiatrisches Zentrum', // Translated name
                                validSince: '2024-05-01'
                            }
                        ],
                        code: 'DEPT06',
                        shortName: 'AbtPädiatrie', // Translated shortName
                        name: 'Pädiatrische Abteilung', // Translated name
                        validSince: '2024-05-01'
                    },
                    start: '2024-08-20T08:00:00Z',
                    end: '2024-08-26T15:00:00Z'
                },
                history: []
            },
            patient: {
                id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                locator: 'patient.e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844567'],
                owner_departments: ['pediatrics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-11T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                related_case_id: 'related_case_id_005',
                data: {
                    surname: 'Wilson', // No need to translate
                    name: 'Linda', // No need to translate
                    gender: 'female', // Translated gender
                    birth_date: '1980-09-29',
                    valid_from: '2024-05-01',
                    valid_until: '2025-05-01'
                },
                history: [],
                i18n: {}
            },
            current_place: dummyVC_Place, // No changes as per previous examples
            last_diagnosis: dummyVC_Diagnose // No changes as per previous examples
        },
        visit_record: {
            id: 'f70e8400-e29b-41d4-a716-446655440995',
            locator: 'visit-record-f70e8400-e29b-41d4-a716-446655440995',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844567'],
            owner_departments: ['pediatrics'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-21T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
            related_case_id: 'c85fe2e0-c9f0-4de3-9374-a75dd583d19c',
            data: {
                note: 'Der Patient hat gut auf die Flüssigkeitstherapie angesprochen.', // Translated note
                date_and_time: '2024-08-21T13:00:00Z'
            },
            history: [
                {
                    data: {
                        note: 'Der Patient hat gut auf die Flüssigkeitstherapie angesprochen.', // Translated note
                        date_and_time: ''
                    },
                    modifiedAt: '2024-08-21T13:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Johnson', // No need to translate
                        name: 'David', // No need to translate
                        validSince: '2021-06-01',
                        validUntil: '2026-06-01'
                    }
                }
            ],
            i18n: {
                de: {note: 'Notizen', date_and_time: 'Datum und Zeit'},
                en: {note: 'Note', date_and_time: 'Date and time'}
            }
        },
        tasks: [
            {
                id: 'h10e8400-e29b-41d4-a716-446655441117',
                locator: 'task-h10e8400-e29b-41d4-a716-446655441117',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844567'],
                owner_departments: ['pediatrics'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-21T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: 'e8f9g0h1-2i3j-4k5l-6m7n-8o9p0q1r2s3t',
                related_case_id: 'c85fe2e0-c9f0-4de3-9374-a75dd583d19c',
                data: {
                    task_name: 'Überwachung der Flüssigkeitstherapie', // Translated task name
                    details:
                        'Überwachung der Reaktion des Patienten auf die Flüssigkeitstherapie.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Johnson', // No need to translate
                        name: 'David', // No need to translate
                        validSince: '2021-06-01',
                        validUntil: '2026-06-01'
                    },
                    goalDateOn: '2024-08-25T12:00:00Z',
                    goal_date: 'on',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Harris', // No need to translate
                        name: 'Anna', // No need to translate
                        validSince: '2022-05-01',
                        validUntil: '2027-05-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Überwachung der Flüssigkeitstherapie', // Translated task name
                            details:
                                'Überwachung der Reaktion des Patienten auf die Flüssigkeitstherapie.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Johnson', // No need to translate
                                name: 'David', // No need to translate
                                validSince: '2021-06-01',
                                validUntil: '2026-06-01'
                            },
                            goalDateOn: '2024-08-25T12:00:00Z',
                            goal_date: 'on',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Harris', // No need to translate
                                name: 'Anna', // No need to translate
                                validSince: '2022-05-01',
                                validUntil: '2027-05-01'
                            }
                        },
                        modifiedAt: '2024-08-22T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Johnson', // No need to translate
                            name: 'David', // No need to translate
                            validSince: '2021-06-01',
                            validUntil: '2026-06-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Überwachung der Flüssigkeitstherapie',
                        details:
                            'Überwachung der Reaktion des Patienten auf die Flüssigkeitstherapie.',
                        priority: 'high',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers'
                    },
                    en: {
                        task_name: 'Fluid Therapy Monitoring',
                        details: 'Monitor patient’s response to fluid therapy.',
                        priority: 'Priority',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            }
        ]
    },
    {
        patient_details: {
            case: {
                id: 'g80e8400-e29b-41d4-a716-446655440996',
                locator: 'case.g80e8400-e29b-41d4-a716-446655440996',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844568'],
                owner_departments: ['dermatology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-22T12:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
                related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
                data: {
                    patient: {
                        surname: 'Anderson', // No need to translate
                        name: 'Charles', // No need to translate
                        gender: 'male', // Translated gender
                        birth_date: '1980-10-24',
                        valid_from: '2024-05-01',
                        valid_until: '2025-05-01'
                    },
                    admission_date: '2024-08-22',
                    discharge_date: '2024-08-28',
                    planned_discharge_date: '2024-08-28',
                    department: {
                        centers: [
                            {
                                location: {
                                    corporation: {
                                        code: 'CORP07',
                                        shortName: 'CorpDerm',
                                        name: 'Dermatologische Gesellschaft', // Translated name
                                        validSince: '2024-05-01'
                                    },
                                    code: 'LOC07',
                                    shortName: 'LocDerm',
                                    name: 'Dermatologischer Standort', // Translated name
                                    validSince: '2024-05-01'
                                },
                                code: 'CTR07',
                                shortName: 'ZentrumDerm', // Translated shortName
                                name: 'Dermatologisches Zentrum', // Translated name
                                validSince: '2024-05-01'
                            }
                        ],
                        code: 'DEPT07',
                        shortName: 'AbtDerm', // Translated shortName
                        name: 'Dermatologische Abteilung', // Translated name
                        validSince: '2024-05-01'
                    },
                    start: '2024-08-22T08:00:00Z',
                    end: '2024-08-28T15:00:00Z'
                },
                history: []
            },
            patient: {
                id: '550e8400-e29b-41d4-a716-446655440000',
                locator: 'patient.550e8400-e29b-41d4-a716-446655440000',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844568'],
                owner_departments: ['dermatology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-04-12T10:00:00Z',
                status: 'final', // Not translated
                related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
                related_case_id: 'related_case_id_006',
                data: {
                    surname: 'Anderson', // No need to translate
                    name: 'Charles', // No need to translate
                    gender: 'male', // Translated gender
                    birth_date: '1980-10-24',
                    valid_from: '2024-05-01',
                    valid_until: '2025-05-01'
                },
                history: [],
                i18n: {}
            },
            current_place: dummyVC_Place, // No changes as per previous examples
            last_diagnosis: dummyVC_Diagnose // No changes as per previous examples
        },
        visit_record: {
            id: 'g80e8400-e29b-41d4-a716-446655440997',
            locator: 'visit-record-g80e8400-e29b-41d4-a716-446655440997',
            owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844568'],
            owner_departments: ['dermatology'], // Not translated
            main_owner_job_type: 'doctor', // Not translated
            created_at: '2024-08-23T13:00:00Z',
            status: 'final', // Not translated
            related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
            related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
            data: {
                note: 'Der Patient spricht gut auf die Behandlung an. Fortsetzen mit der aktuellen Therapie.', // Translated note
                date_and_time: '2024-08-23T13:00:00Z'
            },
            history: [
                {
                    data: {
                        note: 'Der Patient spricht gut auf die Behandlung an. Fortsetzen mit der aktuellen Therapie.', // Translated note
                        date_and_time: '2024-08-23T13:00:00Z'
                    },
                    modifiedAt: '2024-08-23T14:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Morris', // No need to translate
                        name: 'Jessica', // No need to translate
                        validSince: '2021-07-01',
                        validUntil: '2026-07-01'
                    }
                },
                {
                    data: {
                        note: 'Der Patient spricht gut auf die Behandlung an.', // Translated note
                        date_and_time: '2024-08-23T13:00:00Z'
                    },
                    modifiedAt: '2024-08-23T16:00:00Z',
                    modifiedBy: {
                        userId: 'dummy_user_id',
                        surname: 'Morris', // No need to translate
                        name: 'Jessica', // No need to translate
                        validSince: '2021-07-01',
                        validUntil: '2026-07-01'
                    }
                }
            ],
            i18n: {
                de: {note: 'Notizen', date_and_time: 'Datum und Zeit'},
                en: {note: 'Note', date_and_time: 'Date and time'}
            }
        },
        tasks: [
            {
                id: 'i10e8400-e29b-41d4-a716-446655441118',
                locator: 'task-i10e8400-e29b-41d4-a716-446655441118',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844568'],
                owner_departments: ['dermatology'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-23T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
                related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
                data: {
                    task_name: 'Auftragen von topischen Behandlungen', // Translated task name
                    details:
                        'Anwendung und Überwachung der Wirksamkeit topischer Behandlungen.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Morris', // No need to translate
                        name: 'Jessica', // No need to translate
                        validSince: '2021-07-01',
                        validUntil: '2026-07-01'
                    },
                    goalDateOn: '2024-08-27T12:00:00Z',
                    goal_date: 'on',
                    done: false,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Harris', // No need to translate
                        name: 'Anna', // No need to translate
                        validSince: '2022-05-01',
                        validUntil: '2027-05-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Auftragen von topischen Behandlungen', // Translated task name
                            details:
                                'Anwendung und Überwachung der Wirksamkeit topischer Behandlungen.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Morris', // No need to translate
                                name: 'Jessica', // No need to translate
                                validSince: '2021-07-01',
                                validUntil: '2026-07-01'
                            },
                            goalDateOn: '2024-08-27T12:00:00Z',
                            goal_date: 'on',
                            done: false,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Harris', // No need to translate
                                name: 'Anna', // No need to translate
                                validSince: '2022-05-01',
                                validUntil: '2027-05-01'
                            }
                        },
                        modifiedAt: '2024-08-24T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Morris', // No need to translate
                            name: 'Jessica', // No need to translate
                            validSince: '2021-07-01',
                            validUntil: '2026-07-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Auftragen von topischen Behandlungen',
                        details:
                            'Anwendung und Überwachung der Wirksamkeit topischer Behandlungen.',
                        priority: 'high',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers'
                    },
                    en: {
                        task_name: 'Topical Treatment Application',
                        details:
                            'Apply and monitor effectiveness of topical treatments.',
                        priority: 'high',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            },
            {
                id: 'f10e8400-e29b-41d4-a716-446655441111',
                locator: 'task-f10e8400-e29b-41d4-a716-446655441111',
                owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
                owner_departments: ['general_medicine'], // Not translated
                main_owner_job_type: 'doctor', // Not translated
                created_at: '2024-08-09T14:30:00Z',
                status: 'final', // Not translated
                related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
                related_case_id: 'ff4f9577-7198-4ebf-adfe-03205a962439',
                data: {
                    task_name: 'Überwachung der Vitalparameter', // Translated task name
                    details:
                        'Überwachung der Vitalparameter alle 4 Stunden aufgrund von Verdacht auf Meningitis.', // Translated details
                    priority: 'high', // Translated priority
                    editor: {
                        userId: 'dummy_user_id',
                        surname: 'Müller', // No need to translate
                        name: 'Erika', // No need to translate
                        validSince: '2020-01-01',
                        validUntil: '2025-01-01'
                    },
                    goalDateOn: '2024-04-13T12:00:00Z',
                    goal_date: 'on',
                    done: true,
                    contractor: {
                        userId: 'dummy_user_id',
                        surname: 'Schmidt', // No need to translate
                        name: 'Hans', // No need to translate
                        validSince: '2021-01-01',
                        validUntil: '2026-01-01'
                    }
                },
                history: [
                    {
                        data: {
                            task_name: 'Überwachung der Vitalparameter', // Translated task name
                            details:
                                'Überwachung der Vitalparameter alle 4 Stunden aufgrund von Verdacht auf Meningitis.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Müller', // No need to translate
                                name: 'Erika', // No need to translate
                                validSince: '2020-01-01',
                                validUntil: '2025-01-01'
                            },
                            goalDateOn: '2024-04-13T12:00:00Z',
                            goal_date: 'on',
                            done: true,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Schmidt', // No need to translate
                                name: 'Hans', // No need to translate
                                validSince: '2021-01-01',
                                validUntil: '2026-01-01'
                            }
                        },
                        modifiedAt: '2024-04-13T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Müller', // No need to translate
                            name: 'Erika', // No need to translate
                            validSince: '2020-01-01',
                            validUntil: '2025-01-01'
                        }
                    },
                    {
                        data: {
                            task_name: 'Überwachung der Vitalparameter', // Translated task name
                            details:
                                'Überwachung der Vitalparameter alle 4 Stunden aufgrund von Verdacht auf Meningitis.', // Translated details
                            priority: 'high', // Translated priority
                            editor: {
                                userId: 'dummy_id',
                                surname: 'Müller', // No need to translate
                                name: 'Erika', // No need to translate
                                validSince: '2020-01-01',
                                validUntil: '2025-01-01'
                            },
                            goalDateOn: '2024-04-13T12:00:00Z',
                            goal_date: 'on',
                            done: true,
                            contractor: {
                                userId: 'dummy_id',
                                surname: 'Schmidt', // No need to translate
                                name: 'Hans', // No need to translate
                                validSince: '2021-01-01',
                                validUntil: '2026-01-01'
                            }
                        },
                        modifiedAt: '2024-04-14T14:00:00Z',
                        modifiedBy: {
                            userId: 'dummy_user_id',
                            surname: 'Müller', // No need to translate
                            name: 'Erika', // No need to translate
                            validSince: '2020-01-01',
                            validUntil: '2025-01-01'
                        }
                    }
                ],
                i18n: {
                    de: {
                        task_name: 'Überwachung der Vitalparameter',
                        details:
                            'Überwachung der Vitalparameter alle 4 Stunden aufgrund von Verdacht auf Meningitis.',
                        priority: 'high',
                        goal_date: 'Zieldatum',
                        done: 'Erledigt',
                        notes: 'Notizen',
                        contractor: 'Auftragnehmer',
                        editor_surname: 'Nachname des Bearbeiters',
                        editor_name: 'Vorname des Bearbeiters',
                        contractor_surname: 'Nachname des Auftragnehmers',
                        contractor_name: 'Vorname des Auftragnehmers'
                    },
                    en: {
                        task_name: 'Vital Signs Monitoring',
                        details:
                            'Monitor vital signs every 4 hours due to suspected meningitis.',
                        priority: 'high',
                        goal_date: 'Goal Date',
                        done: 'Done',
                        notes: 'Note',
                        contractor: 'Contractor',
                        editor_surname: 'Editor surname',
                        editor_name: 'Editor name',
                        contractor_surname: 'Contractor surname',
                        contractor_name: 'Contractor name'
                    }
                }
            }
        ]
    }
];

export const dummyVC_LabReport: VC_LabReport = {
    id: '7660ea17-7dfe-49bf-8a28-a9bb6bdf86d1',
    related_case_id: '850e8400-e29b-41d4-a716-446655440333',
    locator: 'case.record.lab.850e8400-e29b-41d4-a716-446655440333',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['cardiology'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    data: {
        'results': [
            {
                'id': 102,
                'modified_at': '2024-09-23 10:49:19.847243+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 10:49:19.847270+00:00',
                'observation_date_time': '2024-09-23 10:49:19.847274+00:00',
                'reported_date_time': '2024-09-23 10:49:19.847278+00:00',
                'filler_order_number': '71907610',
                'placer_order_number': '71907610',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.6',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.6',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '70',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.28',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '23',
                                'limits': '<17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibgav',
                                    'short_text': 'Bilirubin, gesamt (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '149',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '110',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.3',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.3',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methbv',
                                    'short_text': 'Met-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 240,
                'modified_at': '2024-09-23 10:49:46.688569+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 10:49:46.688595+00:00',
                'observation_date_time': '2024-09-23 10:49:46.688599+00:00',
                'reported_date_time': '2024-09-23 10:49:46.688603+00:00',
                'filler_order_number': '27719115',
                'placer_order_number': '27719115',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '46.3',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 337,
                'modified_at': '2024-09-23 10:50:00.859688+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:50:00.859713+00:00',
                'observation_date_time': '2024-09-23 10:50:00.859717+00:00',
                'reported_date_time': '2024-09-23 10:50:00.859721+00:00',
                'filler_order_number': '71907669',
                'placer_order_number': '71907669',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.40',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.53',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.3',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '112',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.9',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.25',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 519,
                'modified_at': '2024-09-23 10:50:47.204403+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:50:47.204429+00:00',
                'observation_date_time': '2024-09-23 10:50:47.204433+00:00',
                'reported_date_time': '2024-09-23 10:50:47.204436+00:00',
                'filler_order_number': '71907723',
                'placer_order_number': '71907723',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?3.9',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.55',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?28.7',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?4.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '14.6',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?99.4',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '149',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '112',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.25',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 520,
                'modified_at': '2024-09-23 10:50:47.438702+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 10:50:47.438728+00:00',
                'observation_date_time': '2024-09-23 10:50:47.438732+00:00',
                'reported_date_time': '2024-09-23 10:50:47.438735+00:00',
                'filler_order_number': '71907724',
                'placer_order_number': '71907724',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.51',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.0',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.6',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '69',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.28',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '149',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '112',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.9',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 521,
                'modified_at': '2024-09-23 10:50:47.666869+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:50:47.666895+00:00',
                'observation_date_time': '2024-09-23 10:50:47.666900+00:00',
                'reported_date_time': '2024-09-23 10:50:47.666903+00:00',
                'filler_order_number': '71907725',
                'placer_order_number': '71907725',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.00',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.55',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.4',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '16',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '149',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '112',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.6',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.1',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.25',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.3',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 748,
                'modified_at': '2024-09-23 10:51:42.112269+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:51:42.112296+00:00',
                'observation_date_time': '2024-09-23 10:51:42.112300+00:00',
                'reported_date_time': '2024-09-23 10:51:42.112304+00:00',
                'filler_order_number': '71907777',
                'placer_order_number': '71907777',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.57',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.0',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '17.8',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '149',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.10',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.6',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.26',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 846,
                'modified_at': '2024-09-23 10:52:06.371938+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 10:52:06.371969+00:00',
                'observation_date_time': '2024-09-23 10:52:06.371973+00:00',
                'reported_date_time': '2024-09-23 10:52:06.371977+00:00',
                'filler_order_number': '27853380',
                'placer_order_number': '27853380',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '49.2',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 896,
                'modified_at': '2024-09-23 10:52:17.957672+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:52:17.957697+00:00',
                'observation_date_time': '2024-09-23 10:52:17.957701+00:00',
                'reported_date_time': '2024-09-23 10:52:17.957705+00:00',
                'filler_order_number': '71907865',
                'placer_order_number': '71907865',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.70',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.2',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '16.5',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.10',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.7',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.9',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 961,
                'modified_at': '2024-09-23 10:52:32.066195+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 10:52:32.066222+00:00',
                'observation_date_time': '2024-09-23 10:52:32.066226+00:00',
                'reported_date_time': '2024-09-23 10:52:32.066229+00:00',
                'filler_order_number': '71907897',
                'placer_order_number': '71907897',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.80',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.37',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.2',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.6',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '72',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.30',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.2',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 962,
                'modified_at': '2024-09-23 10:52:32.282522+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:52:32.282548+00:00',
                'observation_date_time': '2024-09-23 10:52:32.282551+00:00',
                'reported_date_time': '2024-09-23 10:52:32.282555+00:00',
                'filler_order_number': '71907898',
                'placer_order_number': '71907898',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.90',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.4',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.5',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '151',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.3',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.1',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.27',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1023,
                'modified_at': '2024-09-23 10:52:45.748300+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 10:52:45.748329+00:00',
                'observation_date_time': '2024-09-23 10:52:45.748333+00:00',
                'reported_date_time': '2024-09-23 10:52:45.748336+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1034,
                'modified_at': '2024-09-23 10:52:47.207582+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 10:52:47.207608+00:00',
                'observation_date_time': '2024-09-23 10:52:47.207612+00:00',
                'reported_date_time': '2024-09-23 10:52:47.207616+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.4',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.90',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '87',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.2',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1037,
                'modified_at': '2024-09-23 10:52:48.021940+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 10:52:48.021966+00:00',
                'observation_date_time': '2024-09-23 10:52:48.021970+00:00',
                'reported_date_time': '2024-09-23 10:52:48.021973+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.4',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.90',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '87',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.2',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.1',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.34',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.51',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.7',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.1',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '338',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '940',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '111',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '57',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.9',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '267',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1038,
                'modified_at': '2024-09-23 10:52:48.414958+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 10:52:48.414984+00:00',
                'observation_date_time': '2024-09-23 10:52:48.414989+00:00',
                'reported_date_time': '2024-09-23 10:52:48.414992+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.4',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.90',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '87',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.2',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.1',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.34',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.51',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.7',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.1',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '338',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '940',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '846',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '379',
                                'limits': '28 - 72',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '111',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '57',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.9',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '267',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1066,
                'modified_at': '2024-09-23 10:52:53.487358+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 10:52:53.487384+00:00',
                'observation_date_time': '2024-09-23 10:52:53.487389+00:00',
                'reported_date_time': '2024-09-23 10:52:53.487392+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.4',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.90',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '87',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '2 - 8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuaa',
                                    'short_text': 'Neutrophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.55',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgran',
                                    'short_text': 'Unreife Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.15',
                                'limits': '1 - 3,2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyaa',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.56',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moaa',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.52',
                                'limits': '< 0,7',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoaa',
                                    'short_text': 'Eosinophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.15',
                                'limits': '< 0,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'baa',
                                    'short_text': 'Basophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '65.0',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuar',
                                    'short_text': 'Neutrophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.9',
                                'limits': '<2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgranr',
                                    'short_text': 'Unreife Granulozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '8.4',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyar',
                                    'short_text': 'Lymphozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.1',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moar',
                                    'short_text': 'Monozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoar',
                                    'short_text': 'Eosinophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.6',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bar',
                                    'short_text': 'Basophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.2',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.1',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.34',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.51',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.7',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.1',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '338',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '940',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '846',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '379',
                                'limits': '28 - 72',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '111',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '57',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.9',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '267',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1151,
                'modified_at': '2024-09-23 10:53:16.604889+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:53:16.604915+00:00',
                'observation_date_time': '2024-09-23 10:53:16.604919+00:00',
                'reported_date_time': '2024-09-23 10:53:16.604922+00:00',
                'filler_order_number': '71907972',
                'placer_order_number': '71907972',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.40',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.6',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '16.5',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '17',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.00',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.27',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '1.0',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1201,
                'modified_at': '2024-09-23 10:53:25.360042+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 10:53:25.360069+00:00',
                'observation_date_time': '2024-09-23 10:53:25.360073+00:00',
                'reported_date_time': '2024-09-23 10:53:25.360076+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.4',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.90',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '87',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '2 - 8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuaa',
                                    'short_text': 'Neutrophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.55',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgran',
                                    'short_text': 'Unreife Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.15',
                                'limits': '1 - 3,2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyaa',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.56',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moaa',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.52',
                                'limits': '< 0,7',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoaa',
                                    'short_text': 'Eosinophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.15',
                                'limits': '< 0,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'baa',
                                    'short_text': 'Basophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '65.0',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuar',
                                    'short_text': 'Neutrophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.9',
                                'limits': '<2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgranr',
                                    'short_text': 'Unreife Granulozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '8.4',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyar',
                                    'short_text': 'Lymphozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.1',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moar',
                                    'short_text': 'Monozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoar',
                                    'short_text': 'Eosinophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.6',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bar',
                                    'short_text': 'Basophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.2',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.1',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.34',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.51',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.7',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.1',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '338',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '940',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '846',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '379',
                                'limits': '28 - 72',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '111',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '57',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.9',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '267',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1484,
                'modified_at': '2024-09-23 10:54:20.157912+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:54:20.157939+00:00',
                'observation_date_time': '2024-09-23 10:54:20.157943+00:00',
                'reported_date_time': '2024-09-23 10:54:20.157946+00:00',
                'filler_order_number': '71908053',
                'placer_order_number': '71908053',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.0',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.7',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '21.4',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '17',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.27',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1568,
                'modified_at': '2024-09-23 10:54:42.144467+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 10:54:42.144495+00:00',
                'observation_date_time': '2024-09-23 10:54:42.144499+00:00',
                'reported_date_time': '2024-09-23 10:54:42.144502+00:00',
                'filler_order_number': '37283907',
                'placer_order_number': '37283907',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '75',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '408',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '435',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '822',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.280',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.727',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1627,
                'modified_at': '2024-09-23 10:55:01.750210+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 10:55:01.750237+00:00',
                'observation_date_time': '2024-09-23 10:55:01.750241+00:00',
                'reported_date_time': '2024-09-23 10:55:01.750245+00:00',
                'filler_order_number': '27719115',
                'placer_order_number': '27719115',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '46.3',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1629,
                'modified_at': '2024-09-23 10:55:02.551210+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, sonstiges, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 10:55:02.551236+00:00',
                'observation_date_time': '2024-09-23 10:55:02.551241+00:00',
                'reported_date_time': '2024-09-23 10:55:02.551244+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.4',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.90',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '87',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '2 - 8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuaa',
                                    'short_text': 'Neutrophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.55',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgran',
                                    'short_text': 'Unreife Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.15',
                                'limits': '1 - 3,2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyaa',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.56',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moaa',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.52',
                                'limits': '< 0,7',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoaa',
                                    'short_text': 'Eosinophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.15',
                                'limits': '< 0,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'baa',
                                    'short_text': 'Basophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '65.0',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuar',
                                    'short_text': 'Neutrophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.9',
                                'limits': '<2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgranr',
                                    'short_text': 'Unreife Granulozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '8.4',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyar',
                                    'short_text': 'Lymphozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.1',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moar',
                                    'short_text': 'Monozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoar',
                                    'short_text': 'Eosinophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.6',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bar',
                                    'short_text': 'Basophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.2',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hypch',
                                    'short_text': 'Hypochromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poch',
                                    'short_text': 'Polychromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'anis',
                                    'short_text': 'Anisozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bast',
                                    'short_text': 'basophile T\u00fcpfelung',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.1',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.34',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.51',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.7',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.1',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '338',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '940',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '846',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '379',
                                'limits': '28 - 72',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '111',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '57',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.9',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '267',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1682,
                'modified_at': '2024-09-23 10:55:23.107904+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 10:55:23.107931+00:00',
                'observation_date_time': '2024-09-23 10:55:23.107935+00:00',
                'reported_date_time': '2024-09-23 10:55:23.107939+00:00',
                'filler_order_number': '27853380',
                'placer_order_number': '27853380',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '49.2',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1683,
                'modified_at': '2024-09-23 10:55:23.451399+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, urinstix, sonstiges, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 10:55:23.451426+00:00',
                'observation_date_time': '2024-09-23 10:55:23.451430+00:00',
                'reported_date_time': '2024-09-23 10:55:23.451434+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.4',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.90',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '87',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '2 - 8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuaa',
                                    'short_text': 'Neutrophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.55',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgran',
                                    'short_text': 'Unreife Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.15',
                                'limits': '1 - 3,2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyaa',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.56',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moaa',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.52',
                                'limits': '< 0,7',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoaa',
                                    'short_text': 'Eosinophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.15',
                                'limits': '< 0,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'baa',
                                    'short_text': 'Basophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '65.0',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuar',
                                    'short_text': 'Neutrophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.9',
                                'limits': '<2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgranr',
                                    'short_text': 'Unreife Granulozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '8.4',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyar',
                                    'short_text': 'Lymphozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.1',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moar',
                                    'short_text': 'Monozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoar',
                                    'short_text': 'Eosinophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.6',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bar',
                                    'short_text': 'Basophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.2',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eos',
                                    'short_text': 'eosinophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bas',
                                    'short_text': 'basophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '7',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myel',
                                    'short_text': 'Myelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '7',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'meta',
                                    'short_text': 'Metamyelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16',
                                'limits': '<8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'stab',
                                    'short_text': 'neutrophile Stabkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '58',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'seg',
                                    'short_text': 'neutrophile Segmentkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lymp',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mono',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hypch',
                                    'short_text': 'Hypochromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poch',
                                    'short_text': 'Polychromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'anis',
                                    'short_text': 'Anisozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'urinstix',
                        'text_long': 'Urindiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 45,
                        'values': [
                            {
                                'value': '1.4',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbcm',
                                    'short_text': 'Erythroblasten (mikroskopisch)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bast',
                                    'short_text': 'basophile T\u00fcpfelung',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.1',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.34',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.51',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.7',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.1',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '338',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '940',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '846',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '379',
                                'limits': '28 - 72',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '111',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '57',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.9',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '267',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 1761,
                'modified_at': '2024-09-23 10:55:51.954930+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 10:55:51.954957+00:00',
                'observation_date_time': '2024-09-23 10:55:51.954962+00:00',
                'reported_date_time': '2024-09-23 10:55:51.954965+00:00',
                'filler_order_number': '37381205',
                'placer_order_number': '37381205',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '81',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '390',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '467',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '952',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.470',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.004',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 2133,
                'modified_at': '2024-09-23 10:57:59.168495+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:57:59.168522+00:00',
                'observation_date_time': '2024-09-23 10:57:59.168526+00:00',
                'reported_date_time': '2024-09-23 10:57:59.168529+00:00',
                'filler_order_number': '71908140',
                'placer_order_number': '71908140',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.1',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.1',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.7',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.80',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.26',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 2198,
                'modified_at': '2024-09-23 10:58:13.956210+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 10:58:13.956250+00:00',
                'observation_date_time': '2024-09-23 10:58:13.956254+00:00',
                'reported_date_time': '2024-09-23 10:58:13.956258+00:00',
                'filler_order_number': '71908150',
                'placer_order_number': '71908150',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.37',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.6',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.1',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.6',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '74',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.25',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.5',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 2398,
                'modified_at': '2024-09-23 10:59:03.058348+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 10:59:03.058376+00:00',
                'observation_date_time': '2024-09-23 10:59:03.058379+00:00',
                'reported_date_time': '2024-09-23 10:59:03.058383+00:00',
                'filler_order_number': '27829272',
                'placer_order_number': '27829272',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '45.1',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 2456,
                'modified_at': '2024-09-23 10:59:22.156577+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, urinstix, sonstiges, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 10:59:22.156604+00:00',
                'observation_date_time': '2024-09-23 10:59:22.156609+00:00',
                'reported_date_time': '2024-09-23 10:59:22.156612+00:00',
                'filler_order_number': '27858390',
                'placer_order_number': '27858390',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.4',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.90',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.7',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.0',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '87',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '2 - 8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuaa',
                                    'short_text': 'Neutrophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.55',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgran',
                                    'short_text': 'Unreife Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.15',
                                'limits': '1 - 3,2',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyaa',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.56',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moaa',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.52',
                                'limits': '< 0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoaa',
                                    'short_text': 'Eosinophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.15',
                                'limits': '< 0,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'baa',
                                    'short_text': 'Basophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '65.0',
                                'limits': '36 - 84',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuar',
                                    'short_text': 'Neutrophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.9',
                                'limits': '<2',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgranr',
                                    'short_text': 'Unreife Granulozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '8.4',
                                'limits': '20 - 42',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyar',
                                    'short_text': 'Lymphozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.1',
                                'limits': '< 15',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moar',
                                    'short_text': 'Monozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '< 5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoar',
                                    'short_text': 'Eosinophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.6',
                                'limits': '< 1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bar',
                                    'short_text': 'Basophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.2',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2',
                                'limits': '< 5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eos',
                                    'short_text': 'eosinophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0',
                                'limits': '< 1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bas',
                                    'short_text': 'basophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '7',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myel',
                                    'short_text': 'Myelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '7',
                                'limits': '< 1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'meta',
                                    'short_text': 'Metamyelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16',
                                'limits': '<8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'stab',
                                    'short_text': 'neutrophile Stabkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '58',
                                'limits': '36 - 84',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'seg',
                                    'short_text': 'neutrophile Segmentkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4',
                                'limits': '20 - 42',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lymp',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5',
                                'limits': '< 15',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mono',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hypch',
                                    'short_text': 'Hypochromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poch',
                                    'short_text': 'Polychromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'anis',
                                    'short_text': 'Anisozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'urinstix',
                        'text_long': 'Urindiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 45,
                        'values': [
                            {
                                'value': '1.4',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbcm',
                                    'short_text': 'Erythroblasten (mikroskopisch)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bast',
                                    'short_text': 'basophile T\u00fcpfelung',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.5',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.2',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93',
                                'limits': '75,2 - 154,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.1',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.34',
                                'limits': '2,20 - 2,55',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,74 - 1,03',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.51',
                                'limits': '0,81 - 1,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63.5',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.7',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.1',
                                'limits': '< 60',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '338',
                                'limits': '< 190',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '< 25',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '940',
                                'limits': '135 - 255',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '846',
                                'limits': '< 14',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '379',
                                'limits': '28 - 72',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '<24',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '111',
                                'limits': '59 - 104',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '57',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.9',
                                'limits': '2,76 - 8,07',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '<0,046',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '267',
                                'limits': '<5,0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 2558,
                'modified_at': '2024-09-23 10:59:46.191426+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 10:59:46.191453+00:00',
                'observation_date_time': '2024-09-23 10:59:46.191457+00:00',
                'reported_date_time': '2024-09-23 10:59:46.191461+00:00',
                'filler_order_number': '71908206',
                'placer_order_number': '71908206',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.90',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.5',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.6',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.5',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.21',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.4',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 2987,
                'modified_at': '2024-09-23 11:01:56.357308+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:01:56.357334+00:00',
                'observation_date_time': '2024-09-23 11:01:56.357338+00:00',
                'reported_date_time': '2024-09-23 11:01:56.357342+00:00',
                'filler_order_number': '27829272',
                'placer_order_number': '27829272',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '45.1',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3029,
                'modified_at': '2024-09-23 11:02:11.577650+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 11:02:11.577677+00:00',
                'observation_date_time': '2024-09-23 11:02:11.577681+00:00',
                'reported_date_time': '2024-09-23 11:02:11.577685+00:00',
                'filler_order_number': '37399815',
                'placer_order_number': '37399815',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '24',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '53.5',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '105',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '139',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.8',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Normalbefund; jedoch ist eine milde Form des von Willebrandsyndroms Typ 1 nicht mit Sicherheit auszuschlie\u00dfen. Die Diagnose kann nur im Zusammenhang mit der Klinik, der FA und EA sowie nach mehreren Kontrolluntersuchungen gestellt werden.'
                            },
                            {
                                'value': '0.130',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.003',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015! Probe h\u00e4molytisch korrigierter Befund'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3030,
                'modified_at': '2024-09-23 11:02:11.701720+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 11:02:11.701762+00:00',
                'observation_date_time': '2024-09-23 11:02:11.701766+00:00',
                'reported_date_time': '2024-09-23 11:02:11.701770+00:00',
                'filler_order_number': '37289425',
                'placer_order_number': '37289425',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '59',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '153',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '118',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '200',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.6',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.030',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.003',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3031,
                'modified_at': '2024-09-23 11:02:11.812235+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 11:02:11.812262+00:00',
                'observation_date_time': '2024-09-23 11:02:11.812266+00:00',
                'reported_date_time': '2024-09-23 11:02:11.812270+00:00',
                'filler_order_number': '37380358',
                'placer_order_number': '37380358',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '60',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '114',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '184',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '248',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.7',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.360',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.002',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015! korrigierter Befund'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3032,
                'modified_at': '2024-09-23 11:02:11.991356+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 11:02:11.991383+00:00',
                'observation_date_time': '2024-09-23 11:02:11.991387+00:00',
                'reported_date_time': '2024-09-23 11:02:11.991390+00:00',
                'filler_order_number': '37283907',
                'placer_order_number': '37283907',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '75',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '408',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '435',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '822',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.280',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '<0.001',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015! korrigierter Befund'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3276,
                'modified_at': '2024-09-23 11:03:15.967911+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:03:15.967939+00:00',
                'observation_date_time': '2024-09-23 11:03:15.967943+00:00',
                'reported_date_time': '2024-09-23 11:03:15.967946+00:00',
                'filler_order_number': '71908260',
                'placer_order_number': '71908260',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.00',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.4',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14.0',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.26',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3687,
                'modified_at': '2024-09-23 11:05:40.940686+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:05:40.940714+00:00',
                'observation_date_time': '2024-09-23 11:05:40.940718+00:00',
                'reported_date_time': '2024-09-23 11:05:40.940722+00:00',
                'filler_order_number': '71908343',
                'placer_order_number': '71908343',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.9',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.2',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '96',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.32',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3744,
                'modified_at': '2024-09-23 11:05:54.602264+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:05:54.602293+00:00',
                'observation_date_time': '2024-09-23 11:05:54.602297+00:00',
                'reported_date_time': '2024-09-23 11:05:54.602301+00:00',
                'filler_order_number': '71908357',
                'placer_order_number': '71908357',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.2',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.6',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.23',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '92',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3845,
                'modified_at': '2024-09-23 11:06:06.178379+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:06:06.178407+00:00',
                'observation_date_time': '2024-09-23 11:06:06.178411+00:00',
                'reported_date_time': '2024-09-23 11:06:06.178415+00:00',
                'filler_order_number': '27857893',
                'placer_order_number': '27857893',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '45.4',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3906,
                'modified_at': '2024-09-23 11:06:17.041600+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:06:17.041631+00:00',
                'observation_date_time': '2024-09-23 11:06:17.041636+00:00',
                'reported_date_time': '2024-09-23 11:06:17.041639+00:00',
                'filler_order_number': '71908380',
                'placer_order_number': '71908380',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.3',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.5',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '50.2',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.9',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.9',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3938,
                'modified_at': '2024-09-23 11:06:25.505148+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:06:25.505179+00:00',
                'observation_date_time': '2024-09-23 11:06:25.505183+00:00',
                'reported_date_time': '2024-09-23 11:06:25.505187+00:00',
                'filler_order_number': '71908399',
                'placer_order_number': '71908399',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.20',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.3',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.3',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '16.8',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.1',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 3939,
                'modified_at': '2024-09-23 11:06:25.729299+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:06:25.729326+00:00',
                'observation_date_time': '2024-09-23 11:06:25.729330+00:00',
                'reported_date_time': '2024-09-23 11:06:25.729334+00:00',
                'filler_order_number': '71908399',
                'placer_order_number': '71908399',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.3',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.3',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '16.8',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4001,
                'modified_at': '2024-09-23 11:06:41.896607+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:06:41.896634+00:00',
                'observation_date_time': '2024-09-23 11:06:41.896638+00:00',
                'reported_date_time': '2024-09-23 11:06:41.896642+00:00',
                'filler_order_number': '71908420',
                'placer_order_number': '71908420',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.2',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.7',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14.1',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.7',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.30',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4028,
                'modified_at': '2024-09-23 11:06:48.094745+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:06:48.094772+00:00',
                'observation_date_time': '2024-09-23 11:06:48.094776+00:00',
                'reported_date_time': '2024-09-23 11:06:48.094780+00:00',
                'filler_order_number': '27833204',
                'placer_order_number': '27833204',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4043,
                'modified_at': '2024-09-23 11:06:50.734256+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:06:50.734282+00:00',
                'observation_date_time': '2024-09-23 11:06:50.734286+00:00',
                'reported_date_time': '2024-09-23 11:06:50.734289+00:00',
                'filler_order_number': '27833204',
                'placer_order_number': '27833204',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.7',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.28',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.02',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.6',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '82',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.3',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4083,
                'modified_at': '2024-09-23 11:06:58.327997+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Chemie',
                'received_date_time': '2024-09-23 11:06:58.328024+00:00',
                'observation_date_time': '2024-09-23 11:06:58.328028+00:00',
                'reported_date_time': '2024-09-23 11:06:58.328032+00:00',
                'filler_order_number': '27833204',
                'placer_order_number': '27833204',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.7',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.28',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.02',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.6',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '82',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.3',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.6',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4130,
                'modified_at': '2024-09-23 11:07:03.333120+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 11:07:03.333147+00:00',
                'observation_date_time': '2024-09-23 11:07:03.333152+00:00',
                'reported_date_time': '2024-09-23 11:07:03.333155+00:00',
                'filler_order_number': '27833204',
                'placer_order_number': '27833204',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.7',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.28',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.02',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.6',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '82',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.3',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '91',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.06',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.0',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.6',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '76',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.6',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4146,
                'modified_at': '2024-09-23 11:07:08.453584+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 11:07:08.453612+00:00',
                'observation_date_time': '2024-09-23 11:07:08.453616+00:00',
                'reported_date_time': '2024-09-23 11:07:08.453619+00:00',
                'filler_order_number': '27833204',
                'placer_order_number': '27833204',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.7',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.28',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.02',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.7',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.6',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '82',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.3',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '91',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.06',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.0',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.6',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '76',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.6',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.31',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.93',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.50',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '53.7',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '29.6',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '96.8',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '236',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.4',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '839',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '598',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '730',
                                'limits': '28 - 72',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.7',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '109',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '59',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '26.3',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4186,
                'modified_at': '2024-09-23 11:07:19.209139+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:07:19.209166+00:00',
                'observation_date_time': '2024-09-23 11:07:19.209170+00:00',
                'reported_date_time': '2024-09-23 11:07:19.209174+00:00',
                'filler_order_number': '71908491',
                'placer_order_number': '71908491',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.5',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.7',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18.8',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.31',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4187,
                'modified_at': '2024-09-23 11:07:19.521927+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 11:07:19.521953+00:00',
                'observation_date_time': '2024-09-23 11:07:19.521957+00:00',
                'reported_date_time': '2024-09-23 11:07:19.521960+00:00',
                'filler_order_number': '71908493',
                'placer_order_number': '71908493',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.4',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.7',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.3',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.3',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '74',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.31',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.4',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4220,
                'modified_at': '2024-09-23 11:07:28.806476+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:07:28.806503+00:00',
                'observation_date_time': '2024-09-23 11:07:28.806507+00:00',
                'reported_date_time': '2024-09-23 11:07:28.806511+00:00',
                'filler_order_number': '27857135',
                'placer_order_number': '27857135',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '45.2',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4290,
                'modified_at': '2024-09-23 11:07:46.547502+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:07:46.547528+00:00',
                'observation_date_time': '2024-09-23 11:07:46.547533+00:00',
                'reported_date_time': '2024-09-23 11:07:46.547536+00:00',
                'filler_order_number': '71908537',
                'placer_order_number': '71908537',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.00',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.5',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '15.9',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '19',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '154',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.0',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4355,
                'modified_at': '2024-09-23 11:08:02.197122+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:08:02.197170+00:00',
                'observation_date_time': '2024-09-23 11:08:02.197175+00:00',
                'reported_date_time': '2024-09-23 11:08:02.197178+00:00',
                'filler_order_number': '71908576',
                'placer_order_number': '71908576',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.80',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '35.9',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '154',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.0',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.33',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'Sondermaterial',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4364,
                'modified_at': '2024-09-23 11:08:04.324541+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:08:04.324607+00:00',
                'observation_date_time': '2024-09-23 11:08:04.324612+00:00',
                'reported_date_time': '2024-09-23 11:08:04.324615+00:00',
                'filler_order_number': '71908581',
                'placer_order_number': '71908581',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.70',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.6',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.3',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '19',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '155',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.0',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.31',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.6',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4379,
                'modified_at': '2024-09-23 11:08:09.835184+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:08:09.835211+00:00',
                'observation_date_time': '2024-09-23 11:08:09.835216+00:00',
                'reported_date_time': '2024-09-23 11:08:09.835219+00:00',
                'filler_order_number': '27773027',
                'placer_order_number': '27773027',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4385,
                'modified_at': '2024-09-23 11:08:11.264013+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:08:11.264040+00:00',
                'observation_date_time': '2024-09-23 11:08:11.264044+00:00',
                'reported_date_time': '2024-09-23 11:08:11.264047+00:00',
                'filler_order_number': '27773027',
                'placer_order_number': '27773027',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.84',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.9',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.87',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.3',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.5',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '71',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4444,
                'modified_at': '2024-09-23 11:08:22.427675+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:08:22.427702+00:00',
                'observation_date_time': '2024-09-23 11:08:22.427707+00:00',
                'reported_date_time': '2024-09-23 11:08:22.427710+00:00',
                'filler_order_number': '27773027',
                'placer_order_number': '27773027',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.84',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.9',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.87',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.3',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.5',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '71',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '153',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.30',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '117',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.90',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.19',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '51.6',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '98.7',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '184',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '790',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '103',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.0',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '261',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4463,
                'modified_at': '2024-09-23 11:08:26.652455+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:08:26.652481+00:00',
                'observation_date_time': '2024-09-23 11:08:26.652486+00:00',
                'reported_date_time': '2024-09-23 11:08:26.652489+00:00',
                'filler_order_number': '27773027',
                'placer_order_number': '27773027',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.84',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.9',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.87',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.3',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.5',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '71',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '153',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.30',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '117',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.90',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.19',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '51.6',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '98.7',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '184',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '790',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '498',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '103',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.0',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.64',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '261',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4499,
                'modified_at': '2024-09-23 11:08:39.499619+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:08:39.499646+00:00',
                'observation_date_time': '2024-09-23 11:08:39.499651+00:00',
                'reported_date_time': '2024-09-23 11:08:39.499654+00:00',
                'filler_order_number': '27773027',
                'placer_order_number': '27773027',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.84',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.9',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.87',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.3',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.5',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '71',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '86',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '43.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '68',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '153',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.30',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '117',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.90',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.19',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '51.6',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '98.7',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '184',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '790',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '498',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '103',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.0',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.64',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '261',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4584,
                'modified_at': '2024-09-23 11:08:57.094680+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:08:57.094707+00:00',
                'observation_date_time': '2024-09-23 11:08:57.094711+00:00',
                'reported_date_time': '2024-09-23 11:08:57.094714+00:00',
                'filler_order_number': '71908667',
                'placer_order_number': '71908667',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.60',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.3',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.1',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.1',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '155',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.00',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.8',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 4932,
                'modified_at': '2024-09-23 11:10:02.773143+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:10:02.773171+00:00',
                'observation_date_time': '2024-09-23 11:10:02.773175+00:00',
                'reported_date_time': '2024-09-23 11:10:02.773178+00:00',
                'filler_order_number': '71908708',
                'placer_order_number': '71908708',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.45',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.2',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.0',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '16',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '154',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.6',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5024,
                'modified_at': '2024-09-23 11:10:25.347273+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:10:25.347299+00:00',
                'observation_date_time': '2024-09-23 11:10:25.347304+00:00',
                'reported_date_time': '2024-09-23 11:10:25.347307+00:00',
                'filler_order_number': '71908727',
                'placer_order_number': '71908727',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.0',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.7',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.3',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '96',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '16',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '155',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.3',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.32',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.6',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5094,
                'modified_at': '2024-09-23 11:10:44.020957+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 11:10:44.020983+00:00',
                'observation_date_time': '2024-09-23 11:10:44.020987+00:00',
                'reported_date_time': '2024-09-23 11:10:44.020990+00:00',
                'filler_order_number': '27833204',
                'placer_order_number': '27833204',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.7',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.28',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.02',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.7',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.6',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '82',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.3',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '91',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.06',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.0',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.6',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '76',
                                'limits': '75,2 - 154,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.6',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.31',
                                'limits': '2,20 - 2,55',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.93',
                                'limits': '0,74 - 1,03',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.50',
                                'limits': '0,81 - 1,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '53.7',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '29.6',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '96.8',
                                'limits': '< 60',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '236',
                                'limits': '< 190',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.4',
                                'limits': '< 25',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '839',
                                'limits': '135 - 255',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '598',
                                'limits': '< 14',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '730',
                                'limits': '28 - 72',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.7',
                                'limits': '<24',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '109',
                                'limits': '59 - 104',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '59',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G3a, gering- bis mittelgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '26.3',
                                'limits': '2,76 - 8,07',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5106,
                'modified_at': '2024-09-23 11:10:46.649461+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:10:46.649489+00:00',
                'observation_date_time': '2024-09-23 11:10:46.649493+00:00',
                'reported_date_time': '2024-09-23 11:10:46.649496+00:00',
                'filler_order_number': '27857893',
                'placer_order_number': '27857893',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '45.4',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5169,
                'modified_at': '2024-09-23 11:11:03.224814+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:11:03.224840+00:00',
                'observation_date_time': '2024-09-23 11:11:03.224844+00:00',
                'reported_date_time': '2024-09-23 11:11:03.224848+00:00',
                'filler_order_number': '71908734',
                'placer_order_number': '71908734',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '38.6',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18',
                                'limits': '< 17',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '156',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.00',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.4',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.7',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'Sondermaterial',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5170,
                'modified_at': '2024-09-23 11:11:03.585876+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:11:03.585904+00:00',
                'observation_date_time': '2024-09-23 11:11:03.585908+00:00',
                'reported_date_time': '2024-09-23 11:11:03.585911+00:00',
                'filler_order_number': '27773027',
                'placer_order_number': '27773027',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.3',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.84',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.9',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.87',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.3',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.5',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '71',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.2',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '86',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '43.5',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '68',
                                'limits': '75,2 - 154,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '153',
                                'limits': '136 - 145',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.30',
                                'limits': '2,20 - 2,55',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '117',
                                'limits': '98 - 107',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.90',
                                'limits': '0,74 - 1,03',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.19',
                                'limits': '0,81 - 1,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '51.6',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.5',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '98.7',
                                'limits': '< 60',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '184',
                                'limits': '< 190',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '< 25',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '790',
                                'limits': '135 - 255',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '498',
                                'limits': '< 14',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.8',
                                'limits': '<24',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '103',
                                'limits': '59 - 104',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '63',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '25.0',
                                'limits': '2,76 - 8,07',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.64',
                                'limits': '<0,046',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '261',
                                'limits': '<5,0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5198,
                'modified_at': '2024-09-23 11:11:10.287676+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:11:10.287704+00:00',
                'observation_date_time': '2024-09-23 11:11:10.287708+00:00',
                'reported_date_time': '2024-09-23 11:11:10.287712+00:00',
                'filler_order_number': '27857135',
                'placer_order_number': '27857135',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '45.2',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5247,
                'modified_at': '2024-09-23 11:11:22.403209+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:11:22.403235+00:00',
                'observation_date_time': '2024-09-23 11:11:22.403239+00:00',
                'reported_date_time': '2024-09-23 11:11:22.403243+00:00',
                'filler_order_number': '71908734',
                'placer_order_number': '71908734',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '38.6',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '156',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.00',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.4',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.7',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'Sondermaterial',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5523,
                'modified_at': '2024-09-23 11:12:08.073796+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:12:08.073823+00:00',
                'observation_date_time': '2024-09-23 11:12:08.073827+00:00',
                'reported_date_time': '2024-09-23 11:12:08.073830+00:00',
                'filler_order_number': '27850865',
                'placer_order_number': '27850865',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '43.9',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5612,
                'modified_at': '2024-09-23 11:12:21.718387+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:12:21.718414+00:00',
                'observation_date_time': '2024-09-23 11:12:21.718418+00:00',
                'reported_date_time': '2024-09-23 11:12:21.718421+00:00',
                'filler_order_number': '71908775',
                'placer_order_number': '71908775',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.3',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14.8',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '120',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.23',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.6',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5738,
                'modified_at': '2024-09-23 11:12:45.659273+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:12:45.659299+00:00',
                'observation_date_time': '2024-09-23 11:12:45.659304+00:00',
                'reported_date_time': '2024-09-23 11:12:45.659307+00:00',
                'filler_order_number': '71908822',
                'placer_order_number': '71908822',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.80',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.6',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.4',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.10',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.30',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5832,
                'modified_at': '2024-09-23 11:13:06.120973+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:13:06.120999+00:00',
                'observation_date_time': '2024-09-23 11:13:06.121003+00:00',
                'reported_date_time': '2024-09-23 11:13:06.121006+00:00',
                'filler_order_number': '71908861',
                'placer_order_number': '71908861',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.80',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.6',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.6',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.2',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.90',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.9',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.33',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5932,
                'modified_at': '2024-09-23 11:13:19.441273+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:13:19.441301+00:00',
                'observation_date_time': '2024-09-23 11:13:19.441305+00:00',
                'reported_date_time': '2024-09-23 11:13:19.441309+00:00',
                'filler_order_number': '27837323',
                'placer_order_number': '27837323',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5981,
                'modified_at': '2024-09-23 11:13:24.495723+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:13:24.495749+00:00',
                'observation_date_time': '2024-09-23 11:13:24.495753+00:00',
                'reported_date_time': '2024-09-23 11:13:24.495757+00:00',
                'filler_order_number': '27837323',
                'placer_order_number': '27837323',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.27',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.85',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.0',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.0',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '64',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.9',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 5992,
                'modified_at': '2024-09-23 11:13:25.883287+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:13:25.883315+00:00',
                'observation_date_time': '2024-09-23 11:13:25.883319+00:00',
                'reported_date_time': '2024-09-23 11:13:25.883323+00:00',
                'filler_order_number': '27837323',
                'placer_order_number': '27837323',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.27',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.85',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.0',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.0',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '64',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.9',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '87',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.09',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '41.2',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6036,
                'modified_at': '2024-09-23 11:13:36.271724+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:13:36.271751+00:00',
                'observation_date_time': '2024-09-23 11:13:36.271755+00:00',
                'reported_date_time': '2024-09-23 11:13:36.271759+00:00',
                'filler_order_number': '71908907',
                'placer_order_number': '71908907',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.80',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.8',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '154',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '119',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.31',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6111,
                'modified_at': '2024-09-23 11:13:49.653520+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:13:49.653546+00:00',
                'observation_date_time': '2024-09-23 11:13:49.653550+00:00',
                'reported_date_time': '2024-09-23 11:13:49.653554+00:00',
                'filler_order_number': '27850865',
                'placer_order_number': '27850865',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '43.9',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6211,
                'modified_at': '2024-09-23 11:14:12.778691+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:14:12.778717+00:00',
                'observation_date_time': '2024-09-23 11:14:12.778721+00:00',
                'reported_date_time': '2024-09-23 11:14:12.778725+00:00',
                'filler_order_number': '27837323',
                'placer_order_number': '27837323',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.3',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.27',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.85',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.0',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.0',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.6',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '64',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.9',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '87',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.09',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '41.2',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6390,
                'modified_at': '2024-09-23 11:14:45.332297+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:14:45.332323+00:00',
                'observation_date_time': '2024-09-23 11:14:45.332327+00:00',
                'reported_date_time': '2024-09-23 11:14:45.332330+00:00',
                'filler_order_number': '71908984',
                'placer_order_number': '71908984',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.60',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.4',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.9',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.90',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '119',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.5',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.32',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6391,
                'modified_at': '2024-09-23 11:14:45.601002+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 11:14:45.601027+00:00',
                'observation_date_time': '2024-09-23 11:14:45.601031+00:00',
                'reported_date_time': '2024-09-23 11:14:45.601034+00:00',
                'filler_order_number': '71908985',
                'placer_order_number': '71908985',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.39',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.8',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '68',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.32',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.0',
                                'limits': '<17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibgav',
                                    'short_text': 'Bilirubin, gesamt (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '154',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.00',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.3',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.2',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methbv',
                                    'short_text': 'Met-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6403,
                'modified_at': '2024-09-23 11:14:48.398719+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:14:48.398744+00:00',
                'observation_date_time': '2024-09-23 11:14:48.398748+00:00',
                'reported_date_time': '2024-09-23 11:14:48.398751+00:00',
                'filler_order_number': '27859200',
                'placer_order_number': '27859200',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6411,
                'modified_at': '2024-09-23 11:14:49.973174+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:14:49.973200+00:00',
                'observation_date_time': '2024-09-23 11:14:49.973204+00:00',
                'reported_date_time': '2024-09-23 11:14:49.973207+00:00',
                'filler_order_number': '27859200',
                'placer_order_number': '27859200',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.1',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.75',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.8',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.8',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.7',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '93',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.9',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.15',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '45.0',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6496,
                'modified_at': '2024-09-23 11:15:07.949880+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:15:07.949906+00:00',
                'observation_date_time': '2024-09-23 11:15:07.949910+00:00',
                'reported_date_time': '2024-09-23 11:15:07.949914+00:00',
                'filler_order_number': '71909034',
                'placer_order_number': '71909034',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.17',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '95',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '15',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '154',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.7',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6578,
                'modified_at': '2024-09-23 11:15:27.011055+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:15:27.011081+00:00',
                'observation_date_time': '2024-09-23 11:15:27.011085+00:00',
                'reported_date_time': '2024-09-23 11:15:27.011089+00:00',
                'filler_order_number': '71909056',
                'placer_order_number': '71909056',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.40',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '23.7',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '154',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.0',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.1',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.34',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'Sondermaterial',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6593,
                'modified_at': '2024-09-23 11:15:30.375527+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:15:30.375554+00:00',
                'observation_date_time': '2024-09-23 11:15:30.375558+00:00',
                'reported_date_time': '2024-09-23 11:15:30.375561+00:00',
                'filler_order_number': '71909073',
                'placer_order_number': '71909073',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.40',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.1',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.27',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '92',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.5',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.32',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6594,
                'modified_at': '2024-09-23 11:15:30.573298+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 11:15:30.573325+00:00',
                'observation_date_time': '2024-09-23 11:15:30.573329+00:00',
                'reported_date_time': '2024-09-23 11:15:30.573333+00:00',
                'filler_order_number': '71909074',
                'placer_order_number': '71909074',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.40',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.37',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.2',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.3',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '63',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.33',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '154',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.4',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6648,
                'modified_at': '2024-09-23 11:15:38.127418+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:15:38.127446+00:00',
                'observation_date_time': '2024-09-23 11:15:38.127450+00:00',
                'reported_date_time': '2024-09-23 11:15:38.127453+00:00',
                'filler_order_number': '27859203',
                'placer_order_number': '27859203',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.68',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '94.4',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.83',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.4',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '24.2',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '91',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.8',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6713,
                'modified_at': '2024-09-23 11:15:51.604121+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:15:51.604147+00:00',
                'observation_date_time': '2024-09-23 11:15:51.604151+00:00',
                'reported_date_time': '2024-09-23 11:15:51.604154+00:00',
                'filler_order_number': '71909112',
                'placer_order_number': '71909112',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.6',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.6',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.51',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '95',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '153',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6722,
                'modified_at': '2024-09-23 11:15:54.348479+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:15:54.348507+00:00',
                'observation_date_time': '2024-09-23 11:15:54.348511+00:00',
                'reported_date_time': '2024-09-23 11:15:54.348514+00:00',
                'filler_order_number': '27859203',
                'placer_order_number': '27859203',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.68',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '94.4',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.83',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.4',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '24.2',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '91',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.8',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.7',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.29',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '118',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.96',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.06',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.0',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.4',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '107',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '128',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '768',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.9',
                                'limits': '35 - 52',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alb',
                                    'short_text': 'Albumin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.0',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '23.5',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '186',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6737,
                'modified_at': '2024-09-23 11:16:00.228175+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:16:00.228202+00:00',
                'observation_date_time': '2024-09-23 11:16:00.228206+00:00',
                'reported_date_time': '2024-09-23 11:16:00.228209+00:00',
                'filler_order_number': '27859203',
                'placer_order_number': '27859203',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.68',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '94.4',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.83',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.4',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '24.2',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '91',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.8',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '86',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.9',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '53',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.7',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.29',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '118',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.96',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.06',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.0',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.4',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '107',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '128',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '768',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '360',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.9',
                                'limits': '35 - 52',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alb',
                                    'short_text': 'Albumin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.0',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '23.5',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.23',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '186',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 6937,
                'modified_at': '2024-09-23 11:16:28.374535+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:16:28.374560+00:00',
                'observation_date_time': '2024-09-23 11:16:28.374564+00:00',
                'reported_date_time': '2024-09-23 11:16:28.374568+00:00',
                'filler_order_number': '71909147',
                'placer_order_number': '71909147',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.1',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.1',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.59',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '95',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.9',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.31',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.2',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7161,
                'modified_at': '2024-09-23 11:17:19.205033+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:17:19.205059+00:00',
                'observation_date_time': '2024-09-23 11:17:19.205063+00:00',
                'reported_date_time': '2024-09-23 11:17:19.205066+00:00',
                'filler_order_number': '71909176',
                'placer_order_number': '71909176',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.38',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.5',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.0',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.9',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.3',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '96',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14',
                                'limits': '< 17',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '151',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.90',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.3',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.1',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.31',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.4',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7164,
                'modified_at': '2024-09-23 11:17:19.669456+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:17:19.669485+00:00',
                'observation_date_time': '2024-09-23 11:17:19.669504+00:00',
                'reported_date_time': '2024-09-23 11:17:19.669507+00:00',
                'filler_order_number': '71909176',
                'placer_order_number': '71909176',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.38',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.5',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.3',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '96',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '151',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.90',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.3',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.1',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.31',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.4',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7259,
                'modified_at': '2024-09-23 11:17:37.725830+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:17:37.725857+00:00',
                'observation_date_time': '2024-09-23 11:17:37.725861+00:00',
                'reported_date_time': '2024-09-23 11:17:37.725864+00:00',
                'filler_order_number': '71909193',
                'placer_order_number': '71909193',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.9',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.2',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '96',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '151',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.7',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.33',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7401,
                'modified_at': '2024-09-23 11:17:55.325857+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:17:55.325884+00:00',
                'observation_date_time': '2024-09-23 11:17:55.325888+00:00',
                'reported_date_time': '2024-09-23 11:17:55.325891+00:00',
                'filler_order_number': '27640515',
                'placer_order_number': '27640515',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7421,
                'modified_at': '2024-09-23 11:17:58.339267+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:17:58.339293+00:00',
                'observation_date_time': '2024-09-23 11:17:58.339297+00:00',
                'reported_date_time': '2024-09-23 11:17:58.339301+00:00',
                'filler_order_number': '27640515',
                'placer_order_number': '27640515',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.7',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.62',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.5',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.79',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.2',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.4',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '77',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.6',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7428,
                'modified_at': '2024-09-23 11:17:59.678649+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:17:59.678676+00:00',
                'observation_date_time': '2024-09-23 11:17:59.678681+00:00',
                'reported_date_time': '2024-09-23 11:17:59.678684+00:00',
                'filler_order_number': '27640515',
                'placer_order_number': '27640515',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.7',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.62',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.5',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.79',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.2',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.4',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '77',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.6',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '75',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.20',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.1',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7488,
                'modified_at': '2024-09-23 11:18:10.600707+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 11:18:10.600733+00:00',
                'observation_date_time': '2024-09-23 11:18:10.600737+00:00',
                'reported_date_time': '2024-09-23 11:18:10.600740+00:00',
                'filler_order_number': '71909231',
                'placer_order_number': '71909231',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.40',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.6',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.2',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '69',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.31',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '16',
                                'limits': '<17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibgav',
                                    'short_text': 'Bilirubin, gesamt (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.1',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methbv',
                                    'short_text': 'Met-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7497,
                'modified_at': '2024-09-23 11:18:12.096661+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:18:12.096687+00:00',
                'observation_date_time': '2024-09-23 11:18:12.096691+00:00',
                'reported_date_time': '2024-09-23 11:18:12.096694+00:00',
                'filler_order_number': '71909234',
                'placer_order_number': '71909234',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.0',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.7',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.74',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '90',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '152',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '1.0',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7534,
                'modified_at': '2024-09-23 11:18:17.939109+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:18:17.939135+00:00',
                'observation_date_time': '2024-09-23 11:18:17.939139+00:00',
                'reported_date_time': '2024-09-23 11:18:17.939142+00:00',
                'filler_order_number': '71909256',
                'placer_order_number': '71909256',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.2',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '20.4',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '151',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.4',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.1',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.32',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'Sondermaterial',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7591,
                'modified_at': '2024-09-23 11:18:30.984917+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:18:30.984945+00:00',
                'observation_date_time': '2024-09-23 11:18:30.984949+00:00',
                'reported_date_time': '2024-09-23 11:18:30.984952+00:00',
                'filler_order_number': '27859200',
                'placer_order_number': '27859200',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.1',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.26',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.75',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.8',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.8',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '23.7',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '93',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.9',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.15',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '45.0',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7639,
                'modified_at': '2024-09-23 11:18:44.326275+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:18:44.326301+00:00',
                'observation_date_time': '2024-09-23 11:18:44.326305+00:00',
                'reported_date_time': '2024-09-23 11:18:44.326309+00:00',
                'filler_order_number': '27859203',
                'placer_order_number': '27859203',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.68',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '94.4',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.83',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.4',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '24.2',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '91',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.8',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '86',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.10',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.9',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '53',
                                'limits': '75,2 - 154,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '152',
                                'limits': '136 - 145',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.7',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.29',
                                'limits': '2,20 - 2,55',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '118',
                                'limits': '98 - 107',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.96',
                                'limits': '0,74 - 1,03',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.06',
                                'limits': '0,81 - 1,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.0',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.4',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '107',
                                'limits': '< 60',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '128',
                                'limits': '< 190',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.6',
                                'limits': '< 25',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '768',
                                'limits': '135 - 255',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '360',
                                'limits': '< 14',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.9',
                                'limits': '35 - 52',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alb',
                                    'short_text': 'Albumin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '16.6',
                                'limits': '<24',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.0',
                                'limits': '59 - 104',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '23.5',
                                'limits': '2,76 - 8,07',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.23',
                                'limits': '<0,046',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '186',
                                'limits': '<5,0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7802,
                'modified_at': '2024-09-23 11:19:27.033773+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:19:27.033800+00:00',
                'observation_date_time': '2024-09-23 11:19:27.033804+00:00',
                'reported_date_time': '2024-09-23 11:19:27.033808+00:00',
                'filler_order_number': '71909269',
                'placer_order_number': '71909269',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.5',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.3',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '19.0',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '151',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.3',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 7867,
                'modified_at': '2024-09-23 11:19:39.541143+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:19:39.541168+00:00',
                'observation_date_time': '2024-09-23 11:19:39.541172+00:00',
                'reported_date_time': '2024-09-23 11:19:39.541176+00:00',
                'filler_order_number': '71909319',
                'placer_order_number': '71909319',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18.7',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '151',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.5',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.9',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.32',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8007,
                'modified_at': '2024-09-23 11:19:59.719471+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:19:59.719498+00:00',
                'observation_date_time': '2024-09-23 11:19:59.719502+00:00',
                'reported_date_time': '2024-09-23 11:19:59.719506+00:00',
                'filler_order_number': '27802897',
                'placer_order_number': '27802897',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8019,
                'modified_at': '2024-09-23 11:20:01.468139+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:20:01.468166+00:00',
                'observation_date_time': '2024-09-23 11:20:01.468170+00:00',
                'reported_date_time': '2024-09-23 11:20:01.468173+00:00',
                'filler_order_number': '27802897',
                'placer_order_number': '27802897',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.63',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.5',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.9',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.7',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '66',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.9',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8038,
                'modified_at': '2024-09-23 11:20:04.598344+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:20:04.598371+00:00',
                'observation_date_time': '2024-09-23 11:20:04.598375+00:00',
                'reported_date_time': '2024-09-23 11:20:04.598379+00:00',
                'filler_order_number': '27802897',
                'placer_order_number': '27802897',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.63',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.5',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.9',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.7',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '66',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.9',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.11',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '47.7',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8055,
                'modified_at': '2024-09-23 11:20:08.246202+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:20:08.246228+00:00',
                'observation_date_time': '2024-09-23 11:20:08.246232+00:00',
                'reported_date_time': '2024-09-23 11:20:08.246235+00:00',
                'filler_order_number': '71909344',
                'placer_order_number': '71909344',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.40',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.9',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.3',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.4',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.64',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '90',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.9',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.30',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8131,
                'modified_at': '2024-09-23 11:20:27.512562+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:20:27.512588+00:00',
                'observation_date_time': '2024-09-23 11:20:27.512592+00:00',
                'reported_date_time': '2024-09-23 11:20:27.512595+00:00',
                'filler_order_number': '27640515',
                'placer_order_number': '27640515',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.7',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.62',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.5',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.79',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.2',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.4',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '77',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.6',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.0',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '75',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.20',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '50.1',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8206,
                'modified_at': '2024-09-23 11:20:46.110875+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:20:46.110900+00:00',
                'observation_date_time': '2024-09-23 11:20:46.110904+00:00',
                'reported_date_time': '2024-09-23 11:20:46.110907+00:00',
                'filler_order_number': '27802897',
                'placer_order_number': '27802897',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.63',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '93.5',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.9',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.7',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '66',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.9',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '85',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.11',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '47.7',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8283,
                'modified_at': '2024-09-23 11:21:03.277159+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 11:21:03.277186+00:00',
                'observation_date_time': '2024-09-23 11:21:03.277190+00:00',
                'reported_date_time': '2024-09-23 11:21:03.277193+00:00',
                'filler_order_number': '37337227',
                'placer_order_number': '37337227',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '79',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '377',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '462',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1006',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.400',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8284,
                'modified_at': '2024-09-23 11:21:03.471880+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:21:03.471907+00:00',
                'observation_date_time': '2024-09-23 11:21:03.471911+00:00',
                'reported_date_time': '2024-09-23 11:21:03.471915+00:00',
                'filler_order_number': '37519590',
                'placer_order_number': '37519590',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '73',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '314',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '453',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '895',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.400',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8285,
                'modified_at': '2024-09-23 11:21:03.618094+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:21:03.618120+00:00',
                'observation_date_time': '2024-09-23 11:21:03.618124+00:00',
                'reported_date_time': '2024-09-23 11:21:03.618128+00:00',
                'filler_order_number': '37517112',
                'placer_order_number': '37517112',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '77',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '203',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '409',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '904',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.350',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8357,
                'modified_at': '2024-09-23 11:21:20.251271+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:21:20.251299+00:00',
                'observation_date_time': '2024-09-23 11:21:20.251303+00:00',
                'reported_date_time': '2024-09-23 11:21:20.251307+00:00',
                'filler_order_number': '71909376',
                'placer_order_number': '71909376',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?3.7',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.6',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?27.0',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?2.9',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.4',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?98.6',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.7',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.27',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8358,
                'modified_at': '2024-09-23 11:21:20.473586+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:21:20.473613+00:00',
                'observation_date_time': '2024-09-23 11:21:20.473617+00:00',
                'reported_date_time': '2024-09-23 11:21:20.473620+00:00',
                'filler_order_number': '71909377',
                'placer_order_number': '71909377',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.4',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.3',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.4',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.6',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.9',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.30',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8363,
                'modified_at': '2024-09-23 11:21:21.754315+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:21:21.754342+00:00',
                'observation_date_time': '2024-09-23 11:21:21.754347+00:00',
                'reported_date_time': '2024-09-23 11:21:21.754350+00:00',
                'filler_order_number': '71909376',
                'placer_order_number': '71909376',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?3.7',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.6',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?27.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?2.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.4',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?98.6',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.7',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.27',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8364,
                'modified_at': '2024-09-23 11:21:21.970707+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:21:21.970732+00:00',
                'observation_date_time': '2024-09-23 11:21:21.970736+00:00',
                'reported_date_time': '2024-09-23 11:21:21.970739+00:00',
                'filler_order_number': '71909377',
                'placer_order_number': '71909377',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.42',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.3',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.4',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.9',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.30',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8541,
                'modified_at': '2024-09-23 11:21:51.978231+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:21:51.978257+00:00',
                'observation_date_time': '2024-09-23 11:21:51.978261+00:00',
                'reported_date_time': '2024-09-23 11:21:51.978264+00:00',
                'filler_order_number': '71909416',
                'placer_order_number': '71909416',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.90',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.4',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.0',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.2',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8542,
                'modified_at': '2024-09-23 11:21:52.194211+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 11:21:52.194238+00:00',
                'observation_date_time': '2024-09-23 11:21:52.194242+00:00',
                'reported_date_time': '2024-09-23 11:21:52.194245+00:00',
                'filler_order_number': '71909417',
                'placer_order_number': '71909417',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.40',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.5',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.3',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '67',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.31',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.9',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8593,
                'modified_at': '2024-09-23 11:22:09.971826+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:22:09.971852+00:00',
                'observation_date_time': '2024-09-23 11:22:09.971856+00:00',
                'reported_date_time': '2024-09-23 11:22:09.971860+00:00',
                'filler_order_number': '27855081',
                'placer_order_number': '27855081',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '55.1',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8709,
                'modified_at': '2024-09-23 11:22:34.726986+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:22:34.727015+00:00',
                'observation_date_time': '2024-09-23 11:22:34.727020+00:00',
                'reported_date_time': '2024-09-23 11:22:34.727023+00:00',
                'filler_order_number': '71909499',
                'placer_order_number': '71909499',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?3.9',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.45',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?28.1',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?4.1',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.6',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?98.3',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.5',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.27',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8747,
                'modified_at': '2024-09-23 11:22:43.574651+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:22:43.574677+00:00',
                'observation_date_time': '2024-09-23 11:22:43.574682+00:00',
                'reported_date_time': '2024-09-23 11:22:43.574685+00:00',
                'filler_order_number': '71909510',
                'placer_order_number': '71909510',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18.2',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.8',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.30',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.5',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'Sondermaterial',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8754,
                'modified_at': '2024-09-23 11:22:45.433129+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 11:22:45.433155+00:00',
                'observation_date_time': '2024-09-23 11:22:45.433159+00:00',
                'reported_date_time': '2024-09-23 11:22:45.433162+00:00',
                'filler_order_number': '71909515',
                'placer_order_number': '71909515',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.39',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.5',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.4',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.2',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '71',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.31',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.8',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8755,
                'modified_at': '2024-09-23 11:22:45.626574+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:22:45.626599+00:00',
                'observation_date_time': '2024-09-23 11:22:45.626603+00:00',
                'reported_date_time': '2024-09-23 11:22:45.626606+00:00',
                'filler_order_number': '71909517',
                'placer_order_number': '71909517',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.3',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.3',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.1',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '149',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.0',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.29',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8773,
                'modified_at': '2024-09-23 11:22:49.774299+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:22:49.774325+00:00',
                'observation_date_time': '2024-09-23 11:22:49.774329+00:00',
                'reported_date_time': '2024-09-23 11:22:49.774332+00:00',
                'filler_order_number': '27859771',
                'placer_order_number': '27859771',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8776,
                'modified_at': '2024-09-23 11:22:50.400551+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 11:22:50.400577+00:00',
                'observation_date_time': '2024-09-23 11:22:50.400582+00:00',
                'reported_date_time': '2024-09-23 11:22:50.400585+00:00',
                'filler_order_number': '27859771',
                'placer_order_number': '27859771',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.56',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '92.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.3',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '95',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.6',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8802,
                'modified_at': '2024-09-23 11:22:56.149873+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 11:22:56.149899+00:00',
                'observation_date_time': '2024-09-23 11:22:56.149903+00:00',
                'reported_date_time': '2024-09-23 11:22:56.149907+00:00',
                'filler_order_number': '27859771',
                'placer_order_number': '27859771',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.56',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '92.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.3',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '95',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.6',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.15',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.2',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.7',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8827,
                'modified_at': '2024-09-23 11:22:59.282186+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:22:59.282211+00:00',
                'observation_date_time': '2024-09-23 11:22:59.282215+00:00',
                'reported_date_time': '2024-09-23 11:22:59.282219+00:00',
                'filler_order_number': '27859771',
                'placer_order_number': '27859771',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.56',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '92.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.3',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '95',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.6',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.15',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.2',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.7',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '148',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.7',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.20',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.97',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.23',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.3',
                                'limits': '13 - 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lipa',
                                    'short_text': 'Lipase',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '47.8',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.0',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '98.2',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '87.4',
                                'limits': '40 - 129',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ap',
                                    'short_text': 'AP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '143',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.6',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '769',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '223',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '35 - 52',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alb',
                                    'short_text': 'Albumin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '14.1',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.3',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '22.4',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '149',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8842,
                'modified_at': '2024-09-23 11:23:02.637365+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:23:02.637391+00:00',
                'observation_date_time': '2024-09-23 11:23:02.637395+00:00',
                'reported_date_time': '2024-09-23 11:23:02.637399+00:00',
                'filler_order_number': '27859771',
                'placer_order_number': '27859771',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.56',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '92.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.3',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '95',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.6',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.15',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.2',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.7',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '148',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.7',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.20',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.97',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.23',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.3',
                                'limits': '13 - 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lipa',
                                    'short_text': 'Lipase',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '47.8',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.0',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '98.2',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '87.4',
                                'limits': '40 - 129',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ap',
                                    'short_text': 'AP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '143',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.6',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '769',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '223',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '35 - 52',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alb',
                                    'short_text': 'Albumin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '14.1',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.3',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '22.4',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.32',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '149',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 8987,
                'modified_at': '2024-09-23 11:23:38.436794+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:23:38.436821+00:00',
                'observation_date_time': '2024-09-23 11:23:38.436825+00:00',
                'reported_date_time': '2024-09-23 11:23:38.436829+00:00',
                'filler_order_number': '27859771',
                'placer_order_number': '27859771',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.56',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '92.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.3',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '95',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.6',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.15',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.2',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.7',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '38',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '148',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.7',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.20',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.97',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.23',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.3',
                                'limits': '13 - 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lipa',
                                    'short_text': 'Lipase',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '47.8',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.0',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '98.2',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '87.4',
                                'limits': '40 - 129',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ap',
                                    'short_text': 'AP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '143',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.6',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '769',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '223',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '35 - 52',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alb',
                                    'short_text': 'Albumin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '14.1',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.3',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '22.4',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.32',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '149',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 9251,
                'modified_at': '2024-09-23 11:24:28.476413+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:24:28.476440+00:00',
                'observation_date_time': '2024-09-23 11:24:28.476444+00:00',
                'reported_date_time': '2024-09-23 11:24:28.476447+00:00',
                'filler_order_number': '71909678',
                'placer_order_number': '71909678',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.2',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.0',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.8',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '149',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.8',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.5',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 9507,
                'modified_at': '2024-09-23 11:25:34.142549+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:25:34.142576+00:00',
                'observation_date_time': '2024-09-23 11:25:34.142580+00:00',
                'reported_date_time': '2024-09-23 11:25:34.142583+00:00',
                'filler_order_number': '27855081',
                'placer_order_number': '27855081',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '55.1',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 9676,
                'modified_at': '2024-09-23 11:26:25.658954+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 11:26:25.658981+00:00',
                'observation_date_time': '2024-09-23 11:26:25.658986+00:00',
                'reported_date_time': '2024-09-23 11:26:25.658989+00:00',
                'filler_order_number': '27859771',
                'placer_order_number': '27859771',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.56',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '92.2',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '26.3',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '95',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.8',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.6',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.15',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.2',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.7',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '38',
                                'limits': '75,2 - 154,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '148',
                                'limits': '136 - 145',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.7',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.20',
                                'limits': '2,20 - 2,55',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '115',
                                'limits': '98 - 107',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.97',
                                'limits': '0,74 - 1,03',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.23',
                                'limits': '0,81 - 1,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.3',
                                'limits': '13 - 60',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lipa',
                                    'short_text': 'Lipase',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '47.8',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.0',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '98.2',
                                'limits': '< 60',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '87.4',
                                'limits': '40 - 129',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ap',
                                    'short_text': 'AP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '143',
                                'limits': '< 190',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.6',
                                'limits': '< 25',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '769',
                                'limits': '135 - 255',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '223',
                                'limits': '< 14',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '35 - 52',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alb',
                                    'short_text': 'Albumin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '14.1',
                                'limits': '<24',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '91.3',
                                'limits': '59 - 104',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '22.4',
                                'limits': '2,76 - 8,07',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.32',
                                'limits': '<0,046',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '149',
                                'limits': '<5,0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 10530,
                'modified_at': '2024-09-23 11:30:08.917741+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 11:30:08.917772+00:00',
                'observation_date_time': '2024-09-23 11:30:08.917776+00:00',
                'reported_date_time': '2024-09-23 11:30:08.917780+00:00',
                'filler_order_number': '71910015',
                'placer_order_number': '71910015',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.38',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.6',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '32.3',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '149',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.5',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.9',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.28',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.6',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 10681,
                'modified_at': '2024-09-23 11:30:42.276664+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 11:30:42.276691+00:00',
                'observation_date_time': '2024-09-23 11:30:42.276695+00:00',
                'reported_date_time': '2024-09-23 11:30:42.276698+00:00',
                'filler_order_number': '27849269',
                'placer_order_number': '27849269',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '44.6',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 11350,
                'modified_at': '2024-09-23 12:03:36.680448+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:03:36.680477+00:00',
                'observation_date_time': '2024-09-23 12:03:36.680481+00:00',
                'reported_date_time': '2024-09-23 12:03:36.680485+00:00',
                'filler_order_number': '27849269',
                'placer_order_number': '27849269',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '44.6',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 11478,
                'modified_at': '2024-09-23 12:04:22.619707+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:04:22.619735+00:00',
                'observation_date_time': '2024-09-23 12:04:22.619739+00:00',
                'reported_date_time': '2024-09-23 12:04:22.619743+00:00',
                'filler_order_number': '71910249',
                'placer_order_number': '71910249',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.80',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.51',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.5',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '52.8',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '148',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.7',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.7',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.26',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 11595,
                'modified_at': '2024-09-23 12:04:50.227341+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:04:50.227368+00:00',
                'observation_date_time': '2024-09-23 12:04:50.227372+00:00',
                'reported_date_time': '2024-09-23 12:04:50.227375+00:00',
                'filler_order_number': '71910285',
                'placer_order_number': '71910285',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.49',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.6',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.2',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.1',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.9',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '148',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.90',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '117',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.1',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.24',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 11762,
                'modified_at': '2024-09-23 12:05:21.801097+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:05:21.801124+00:00',
                'observation_date_time': '2024-09-23 12:05:21.801129+00:00',
                'reported_date_time': '2024-09-23 12:05:21.801132+00:00',
                'filler_order_number': '27856175',
                'placer_order_number': '27856175',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 11802,
                'modified_at': '2024-09-23 12:05:31.401121+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 12:05:31.401148+00:00',
                'observation_date_time': '2024-09-23 12:05:31.401153+00:00',
                'reported_date_time': '2024-09-23 12:05:31.401156+00:00',
                'filler_order_number': '27856175',
                'placer_order_number': '27856175',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.8',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.3',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.6',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '41.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '82',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.6',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.4',
                                'limits': '2 - 8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuaa',
                                    'short_text': 'Neutrophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.53',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgran',
                                    'short_text': 'Unreife Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.75',
                                'limits': '1 - 3,2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyaa',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.57',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moaa',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.24',
                                'limits': '< 0,7',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoaa',
                                    'short_text': 'Eosinophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.14',
                                'limits': '< 0,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'baa',
                                    'short_text': 'Basophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73.0',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuar',
                                    'short_text': 'Neutrophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.3',
                                'limits': '<2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgranr',
                                    'short_text': 'Unreife Granulozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.6',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyar',
                                    'short_text': 'Lymphozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.8',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moar',
                                    'short_text': 'Monozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.0',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoar',
                                    'short_text': 'Eosinophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.3',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bar',
                                    'short_text': 'Basophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.7',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '78',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.16',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.4',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 11824,
                'modified_at': '2024-09-23 12:05:38.640217+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, sonstiges, urinstix, Gerinnung',
                'received_date_time': '2024-09-23 12:05:38.640243+00:00',
                'observation_date_time': '2024-09-23 12:05:38.640247+00:00',
                'reported_date_time': '2024-09-23 12:05:38.640251+00:00',
                'filler_order_number': '27856175',
                'placer_order_number': '27856175',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.8',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.3',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.6',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '41.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '82',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.6',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.4',
                                'limits': '2 - 8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuaa',
                                    'short_text': 'Neutrophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.53',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgran',
                                    'short_text': 'Unreife Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.75',
                                'limits': '1 - 3,2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyaa',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.57',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moaa',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.24',
                                'limits': '< 0,7',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoaa',
                                    'short_text': 'Eosinophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.14',
                                'limits': '< 0,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'baa',
                                    'short_text': 'Basophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73.0',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuar',
                                    'short_text': 'Neutrophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.3',
                                'limits': '<2',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgranr',
                                    'short_text': 'Unreife Granulozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.6',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyar',
                                    'short_text': 'Lymphozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.8',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moar',
                                    'short_text': 'Monozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.0',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoar',
                                    'short_text': 'Eosinophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.3',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bar',
                                    'short_text': 'Basophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.7',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eos',
                                    'short_text': 'eosinophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bas',
                                    'short_text': 'basophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myel',
                                    'short_text': 'Myelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'meta',
                                    'short_text': 'Metamyelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '10',
                                'limits': '<8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'stab',
                                    'short_text': 'neutrophile Stabkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '72',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'seg',
                                    'short_text': 'neutrophile Segmentkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lymp',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mono',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hypch',
                                    'short_text': 'Hypochromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poch',
                                    'short_text': 'Polychromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'anis',
                                    'short_text': 'Anisozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poik',
                                    'short_text': 'Poikilozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'akant',
                                    'short_text': 'Akanthozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '10',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kerns-cll',
                                    'short_text': 'Kernschatten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mizy',
                                    'short_text': 'Mikrozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'urinstix',
                        'text_long': 'Urindiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 45,
                        'values': [
                            {
                                'value': '2.2',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbcm',
                                    'short_text': 'Erythroblasten (mikroskopisch)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '78',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.16',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.4',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 11923,
                'modified_at': '2024-09-23 12:06:06.859943+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:06:06.859970+00:00',
                'observation_date_time': '2024-09-23 12:06:06.859974+00:00',
                'reported_date_time': '2024-09-23 12:06:06.859977+00:00',
                'filler_order_number': '71910351',
                'placer_order_number': '71910351',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.00',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.3',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.7',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.6',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.8',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '147',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.90',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.0',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.23',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12100,
                'modified_at': '2024-09-23 12:06:39.988641+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:06:39.988667+00:00',
                'observation_date_time': '2024-09-23 12:06:39.988672+00:00',
                'reported_date_time': '2024-09-23 12:06:39.988675+00:00',
                'filler_order_number': '71910413',
                'placer_order_number': '71910413',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.1',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.0',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.4',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '146',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.00',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.3',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.5',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.23',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12148,
                'modified_at': '2024-09-23 12:06:53.116156+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:06:53.116183+00:00',
                'observation_date_time': '2024-09-23 12:06:53.116188+00:00',
                'reported_date_time': '2024-09-23 12:06:53.116191+00:00',
                'filler_order_number': '27859774',
                'placer_order_number': '27859774',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '43.8',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12199,
                'modified_at': '2024-09-23 12:07:05.029078+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:07:05.029162+00:00',
                'observation_date_time': '2024-09-23 12:07:05.029172+00:00',
                'reported_date_time': '2024-09-23 12:07:05.029177+00:00',
                'filler_order_number': '27858403',
                'placer_order_number': '27858403',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12203,
                'modified_at': '2024-09-23 12:07:05.625834+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 12:07:05.625860+00:00',
                'observation_date_time': '2024-09-23 12:07:05.625864+00:00',
                'reported_date_time': '2024-09-23 12:07:05.625868+00:00',
                'filler_order_number': '27858403',
                'placer_order_number': '27858403',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.0',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.62',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.9',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '34.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '79',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.7',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.6',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '2.4',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12218,
                'modified_at': '2024-09-23 12:07:10.251728+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, sonstiges, urinstix, Gerinnung',
                'received_date_time': '2024-09-23 12:07:10.251756+00:00',
                'observation_date_time': '2024-09-23 12:07:10.251761+00:00',
                'reported_date_time': '2024-09-23 12:07:10.251765+00:00',
                'filler_order_number': '27858403',
                'placer_order_number': '27858403',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '12.7',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.0',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.62',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.9',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '34.0',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '79',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.6',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2',
                                'limits': '< 5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eos',
                                    'short_text': 'eosinophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bas',
                                    'short_text': 'basophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myel',
                                    'short_text': 'Myelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '7',
                                'limits': '< 1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'meta',
                                    'short_text': 'Metamyelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13',
                                'limits': '<8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'stab',
                                    'short_text': 'neutrophile Stabkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '69',
                                'limits': '36 - 84',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'seg',
                                    'short_text': 'neutrophile Segmentkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3',
                                'limits': '20 - 42',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lymp',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1',
                                'limits': '< 15',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mono',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poch',
                                    'short_text': 'Polychromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'anis',
                                    'short_text': 'Anisozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poik',
                                    'short_text': 'Poikilozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '5',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kerns-cll',
                                    'short_text': 'Kernschatten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'urinstix',
                        'text_long': 'Urindiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 45,
                        'values': [
                            {
                                'value': '4.1',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbcm',
                                    'short_text': 'Erythroblasten (mikroskopisch)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '2.4',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12263,
                'modified_at': '2024-09-23 12:07:23.306041+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:07:23.306071+00:00',
                'observation_date_time': '2024-09-23 12:07:23.306075+00:00',
                'reported_date_time': '2024-09-23 12:07:23.306079+00:00',
                'filler_order_number': '71910489',
                'placer_order_number': '71910489',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.51',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.3',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.1',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '148',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.4',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.5',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.23',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.1',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12340,
                'modified_at': '2024-09-23 12:07:48.335810+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:07:48.335837+00:00',
                'observation_date_time': '2024-09-23 12:07:48.335841+00:00',
                'reported_date_time': '2024-09-23 12:07:48.335845+00:00',
                'filler_order_number': '71910527',
                'placer_order_number': '71910527',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.70',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.9',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.7',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '36',
                                'limits': '< 17',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '148',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.8',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.2',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.24',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.6',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12343,
                'modified_at': '2024-09-23 12:07:48.975763+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 12:07:48.975791+00:00',
                'observation_date_time': '2024-09-23 12:07:48.975795+00:00',
                'reported_date_time': '2024-09-23 12:07:48.975799+00:00',
                'filler_order_number': '71910529',
                'placer_order_number': '71910529',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.60',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.40',
                                'limits': '7,35 - 7,43',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,9 - 6,7',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.0',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.3',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.1',
                                'limits': '4,8 - 5,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '72',
                                'limits': '70 - 80',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.25',
                                'limits': '1,17 - 1,27',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '35',
                                'limits': '<17',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibgav',
                                    'short_text': 'Bilirubin, gesamt (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '147',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.6',
                                'limits': '4,1 - 6,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.3',
                                'limits': '<0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methbv',
                                    'short_text': 'Met-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12347,
                'modified_at': '2024-09-23 12:07:49.962834+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:07:49.962862+00:00',
                'observation_date_time': '2024-09-23 12:07:49.962866+00:00',
                'reported_date_time': '2024-09-23 12:07:49.962870+00:00',
                'filler_order_number': '71910527',
                'placer_order_number': '71910527',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.70',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.9',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.7',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '36',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '148',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.24',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.6',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12348,
                'modified_at': '2024-09-23 12:07:50.286677+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 12:07:50.286707+00:00',
                'observation_date_time': '2024-09-23 12:07:50.286712+00:00',
                'reported_date_time': '2024-09-23 12:07:50.286716+00:00',
                'filler_order_number': '71910529',
                'placer_order_number': '71910529',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.60',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.40',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.3',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.1',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '72',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.25',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '35',
                                'limits': '<17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibgav',
                                    'short_text': 'Bilirubin, gesamt (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '147',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.6',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.3',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methbv',
                                    'short_text': 'Met-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12384,
                'modified_at': '2024-09-23 12:07:58.636168+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:07:58.636195+00:00',
                'observation_date_time': '2024-09-23 12:07:58.636200+00:00',
                'reported_date_time': '2024-09-23 12:07:58.636204+00:00',
                'filler_order_number': '27859777',
                'placer_order_number': '27859777',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12410,
                'modified_at': '2024-09-23 12:08:02.185054+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:08:02.185081+00:00',
                'observation_date_time': '2024-09-23 12:08:02.185085+00:00',
                'reported_date_time': '2024-09-23 12:08:02.185089+00:00',
                'filler_order_number': '27859777',
                'placer_order_number': '27859777',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.21',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.28',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.4',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '108',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.1',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.5',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12439,
                'modified_at': '2024-09-23 12:08:08.384577+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:08:08.384606+00:00',
                'observation_date_time': '2024-09-23 12:08:08.384610+00:00',
                'reported_date_time': '2024-09-23 12:08:08.384614+00:00',
                'filler_order_number': '27859777',
                'placer_order_number': '27859777',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.21',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.28',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.4',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '108',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.1',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.5',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.16',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '39.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '195',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12447,
                'modified_at': '2024-09-23 12:08:10.686522+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:08:10.686549+00:00',
                'observation_date_time': '2024-09-23 12:08:10.686554+00:00',
                'reported_date_time': '2024-09-23 12:08:10.686557+00:00',
                'filler_order_number': '27859777',
                'placer_order_number': '27859777',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.21',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.28',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.4',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '108',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.1',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.5',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.16',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '39.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '146',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.06',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '116',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.88',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '45.1',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '24.3',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '66.9',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '157',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.7',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '694',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '195',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '36.2',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82.7',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '18.6',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12585,
                'modified_at': '2024-09-23 12:09:03.779801+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:09:03.779829+00:00',
                'observation_date_time': '2024-09-23 12:09:03.779833+00:00',
                'reported_date_time': '2024-09-23 12:09:03.779837+00:00',
                'filler_order_number': '27859777',
                'placer_order_number': '27859777',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.21',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.28',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.4',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '108',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.1',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.5',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.16',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '39.5',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '49',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '146',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.06',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '116',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.88',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '45.1',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '24.3',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '66.9',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '157',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.7',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '694',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '195',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '36.2',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82.7',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '18.6',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12748,
                'modified_at': '2024-09-23 12:09:57.115109+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Chemie, Endo, Stoffwech',
                'received_date_time': '2024-09-23 12:09:57.115140+00:00',
                'observation_date_time': '2024-09-23 12:09:57.115145+00:00',
                'reported_date_time': '2024-09-23 12:09:57.115149+00:00',
                'filler_order_number': '37191895',
                'placer_order_number': '37191895',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.26',
                                'limits': '< 1,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'trig',
                                    'short_text': 'Triglyceride',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.3',
                                'limits': '35 - 52',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alb',
                                    'short_text': 'Albumin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Endo',
                        'text_long': 'Endokrinologie',
                        'icon_name': '',
                        'color': 'violett',
                        'sort_order': 70,
                        'values': [
                            {
                                'value': '2.65',
                                'limits': '3,1 - 6,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ft3',
                                    'short_text': 'FT3',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'pmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.6',
                                'limits': '11,9 - 21,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ft4',
                                    'short_text': 'FT4',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'pmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.164',
                                'limits': '0,27 - 4,2',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tsh',
                                    'short_text': 'TSH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Stoffwech',
                        'text_long': 'Stoffwechsel',
                        'icon_name': '',
                        'color': 'violett',
                        'sort_order': 80,
                        'values': [
                            {
                                'value': '68.3',
                                'limits': '> 50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': '25ohd',
                                    'short_text': '25-OH-Vitamin D',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'nmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Bei Werten zwischen 75 -125 nmol/l geht man von einer ad\u00e4quaten Versorgung mit 25OH-Vitamin D aus.'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12825,
                'modified_at': '2024-09-23 12:10:25.260039+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, sonstiges, urinstix, Gerinnung',
                'received_date_time': '2024-09-23 12:10:25.260066+00:00',
                'observation_date_time': '2024-09-23 12:10:25.260070+00:00',
                'reported_date_time': '2024-09-23 12:10:25.260074+00:00',
                'filler_order_number': '27856175',
                'placer_order_number': '27856175',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.8',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.58',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.3',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.86',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.6',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '41.0',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '82',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.6',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '30.4',
                                'limits': '2 - 8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuaa',
                                    'short_text': 'Neutrophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.53',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgran',
                                    'short_text': 'Unreife Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.75',
                                'limits': '1 - 3,2',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyaa',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.57',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moaa',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.24',
                                'limits': '< 0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoaa',
                                    'short_text': 'Eosinophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.14',
                                'limits': '< 0,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'baa',
                                    'short_text': 'Basophile',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '73.0',
                                'limits': '36 - 84',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'neuar',
                                    'short_text': 'Neutrophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13.3',
                                'limits': '<2',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'imgranr',
                                    'short_text': 'Unreife Granulozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.6',
                                'limits': '20 - 42',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lyar',
                                    'short_text': 'Lymphozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.8',
                                'limits': '< 15',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'moar',
                                    'short_text': 'Monozyten %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.0',
                                'limits': '< 5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eoar',
                                    'short_text': 'Eosinophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.3',
                                'limits': '< 1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bar',
                                    'short_text': 'Basophile %',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.7',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0',
                                'limits': '< 5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eos',
                                    'short_text': 'eosinophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0',
                                'limits': '< 1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bas',
                                    'short_text': 'basophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myel',
                                    'short_text': 'Myelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2',
                                'limits': '< 1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'meta',
                                    'short_text': 'Metamyelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '10',
                                'limits': '<8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'stab',
                                    'short_text': 'neutrophile Stabkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '72',
                                'limits': '36 - 84',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'seg',
                                    'short_text': 'neutrophile Segmentkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1',
                                'limits': '20 - 42',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lymp',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1',
                                'limits': '< 15',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mono',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hypch',
                                    'short_text': 'Hypochromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poch',
                                    'short_text': 'Polychromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'anis',
                                    'short_text': 'Anisozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+++',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poik',
                                    'short_text': 'Poikilozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'akant',
                                    'short_text': 'Akanthozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '10',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kerns-cll',
                                    'short_text': 'Kernschatten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '++',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mizy',
                                    'short_text': 'Mikrozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'urinstix',
                        'text_long': 'Urindiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 45,
                        'values': [
                            {
                                'value': '2.2',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbcm',
                                    'short_text': 'Erythroblasten (mikroskopisch)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '78',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.16',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.4',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12873,
                'modified_at': '2024-09-23 12:10:43.748715+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, sonstiges, urinstix, Gerinnung',
                'received_date_time': '2024-09-23 12:10:43.748742+00:00',
                'observation_date_time': '2024-09-23 12:10:43.748746+00:00',
                'reported_date_time': '2024-09-23 12:10:43.748750+00:00',
                'filler_order_number': '27858403',
                'placer_order_number': '27858403',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.0',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.62',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.9',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.91',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '34.0',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '79',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.7',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3.6',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2',
                                'limits': '< 5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'eos',
                                    'short_text': 'eosinophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0',
                                'limits': '< 1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bas',
                                    'short_text': 'basophile Granulozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myel',
                                    'short_text': 'Myelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '7',
                                'limits': '< 1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'meta',
                                    'short_text': 'Metamyelozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '13',
                                'limits': '<8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'stab',
                                    'short_text': 'neutrophile Stabkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '69',
                                'limits': '36 - 84',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'seg',
                                    'short_text': 'neutrophile Segmentkernige',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '3',
                                'limits': '20 - 42',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lymp',
                                    'short_text': 'Lymphozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1',
                                'limits': '< 15',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mono',
                                    'short_text': 'Monozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poch',
                                    'short_text': 'Polychromasie',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'anis',
                                    'short_text': 'Anisozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '+',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'poik',
                                    'short_text': 'Poikilozytose',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '5',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kerns-cll',
                                    'short_text': 'Kernschatten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'urinstix',
                        'text_long': 'Urindiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 45,
                        'values': [
                            {
                                'value': '4.1',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbcm',
                                    'short_text': 'Erythroblasten (mikroskopisch)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '2.4',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 12874,
                'modified_at': '2024-09-23 12:10:44.040467+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:10:44.040495+00:00',
                'observation_date_time': '2024-09-23 12:10:44.040499+00:00',
                'reported_date_time': '2024-09-23 12:10:44.040503+00:00',
                'filler_order_number': '27859774',
                'placer_order_number': '27859774',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '43.8',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 13034,
                'modified_at': '2024-09-23 12:11:36.486298+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:11:36.486324+00:00',
                'observation_date_time': '2024-09-23 12:11:36.486328+00:00',
                'reported_date_time': '2024-09-23 12:11:36.486332+00:00',
                'filler_order_number': '27859777',
                'placer_order_number': '27859777',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.3',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.21',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.28',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.4',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.9',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.6',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '108',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.1',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.5',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '79',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.16',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '39.5',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '49',
                                'limits': '75,2 - 154,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '146',
                                'limits': '136 - 145',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.8',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.06',
                                'limits': '2,20 - 2,55',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '116',
                                'limits': '98 - 107',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.88',
                                'limits': '0,74 - 1,03',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.00',
                                'limits': '0,81 - 1,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '45.1',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '24.3',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '66.9',
                                'limits': '< 60',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '157',
                                'limits': '< 190',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '19.7',
                                'limits': '< 25',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '694',
                                'limits': '135 - 255',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '195',
                                'limits': '< 14',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '36.2',
                                'limits': '<24',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82.7',
                                'limits': '59 - 104',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '18.6',
                                'limits': '2,76 - 8,07',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 13265,
                'modified_at': '2024-09-23 12:12:16.726089+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:12:16.726117+00:00',
                'observation_date_time': '2024-09-23 12:12:16.726121+00:00',
                'reported_date_time': '2024-09-23 12:12:16.726125+00:00',
                'filler_order_number': '71910647',
                'placer_order_number': '71910647',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?3.9',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.5',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?25.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?1.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.61',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?95.2',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '147',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.20',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 13270,
                'modified_at': '2024-09-23 12:12:18.194079+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:12:18.194106+00:00',
                'observation_date_time': '2024-09-23 12:12:18.194110+00:00',
                'reported_date_time': '2024-09-23 12:12:18.194114+00:00',
                'filler_order_number': '71910650',
                'placer_order_number': '71910650',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '10.1',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '146',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '116',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.3',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '3.5',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.18',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 13961,
                'modified_at': '2024-09-23 12:15:32.289243+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:15:32.289272+00:00',
                'observation_date_time': '2024-09-23 12:15:32.289277+00:00',
                'reported_date_time': '2024-09-23 12:15:32.289281+00:00',
                'filler_order_number': '71910751',
                'placer_order_number': '71910751',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.80',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.45',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.6',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '20',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '146',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.8',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.23',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 14421,
                'modified_at': '2024-09-23 12:17:26.368407+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:17:26.368452+00:00',
                'observation_date_time': '2024-09-23 12:17:26.368457+00:00',
                'reported_date_time': '2024-09-23 12:17:26.368461+00:00',
                'filler_order_number': '27852334',
                'placer_order_number': '27852334',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '39.7',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 14464,
                'modified_at': '2024-09-23 12:17:36.080126+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung, Infektion',
                'received_date_time': '2024-09-23 12:17:36.080153+00:00',
                'observation_date_time': '2024-09-23 12:17:36.080158+00:00',
                'reported_date_time': '2024-09-23 12:17:36.080161+00:00',
                'filler_order_number': '27852334',
                'placer_order_number': '27852334',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '39.7',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '146',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 14494,
                'modified_at': '2024-09-23 12:17:44.514347+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung, Infektion, Chemie',
                'received_date_time': '2024-09-23 12:17:44.514374+00:00',
                'observation_date_time': '2024-09-23 12:17:44.514378+00:00',
                'reported_date_time': '2024-09-23 12:17:44.514382+00:00',
                'filler_order_number': '27852334',
                'placer_order_number': '27852334',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '39.7',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '146',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.09',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15000,
                'modified_at': '2024-09-23 12:20:12.285094+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:20:12.285124+00:00',
                'observation_date_time': '2024-09-23 12:20:12.285129+00:00',
                'reported_date_time': '2024-09-23 12:20:12.285132+00:00',
                'filler_order_number': '71910965',
                'placer_order_number': '71910965',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.70',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.20',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '96',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.23',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15032,
                'modified_at': '2024-09-23 12:20:22.118560+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:20:22.118588+00:00',
                'observation_date_time': '2024-09-23 12:20:22.118593+00:00',
                'reported_date_time': '2024-09-23 12:20:22.118596+00:00',
                'filler_order_number': '37519590',
                'placer_order_number': '37519590',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '73',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '314',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '453',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '895',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.400',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '559',
                                'limits': '28 - 72',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'myo',
                                    'short_text': 'Myoglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.004',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015! 6,12md/dl/1000*0,621= 0,0038mmol/l'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15033,
                'modified_at': '2024-09-23 12:20:22.315970+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:20:22.315997+00:00',
                'observation_date_time': '2024-09-23 12:20:22.316001+00:00',
                'reported_date_time': '2024-09-23 12:20:22.316005+00:00',
                'filler_order_number': '37337227',
                'placer_order_number': '37337227',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '79',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '377',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '462',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1006',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.400',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.005',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15257,
                'modified_at': '2024-09-23 12:21:40.796719+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:21:40.796748+00:00',
                'observation_date_time': '2024-09-23 12:21:40.796753+00:00',
                'reported_date_time': '2024-09-23 12:21:40.796756+00:00',
                'filler_order_number': '37191897',
                'placer_order_number': '37191897',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '64',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '230',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '438',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '660',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.7',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.270',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.004',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15308,
                'modified_at': '2024-09-23 12:21:59.381034+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung, Infektion, Chemie',
                'received_date_time': '2024-09-23 12:21:59.381063+00:00',
                'observation_date_time': '2024-09-23 12:21:59.381067+00:00',
                'reported_date_time': '2024-09-23 12:21:59.381071+00:00',
                'filler_order_number': '27852334',
                'placer_order_number': '27852334',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '39.7',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '146',
                                'limits': '<5,0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.09',
                                'limits': '<0,046',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15566,
                'modified_at': '2024-09-23 12:23:50.054355+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 12:23:50.054385+00:00',
                'observation_date_time': '2024-09-23 12:23:50.054389+00:00',
                'reported_date_time': '2024-09-23 12:23:50.054393+00:00',
                'filler_order_number': '37191894',
                'placer_order_number': '37191894',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '71',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '168',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '440',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '789',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.6',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.360',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15632,
                'modified_at': '2024-09-23 12:24:04.683876+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:24:04.683905+00:00',
                'observation_date_time': '2024-09-23 12:24:04.683910+00:00',
                'reported_date_time': '2024-09-23 12:24:04.683914+00:00',
                'filler_order_number': '27787765',
                'placer_order_number': '27787765',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '35.7',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15794,
                'modified_at': '2024-09-23 12:24:42.553544+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:24:42.553576+00:00',
                'observation_date_time': '2024-09-23 12:24:42.553581+00:00',
                'reported_date_time': '2024-09-23 12:24:42.553585+00:00',
                'filler_order_number': '71911031',
                'placer_order_number': '71911031',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.80',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.3',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.21',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15909,
                'modified_at': '2024-09-23 12:24:59.288103+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:24:59.288131+00:00',
                'observation_date_time': '2024-09-23 12:24:59.288135+00:00',
                'reported_date_time': '2024-09-23 12:24:59.288139+00:00',
                'filler_order_number': '37517112',
                'placer_order_number': '37517112',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '77',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '203',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '409',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '904',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.5',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.350',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.003',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15910,
                'modified_at': '2024-09-23 12:24:59.432433+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:24:59.432463+00:00',
                'observation_date_time': '2024-09-23 12:24:59.432467+00:00',
                'reported_date_time': '2024-09-23 12:24:59.432470+00:00',
                'filler_order_number': '37191894',
                'placer_order_number': '37191894',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '71',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '168',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '440',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '789',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.6',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.360',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.004',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 15924,
                'modified_at': '2024-09-23 12:25:00.679679+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:25:00.679712+00:00',
                'observation_date_time': '2024-09-23 12:25:00.679729+00:00',
                'reported_date_time': '2024-09-23 12:25:00.679733+00:00',
                'filler_order_number': '27787765',
                'placer_order_number': '27787765',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '35.7',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16074,
                'modified_at': '2024-09-23 12:25:50.408507+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:25:50.408535+00:00',
                'observation_date_time': '2024-09-23 12:25:50.408539+00:00',
                'reported_date_time': '2024-09-23 12:25:50.408543+00:00',
                'filler_order_number': '71911083',
                'placer_order_number': '71911083',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.90',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24.9',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.5',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '13.1',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '146',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.1',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.5',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.19',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16075,
                'modified_at': '2024-09-23 12:25:50.660984+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 12:25:50.661011+00:00',
                'observation_date_time': '2024-09-23 12:25:50.661015+00:00',
                'reported_date_time': '2024-09-23 12:25:50.661019+00:00',
                'filler_order_number': '71911084',
                'placer_order_number': '71911084',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.10',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.39',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.1',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '71',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.23',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '15',
                                'limits': '<17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibgav',
                                    'short_text': 'Bilirubin, gesamt (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '113',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.4',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.6',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.1',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methbv',
                                    'short_text': 'Met-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16135,
                'modified_at': '2024-09-23 12:26:07.851068+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:26:07.851096+00:00',
                'observation_date_time': '2024-09-23 12:26:07.851100+00:00',
                'reported_date_time': '2024-09-23 12:26:07.851103+00:00',
                'filler_order_number': '27856818',
                'placer_order_number': '27856818',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '34.4',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16201,
                'modified_at': '2024-09-23 12:26:20.434060+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:26:20.434101+00:00',
                'observation_date_time': '2024-09-23 12:26:20.434105+00:00',
                'reported_date_time': '2024-09-23 12:26:20.434108+00:00',
                'filler_order_number': '71911140',
                'placer_order_number': '71911140',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?4.3',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.6',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?25.2',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.9',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '20.9',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?99.9',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.9',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.0',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.19',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16203,
                'modified_at': '2024-09-23 12:26:21.011510+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:26:21.011536+00:00',
                'observation_date_time': '2024-09-23 12:26:21.011541+00:00',
                'reported_date_time': '2024-09-23 12:26:21.011544+00:00',
                'filler_order_number': '71911140',
                'placer_order_number': '71911140',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?4.3',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.6',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?25.2',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '20.9',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?99.9',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.9',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.19',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16204,
                'modified_at': '2024-09-23 12:26:21.274377+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:26:21.274404+00:00',
                'observation_date_time': '2024-09-23 12:26:21.274408+00:00',
                'reported_date_time': '2024-09-23 12:26:21.274412+00:00',
                'filler_order_number': '71911142',
                'placer_order_number': '71911142',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '6.40',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '30.2',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.3',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '15.0',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '98',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '150',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.00',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '113',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.09',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16207,
                'modified_at': '2024-09-23 12:26:22.057290+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:26:22.057346+00:00',
                'observation_date_time': '2024-09-23 12:26:22.057350+00:00',
                'reported_date_time': '2024-09-23 12:26:22.057353+00:00',
                'filler_order_number': '71911145',
                'placer_order_number': '71911145',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.0',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '21.1',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.22',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16214,
                'modified_at': '2024-09-23 12:26:23.659631+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:26:23.659660+00:00',
                'observation_date_time': '2024-09-23 12:26:23.659664+00:00',
                'reported_date_time': '2024-09-23 12:26:23.659668+00:00',
                'filler_order_number': '27640517',
                'placer_order_number': '27640517',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.0',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.68',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '86.9',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.87',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.4',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '80',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.7',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16222,
                'modified_at': '2024-09-23 12:26:25.833099+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 12:26:25.833129+00:00',
                'observation_date_time': '2024-09-23 12:26:25.833150+00:00',
                'reported_date_time': '2024-09-23 12:26:25.833153+00:00',
                'filler_order_number': '27640517',
                'placer_order_number': '27640517',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.0',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.68',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '86.9',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.87',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.4',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '80',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.7',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.3',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': 'kein Material',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': 'kein Material',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16270,
                'modified_at': '2024-09-23 12:26:36.305940+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:26:36.305967+00:00',
                'observation_date_time': '2024-09-23 12:26:36.305971+00:00',
                'reported_date_time': '2024-09-23 12:26:36.305975+00:00',
                'filler_order_number': '27861062',
                'placer_order_number': '27861062',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '40.9',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16283,
                'modified_at': '2024-09-23 12:26:39.351049+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:26:39.351077+00:00',
                'observation_date_time': '2024-09-23 12:26:39.351081+00:00',
                'reported_date_time': '2024-09-23 12:26:39.351085+00:00',
                'filler_order_number': '71911196',
                'placer_order_number': '71911196',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.1',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '23.3',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.5',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.24',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16284,
                'modified_at': '2024-09-23 12:26:39.592114+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:26:39.592141+00:00',
                'observation_date_time': '2024-09-23 12:26:39.592145+00:00',
                'reported_date_time': '2024-09-23 12:26:39.592149+00:00',
                'filler_order_number': '71911197',
                'placer_order_number': '71911197',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.41',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26.2',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.47',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '78',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '21',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '146',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.20',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.24',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16299,
                'modified_at': '2024-09-23 12:26:43.585912+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:26:43.585969+00:00',
                'observation_date_time': '2024-09-23 12:26:43.585988+00:00',
                'reported_date_time': '2024-09-23 12:26:43.585992+00:00',
                'filler_order_number': '27861061',
                'placer_order_number': '27861061',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16304,
                'modified_at': '2024-09-23 12:26:44.436912+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:26:44.436939+00:00',
                'observation_date_time': '2024-09-23 12:26:44.436944+00:00',
                'reported_date_time': '2024-09-23 12:26:44.436947+00:00',
                'filler_order_number': '27861061',
                'placer_order_number': '27861061',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.59',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.0',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.4',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '78',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.0',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16400,
                'modified_at': '2024-09-23 12:27:05.882758+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Chemie, Infektion',
                'received_date_time': '2024-09-23 12:27:05.882799+00:00',
                'observation_date_time': '2024-09-23 12:27:05.882819+00:00',
                'reported_date_time': '2024-09-23 12:27:05.882822+00:00',
                'filler_order_number': '27861061',
                'placer_order_number': '27861061',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.59',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.0',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.4',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '78',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.0',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '145',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.5',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.16',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '114',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.98',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.93',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '43.3',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.3',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '56.9',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '156',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.0',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '674',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '178',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82.9',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '81',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '16.0',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.972',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '187',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16411,
                'modified_at': '2024-09-23 12:27:08.685023+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 12:27:08.685105+00:00',
                'observation_date_time': '2024-09-23 12:27:08.685110+00:00',
                'reported_date_time': '2024-09-23 12:27:08.685126+00:00',
                'filler_order_number': '27861061',
                'placer_order_number': '27861061',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.59',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.0',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.4',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '78',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.0',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '93',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.05',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '44.6',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.7',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '145',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.5',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.16',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '114',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.98',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.93',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '43.3',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.3',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '56.9',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '156',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.0',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '674',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '178',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82.9',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '81',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '16.0',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.972',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '187',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16412,
                'modified_at': '2024-09-23 12:27:09.060304+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:27:09.060334+00:00',
                'observation_date_time': '2024-09-23 12:27:09.060338+00:00',
                'reported_date_time': '2024-09-23 12:27:09.060342+00:00',
                'filler_order_number': '71911231',
                'placer_order_number': '71911231',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.40',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.49',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.4',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.9',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.6',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18.6',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27',
                                'limits': '< 17',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.4',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.5',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.21',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '1.2',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16425,
                'modified_at': '2024-09-23 12:27:13.619927+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:27:13.619957+00:00',
                'observation_date_time': '2024-09-23 12:27:13.619961+00:00',
                'reported_date_time': '2024-09-23 12:27:13.619964+00:00',
                'filler_order_number': '71911231',
                'placer_order_number': '71911231',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.40',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.49',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.9',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.6',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18.6',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '27',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.30',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.4',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.5',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.21',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '1.2',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16471,
                'modified_at': '2024-09-23 12:27:25.651099+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 12:27:25.651127+00:00',
                'observation_date_time': '2024-09-23 12:27:25.651132+00:00',
                'reported_date_time': '2024-09-23 12:27:25.651135+00:00',
                'filler_order_number': '27861061',
                'placer_order_number': '27861061',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.59',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.0',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.4',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '78',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.0',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '93',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.05',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '44.6',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.7',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '48',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '145',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.5',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.16',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '114',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.98',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.93',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '43.3',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.3',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '56.9',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '156',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.0',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '674',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '178',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82.9',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '81',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '16.0',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.972',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '187',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16531,
                'modified_at': '2024-09-23 12:27:44.036091+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 12:27:44.036132+00:00',
                'observation_date_time': '2024-09-23 12:27:44.036137+00:00',
                'reported_date_time': '2024-09-23 12:27:44.036140+00:00',
                'filler_order_number': '27861061',
                'placer_order_number': '27861061',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.59',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.0',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.89',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '27.4',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '78',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.0',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.0',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '93',
                                'limits': '70 - 130',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.05',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '44.6',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.7',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '48',
                                'limits': '75,2 - 154,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '145',
                                'limits': '136 - 145',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '4.5',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.16',
                                'limits': '2,20 - 2,55',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '114',
                                'limits': '98 - 107',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.98',
                                'limits': '0,74 - 1,03',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.93',
                                'limits': '0,81 - 1,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '43.3',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.3',
                                'limits': '<50',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '56.9',
                                'limits': '< 60',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '156',
                                'limits': '< 190',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.0',
                                'limits': '< 25',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '674',
                                'limits': '135 - 255',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '178',
                                'limits': '< 14',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '<24',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '82.9',
                                'limits': '59 - 104',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '81',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '16.0',
                                'limits': '2,76 - 8,07',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.972',
                                'limits': '<0,046',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '187',
                                'limits': '<5,0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16544,
                'modified_at': '2024-09-23 12:27:46.796152+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:27:46.796180+00:00',
                'observation_date_time': '2024-09-23 12:27:46.796184+00:00',
                'reported_date_time': '2024-09-23 12:27:46.796188+00:00',
                'filler_order_number': '27861062',
                'placer_order_number': '27861062',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '40.9',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.4',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16561,
                'modified_at': '2024-09-23 12:27:50.102489+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 12:27:50.102515+00:00',
                'observation_date_time': '2024-09-23 12:27:50.102519+00:00',
                'reported_date_time': '2024-09-23 12:27:50.102523+00:00',
                'filler_order_number': '27640517',
                'placer_order_number': '27640517',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.0',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.23',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.68',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '86.9',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.87',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.5',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '28.4',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '80',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.7',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '6.3',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': 'kein Material',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': 'kein Material',
                                'limits': '2,00 - 3,93',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16576,
                'modified_at': '2024-09-23 12:27:53.296070+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:27:53.296098+00:00',
                'observation_date_time': '2024-09-23 12:27:53.296103+00:00',
                'reported_date_time': '2024-09-23 12:27:53.296106+00:00',
                'filler_order_number': '27856818',
                'placer_order_number': '27856818',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '34.4',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 16836,
                'modified_at': '2024-09-23 12:28:37.222163+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:28:37.222192+00:00',
                'observation_date_time': '2024-09-23 12:28:37.222196+00:00',
                'reported_date_time': '2024-09-23 12:28:37.222200+00:00',
                'filler_order_number': '71911322',
                'placer_order_number': '71911322',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.00',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.45',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24.9',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.5',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18.7',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '21',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '144',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '113',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.4',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.3',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.21',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 17452,
                'modified_at': '2024-09-23 12:31:16.337052+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie',
                'received_date_time': '2024-09-23 12:31:16.337083+00:00',
                'observation_date_time': '2024-09-23 12:31:16.337087+00:00',
                'reported_date_time': '2024-09-23 12:31:16.337091+00:00',
                'filler_order_number': '37519891',
                'placer_order_number': '37519891',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '52',
                                'limits': '83 - 128',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ata',
                                    'short_text': 'AT III',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '214',
                                'limits': '50 - 150',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8',
                                    'short_text': 'Gerinnungsfaktor VIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'kontrollierter Wert'
                            },
                            {
                                'value': '404',
                                'limits': '48,8 - 163,4',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ac',
                                    'short_text': 'F VIII vWF:Ac',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '590',
                                'limits': '66,1 - 176,3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8ag',
                                    'short_text': 'F VIII vWF:Ag',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.7',
                                'limits': '>0,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f8acagq',
                                    'short_text': 'Faktor VIII Ac/Ag Ratio',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Verdacht auf einen qualitativen Defekt des vWF (vWS Typ 2). Zur Absicherung der Diagnose wird eine Multimeranalytik empfohlen.'
                            },
                            {
                                'value': '0.240',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ufh',
                                    'short_text': 'unfraktioniertes Heparin (Anti-Xa,quant.)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'IU/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '0.002',
                                'limits': '<0,003',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 17800,
                'modified_at': '2024-09-23 12:33:26.117572+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:33:26.117601+00:00',
                'observation_date_time': '2024-09-23 12:33:26.117605+00:00',
                'reported_date_time': '2024-09-23 12:33:26.117609+00:00',
                'filler_order_number': '71911748',
                'placer_order_number': '71911748',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.47',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.6',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.2',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?18.5',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Inhomogene Probe\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '144',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.70',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?9.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Glu nicht benutzbar\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.16',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 18093,
                'modified_at': '2024-09-23 12:34:34.033532+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:34:34.033579+00:00',
                'observation_date_time': '2024-09-23 12:34:34.033607+00:00',
                'reported_date_time': '2024-09-23 12:34:34.033613+00:00',
                'filler_order_number': '27860460',
                'placer_order_number': '27860460',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '49.0',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 18176,
                'modified_at': '2024-09-23 12:35:02.386255+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:35:02.386283+00:00',
                'observation_date_time': '2024-09-23 12:35:02.386287+00:00',
                'reported_date_time': '2024-09-23 12:35:02.386291+00:00',
                'filler_order_number': '71911811',
                'placer_order_number': '71911811',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?4.0',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.40',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?25.8',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?1.7',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.90',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?81.5',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.80',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '112',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.1',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.0',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.24',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 18184,
                'modified_at': '2024-09-23 12:35:05.141014+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:35:05.141042+00:00',
                'observation_date_time': '2024-09-23 12:35:05.141047+00:00',
                'reported_date_time': '2024-09-23 12:35:05.141050+00:00',
                'filler_order_number': '71911811',
                'placer_order_number': '71911811',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '?4.0',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.40',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?25.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?1.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.90',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?81.5',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?4.0',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.40',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.8',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?25.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?1.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.9',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?81.5',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.24',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '<17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibgav',
                                    'short_text': 'Bilirubin, gesamt (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ Inhomogene Probe\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -20\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.80',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.80',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '112',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '112',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.1',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.1',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.0',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '?0.0',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methbv',
                                    'short_text': 'Met-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.24',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '<',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'ParameterValueHasQuestionMarkErrorMessage\\S\\ Tr\u00fcbung zu hoch\\S\\ OXI spectrum mismatch\\S\\ Wert unterhalb berichtbarem Messbereich < -2,0\\S\\ Point of Care Test (POCT)'
                            },
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 18210,
                'modified_at': '2024-09-23 12:35:13.128276+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 12:35:13.128422+00:00',
                'observation_date_time': '2024-09-23 12:35:13.128432+00:00',
                'reported_date_time': '2024-09-23 12:35:13.128437+00:00',
                'filler_order_number': '71911822',
                'placer_order_number': '71911822',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '4.90',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.39',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.9',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.7',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.6',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '88',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.22',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25',
                                'limits': '<17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibgav',
                                    'short_text': 'Bilirubin, gesamt (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '144',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.80',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '113',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '11.0',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.4',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methbv',
                                    'short_text': 'Met-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 18460,
                'modified_at': '2024-09-23 12:36:43.094204+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:36:43.094233+00:00',
                'observation_date_time': '2024-09-23 12:36:43.094237+00:00',
                'reported_date_time': '2024-09-23 12:36:43.094241+00:00',
                'filler_order_number': '27860460',
                'placer_order_number': '27860460',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '49.0',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19231,
                'modified_at': '2024-09-23 12:40:57.591553+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:40:57.591581+00:00',
                'observation_date_time': '2024-09-23 12:40:57.591585+00:00',
                'reported_date_time': '2024-09-23 12:40:57.591588+00:00',
                'filler_order_number': '71912018',
                'placer_order_number': '71912018',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.50',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.48',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.4',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.3',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '29.5',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '28',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.50',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '113',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.2',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.19',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.8',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19319,
                'modified_at': '2024-09-23 12:41:20.900798+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:41:20.900845+00:00',
                'observation_date_time': '2024-09-23 12:41:20.900975+00:00',
                'reported_date_time': '2024-09-23 12:41:20.900981+00:00',
                'filler_order_number': '27838963',
                'placer_order_number': '27838963',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19328,
                'modified_at': '2024-09-23 12:41:22.770187+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:41:22.770217+00:00',
                'observation_date_time': '2024-09-23 12:41:22.770221+00:00',
                'reported_date_time': '2024-09-23 12:41:22.770225+00:00',
                'filler_order_number': '27838963',
                'placer_order_number': '27838963',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.1',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.78',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.1',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.84',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.1',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '96',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.4',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.9',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19341,
                'modified_at': '2024-09-23 12:41:24.749519+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 12:41:24.749548+00:00',
                'observation_date_time': '2024-09-23 12:41:24.749552+00:00',
                'reported_date_time': '2024-09-23 12:41:24.749556+00:00',
                'filler_order_number': '27838963',
                'placer_order_number': '27838963',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.1',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.78',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.1',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.84',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.1',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '96',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.4',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.9',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '53.1',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19536,
                'modified_at': '2024-09-23 12:42:06.347565+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:42:06.347594+00:00',
                'observation_date_time': '2024-09-23 12:42:06.347598+00:00',
                'reported_date_time': '2024-09-23 12:42:06.347602+00:00',
                'filler_order_number': '71912073',
                'placer_order_number': '71912073',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.1',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.1',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '8.99',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '95',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '115',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.2',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.6',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.19',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.5',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19571,
                'modified_at': '2024-09-23 12:42:14.083728+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung',
                'received_date_time': '2024-09-23 12:42:14.083755+00:00',
                'observation_date_time': '2024-09-23 12:42:14.083759+00:00',
                'reported_date_time': '2024-09-23 12:42:14.083762+00:00',
                'filler_order_number': '27838963',
                'placer_order_number': '27838963',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '5.1',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.25',
                                'limits': '0,4 - 0,51',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.78',
                                'limits': '4,4 - 5,77',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '88.1',
                                'limits': '81 - 96',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.84',
                                'limits': '1,55 - 1,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.8',
                                'limits': '18,5 - 22,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '21.1',
                                'limits': '4 - 9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '96',
                                'limits': '150 - 450',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '11.4',
                                'limits': '9 - 13',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.9',
                                'limits': '0',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '53.1',
                                'limits': '25,1 - 36,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19870,
                'modified_at': '2024-09-23 12:43:57.383477+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:43:57.383506+00:00',
                'observation_date_time': '2024-09-23 12:43:57.383511+00:00',
                'reported_date_time': '2024-09-23 12:43:57.383514+00:00',
                'filler_order_number': '71912156',
                'placer_order_number': '71912156',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.60',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.45',
                                'limits': '7,37 - 7,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '4,7 - 6,1',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.8',
                                'limits': '21 - 26',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.6',
                                'limits': '-2 - 3',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '22.1',
                                'limits': '9,5 - 13,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.80',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.8',
                                'limits': '3,9 - 5,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.5',
                                'limits': '< 0,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.21',
                                'limits': '1,15 - 1,29',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19874,
                'modified_at': '2024-09-23 12:43:58.394299+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:43:58.394328+00:00',
                'observation_date_time': '2024-09-23 12:43:58.394333+00:00',
                'reported_date_time': '2024-09-23 12:43:58.394336+00:00',
                'filler_order_number': '71912156',
                'placer_order_number': '71912156',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.60',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.45',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.9',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.6',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '22.1',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.80',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.8',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.5',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.21',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19875,
                'modified_at': '2024-09-23 12:43:58.633775+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 12:43:58.633802+00:00',
                'observation_date_time': '2024-09-23 12:43:58.633807+00:00',
                'reported_date_time': '2024-09-23 12:43:58.633810+00:00',
                'filler_order_number': '71912158',
                'placer_order_number': '71912158',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.40',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.37',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.4',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.8',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.9',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '77',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.24',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '143',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.60',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '118',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.9',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '6.6',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19911,
                'modified_at': '2024-09-23 12:44:09.915022+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'Gerinnung',
                'received_date_time': '2024-09-23 12:44:09.915049+00:00',
                'observation_date_time': '2024-09-23 12:44:09.915054+00:00',
                'reported_date_time': '2024-09-23 12:44:09.915057+00:00',
                'filler_order_number': '27826189',
                'placer_order_number': '27826189',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '42.3',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 19993,
                'modified_at': '2024-09-23 12:44:30.538177+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:44:30.538204+00:00',
                'observation_date_time': '2024-09-23 12:44:30.538209+00:00',
                'reported_date_time': '2024-09-23 12:44:30.538212+00:00',
                'filler_order_number': '71912227',
                'placer_order_number': '71912227',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.00',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.0',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '25.3',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.0',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '18.4',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '100',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '32',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '114',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.5',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.5',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.20',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '1.3',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 20088,
                'modified_at': '2024-09-23 12:44:54.891164+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:44:54.891191+00:00',
                'observation_date_time': '2024-09-23 12:44:54.891195+00:00',
                'reported_date_time': '2024-09-23 12:44:54.891199+00:00',
                'filler_order_number': '71912283',
                'placer_order_number': '71912283',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.44',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.7',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '-0.1',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.59',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '97',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '34',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '143',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.10',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '113',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.0',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.21',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.9',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 20089,
                'modified_at': '2024-09-23 12:44:55.149496+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, sonstiges',
                'received_date_time': '2024-09-23 12:44:55.149523+00:00',
                'observation_date_time': '2024-09-23 12:44:55.149527+00:00',
                'reported_date_time': '2024-09-23 12:44:55.149531+00:00',
                'filler_order_number': '71912284',
                'placer_order_number': '71912284',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.30',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbgav',
                                    'short_text': 'H\u00e4moglobin (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.39',
                                'limits': '7,35 - 7,43',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phv',
                                    'short_text': 'pH-Wert (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.7',
                                'limits': '4,9 - 6,7',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2v',
                                    'short_text': 'CO2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbcv',
                                    'short_text': 'Standardbicarbonat (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.5',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bev',
                                    'short_text': 'Basen\u00fcberschu\u00df (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.1',
                                'limits': '4,8 - 5,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2v',
                                    'short_text': 'O2-Partialdruck (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '73',
                                'limits': '70 - 80',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2satv',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '1.24',
                                'limits': '1,17 - 1,27',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caiov',
                                    'short_text': 'Calcium, ionisiert ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '144',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabgav',
                                    'short_text': 'Natrium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '5.80',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbgav',
                                    'short_text': 'Kalium (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '111',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbgav',
                                    'short_text': 'Chlorid (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.8',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbgav',
                                    'short_text': 'Lactat (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '12.1',
                                'limits': '4,1 - 6,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbgav',
                                    'short_text': 'Glucose (BGA) ven\u00f6s',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.8',
                                'limits': '<0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohbv',
                                    'short_text': 'CO-Hb (ven\u00f6s)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': 'ven\u00f6s',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 20164,
                'modified_at': '2024-09-23 12:45:12.345024+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma',
                'received_date_time': '2024-09-23 12:45:12.345053+00:00',
                'observation_date_time': '2024-09-23 12:45:12.345057+00:00',
                'reported_date_time': '2024-09-23 12:45:12.345061+00:00',
                'filler_order_number': '27719118',
                'placer_order_number': '27719118',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.65',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.85',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.1',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '72',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 20214,
                'modified_at': '2024-09-23 12:45:26.861173+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Chemie',
                'received_date_time': '2024-09-23 12:45:26.861200+00:00',
                'observation_date_time': '2024-09-23 12:45:26.861205+00:00',
                'reported_date_time': '2024-09-23 12:45:26.861209+00:00',
                'filler_order_number': '27719118',
                'placer_order_number': '27719118',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.65',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.85',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.1',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '72',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '141',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.3',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '110',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 20228,
                'modified_at': '2024-09-23 12:45:32.194358+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Chemie, Infektion',
                'received_date_time': '2024-09-23 12:45:32.194386+00:00',
                'observation_date_time': '2024-09-23 12:45:32.194390+00:00',
                'reported_date_time': '2024-09-23 12:45:32.194393+00:00',
                'filler_order_number': '27719118',
                'placer_order_number': '27719118',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.65',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.85',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.1',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '72',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '141',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.3',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.21',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '110',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.88',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.09',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '37.9',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.9',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '256',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '14.4',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '523',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '154',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '35.4',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '77.1',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '87',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '14.1',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            },
                            {
                                'value': '0.524',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '156',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 20251,
                'modified_at': '2024-09-23 12:45:40.420702+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'H\u00e4ma, Gerinnung, Chemie, Infektion',
                'received_date_time': '2024-09-23 12:45:40.420730+00:00',
                'observation_date_time': '2024-09-23 12:45:40.420734+00:00',
                'reported_date_time': '2024-09-23 12:45:40.420738+00:00',
                'filler_order_number': '27719118',
                'placer_order_number': '27719118',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'H\u00e4ma',
                        'text_long': 'H\u00e4matologie',
                        'icon_name': '',
                        'color': 'rot',
                        'sort_order': 20,
                        'values': [
                            {
                                'value': '4.9',
                                'limits': '8,6 - 12',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hb',
                                    'short_text': 'H\u00e4moglobin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.24',
                                'limits': '0,4 - 0,51',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hk',
                                    'short_text': 'H\u00e4matokrit',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.65',
                                'limits': '4,4 - 5,77',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ery',
                                    'short_text': 'Erythrozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E12/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '90.2',
                                'limits': '81 - 96',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mcv',
                                    'short_text': 'MCV',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.85',
                                'limits': '1,55 - 1,9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mch',
                                    'short_text': 'MCH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fmol',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '20.5',
                                'limits': '18,5 - 22,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mchc',
                                    'short_text': 'MCHC',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '18.1',
                                'limits': '4 - 9',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'leuko',
                                    'short_text': 'Leukozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Erythroblasten korrigierte Leukozytenzahl'
                            },
                            {
                                'value': '72',
                                'limits': '150 - 450',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'plt',
                                    'short_text': 'Thrombozyten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '10E9/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '12.2',
                                'limits': '9 - 13',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mpv',
                                    'short_text': 'Mittleres Thrombozytenvolumen',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'fl',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.0',
                                'limits': '0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nrbc',
                                    'short_text': 'Erythroblasten',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '/100 Leuko',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Gerinnung',
                        'text_long': 'H\u00e4mostaseologie',
                        'icon_name': '',
                        'color': 'gr\u00fcn',
                        'sort_order': 30,
                        'values': [
                            {
                                'value': '93',
                                'limits': '70 - 130',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tpz',
                                    'short_text': 'Quick (befundet)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.05',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'inr',
                                    'short_text': 'INR',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '44.6',
                                'limits': '25,1 - 36,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ptt',
                                    'short_text': 'PTT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 's',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.3',
                                'limits': '2,00 - 3,93',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'fibc',
                                    'short_text': 'Fibrinogen (Clauss)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'g/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '31',
                                'limits': '75,2 - 154,8',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'f13',
                                    'short_text': 'Gerinnungsfaktor XIII',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '141',
                                'limits': '136 - 145',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'na',
                                    'short_text': 'Natrium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '5.3',
                                'limits': '3,4 - 4,5',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'k',
                                    'short_text': 'Kalium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '2.21',
                                'limits': '2,20 - 2,55',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ca',
                                    'short_text': 'Calcium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '110',
                                'limits': '98 - 107',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cl',
                                    'short_text': 'Chlorid',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '0.88',
                                'limits': '0,74 - 1,03',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'mg',
                                    'short_text': 'Magnesium',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '1.09',
                                'limits': '0,81 - 1,45',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'phos',
                                    'short_text': 'Phosphat',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '37.9',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'asat',
                                    'short_text': 'ASAT (GOT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '17.5',
                                'limits': '<50',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'alat',
                                    'short_text': 'ALAT (GPT)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '46.9',
                                'limits': '< 60',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ggt',
                                    'short_text': 'Gamma-GT',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '256',
                                'limits': '< 190',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ck',
                                    'short_text': 'CK',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '14.4',
                                'limits': '< 25',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckmb',
                                    'short_text': 'CK-MB',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '523',
                                'limits': '135 - 255',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ldh',
                                    'short_text': 'LDH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'U/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '154',
                                'limits': '< 14',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'tnt-l',
                                    'short_text': 'cardiales Troponin-T, hochsensitiv',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '35.4',
                                'limits': '<24',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilig',
                                    'short_text': 'Bilirubin, gesamt',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '77.1',
                                'limits': '59 - 104',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crea',
                                    'short_text': 'Creatinin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '87',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ckdepicreagfr',
                                    'short_text': 'CKD-EPI-GFR (nur Creatinin)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ml/min/1.73m\u00b2',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Einteilung nach KDIGO bei chronischer Nierenerkrankung: Stadium G2, geringgradige Funktionseinschr\u00e4nkung'
                            },
                            {
                                'value': '14.1',
                                'limits': '2,76 - 8,07',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hst',
                                    'short_text': 'Harnstoff',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            },
                            {
                                'value': '',
                                'limits': '',
                                'status': 'P',
                                'abnormal_flags': '',
                                'method': {
                                    'code': 'fhb',
                                    'short_text': 'H\u00e4moglobin, freies',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Rohrposttransport nicht empfohlen, siehe Laborinfo 10/2015!'
                            },
                            {
                                'value': '0.524',
                                'limits': '<0,046',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pct',
                                    'short_text': 'Procalcitonin',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'ng/ml',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    },
                    {
                        'text_short': 'Infektion',
                        'text_long': 'Infektionsdiagnostik',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 100,
                        'values': [
                            {
                                'value': '156',
                                'limits': '<5,0',
                                'status': 'P',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'crp',
                                    'short_text': 'CRP',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mg/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': ''
                            }
                        ]
                    }
                ]
            },
            {
                'id': 20652,
                'modified_at': '2024-09-23 12:47:00.704415+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:47:00.704443+00:00',
                'observation_date_time': '2024-09-23 12:47:00.704448+00:00',
                'reported_date_time': '2024-09-23 12:47:00.704452+00:00',
                'filler_order_number': '71912457',
                'placer_order_number': '71912457',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.46',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.5',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24.5',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.1',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14.2',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '26',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '144',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.10',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '113',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '10.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.17',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.8',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            },
            {
                'id': 20786,
                'modified_at': '2024-09-23 12:47:37.088217+00:00',
                'modified_by': 'oru-import',
                'result_groups': 'BGA, Chemie, sonstiges',
                'received_date_time': '2024-09-23 12:47:37.088249+00:00',
                'observation_date_time': '2024-09-23 12:47:37.088254+00:00',
                'reported_date_time': '2024-09-23 12:47:37.088257+00:00',
                'filler_order_number': '71912481',
                'placer_order_number': '71912481',
                'order_control': 'RE',
                'verified_by': 'LABOR',
                'functional_service_unit': {
                    'code': 'KAIIPIT1',
                    'short_text': 'importiert',
                    'long_text': null
                },
                'groups': [
                    {
                        'text_short': 'BGA',
                        'text_long': 'POC BGA',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 55,
                        'values': [
                            {
                                'value': '5.20',
                                'limits': '8,6 - 12',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'hbbga',
                                    'short_text': 'H\u00e4moglobin (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '7.43',
                                'limits': '7,37 - 7,45',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'ph',
                                    'short_text': 'pH',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.8',
                                'limits': '4,7 - 6,1',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'pco2',
                                    'short_text': 'pCO2',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24.4',
                                'limits': '21 - 26',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'sbc',
                                    'short_text': 'Standardbicarbonat (SBC)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '-0.1',
                                'limits': '-2 - 3',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'be',
                                    'short_text': 'Basen\u00fcberschu\u00df (BE)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '14.3',
                                'limits': '9,5 - 13,9',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'po2',
                                    'short_text': 'O2-Partialdruck (PO2)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'kPa',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '99',
                                'limits': '95 - 98,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'o2sat',
                                    'short_text': 'Sauerstoffs\u00e4ttigung (O2Sat)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '24',
                                'limits': '< 17',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bilibga',
                                    'short_text': 'Bilirubin, gesamt (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '\u00b5mol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '145',
                                'limits': '136 - 146',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'nabga',
                                    'short_text': 'Natrium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '4.40',
                                'limits': '3,4 - 4,5',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'kbga',
                                    'short_text': 'Kalium (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '112',
                                'limits': '98 - 106',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'clbga',
                                    'short_text': 'Chlorid (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '0.7',
                                'limits': '0,5 - 1,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'lacbga',
                                    'short_text': 'Lactat (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '9.6',
                                'limits': '3,9 - 5,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'glucbga',
                                    'short_text': 'Glucose (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': '2.7',
                                'limits': '< 0,8',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'cohb',
                                    'short_text': 'CO-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'Chemie',
                        'text_long': 'Klinische Chemie',
                        'icon_name': '',
                        'color': 'orange',
                        'sort_order': 10,
                        'values': [
                            {
                                'value': '1.18',
                                'limits': '1,15 - 1,29',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'caio',
                                    'short_text': 'Calcium, ionisiert',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': 'mmol/l',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    },
                    {
                        'text_short': 'sonstiges',
                        'text_long': 'sonstiges',
                        'icon_name': '',
                        'color': '',
                        'sort_order': 999,
                        'values': [
                            {
                                'value': '0.5',
                                'limits': '0,2 - 0,6',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'methb',
                                    'short_text': 'Met-Hb (arteriell)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '%',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            },
                            {
                                'value': 'arteriell',
                                'limits': '',
                                'status': 'F',
                                'abnormal_flags': 'N',
                                'method': {
                                    'code': 'bga_styp',
                                    'short_text': 'Art der Probenentnahme (BGA)',
                                    'long_text': ''
                                },
                                'unit': {
                                    'code': '',
                                    'short_text': '',
                                    'long_text': ''
                                },
                                'comments': 'Point of Care Test (POCT)'
                            }
                        ]
                    }
                ]
            }
        ]
    }
};

export const dummyVC_LabReportOld: VC_LabReport = {
    id: '7660ea17-7dfe-49bf-8a28-a9bb6bdf86d1',
    related_case_id: '850e8400-e29b-41d4-a716-446655440333',
    locator: 'case.record.lab.850e8400-e29b-41d4-a716-446655440333',
    owners: ['9fbd1d64-c6f7-4b2d-8121-333cd9844562'],
    owner_departments: ['cardiology'],
    main_owner_job_type: 'doctor',
    created_at: '2024-08-09T12:00:00Z',
    status: 'final',
    related_patient_id: '550e8400-e29b-41d4-a716-446655440000',
    data: {
        results: [
            {
                id: 20928,
                modified_at: '2024-09-04 13:56:25.818046+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Endo',
                received_date_time: '2024-09-04 13:56:25.818189+00:00',
                observation_date_time: '2024-09-02 13:56:25.818215+00:00',
                reported_date_time: '2024-09-04 13:56:25.818240+00:00',
                filler_order_number: '37484878',
                placer_order_number: '37484878',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Häma',
                        text_long: 'Hämatologie',
                        icon_name: '',
                        color: 'rot',
                        sort_order: 20,
                        values: [
                            {
                                value: '8.0',
                                limits: '7,4 - 9,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hb',
                                    short_text: 'Hämoglobin',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Hämoglobin-Wert im Normbereich, keine Anzeichen für Anämie.'
                            },
                            {
                                value: '0.39',
                                limits: '0,35 - 0,47',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hk',
                                    short_text: 'Hämatokrit',
                                    long_text: ''
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Hämatokrit normal, zeigt keine Dehydratation oder Überwässerung an.'
                            },
                            {
                                value: '5.07',
                                limits: '3,85 - 5,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ery',
                                    short_text: 'Erythrozyten',
                                    long_text: ''
                                },
                                unit: {
                                    code: '10E12/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Erythrozytenzahl normal, keine Hinweise auf Anämie oder Polyzythämie.'
                            },
                            {
                                value: '77.3',
                                limits: '81 - 96',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mcv',
                                    short_text: 'MCV',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'MCV leicht unter dem Normbereich, könnte auf eine Mikrozytose hinweisen.'
                            },
                            {
                                value: '1.58',
                                limits: '1,55 - 1,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mch',
                                    short_text: 'MCH',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'fmol',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Mittleres korpuskuläres Hämoglobin innerhalb des Normbereichs.'
                            },
                            {
                                value: '20.4',
                                limits: '18,5 - 22,5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mchc',
                                    short_text: 'MCHC',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'MCHC zeigt keine Anzeichen für Hypochromie.'
                            },
                            {
                                value: '8.80',
                                limits: '4 - 9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'leuko',
                                    short_text: 'Leukozyten',
                                    long_text: ''
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Leukozytenzahl normal, keine Hinweise auf Infektion oder Entzündung.'
                            },
                            {
                                value: '378',
                                limits: '150 - 450',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'plt',
                                    short_text: 'Thrombozyten',
                                    long_text: ''
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Thrombozytenzahl im Normbereich, normale Gerinnungsfunktion.'
                            },
                            {
                                value: '10.3',
                                limits: '9 - 13',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mpv',
                                    short_text: 'Mittleres Thrombozytenvolumen',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'MPV im Normbereich, zeigt keine Anomalien in der Thrombozytengröße an.'
                            }
                        ]
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '15.9',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'ASAT-Wert im Normbereich, keine Hinweise auf Leberzellschädigung.'
                            },
                            {
                                value: '15.4',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'ALAT normal, keine Zeichen einer akuten Leberentzündung.'
                            },
                            {
                                value: '16.9',
                                limits: '< 40',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ggt',
                                    short_text: 'Gamma-GT',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Gamma-GT im Normbereich, keine Anzeichen für Gallengangserkrankungen.'
                            },
                            {
                                value: '37.5',
                                limits: '35 - 52',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alb',
                                    short_text: 'Albumin',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Albumin im Normbereich, keine Hinweise auf Leber- oder Nierenerkrankungen.'
                            },
                            {
                                value: '71.2',
                                limits: '66 - 87',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'prot',
                                    short_text: 'Protein',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Gesamtprotein im Normbereich, keine Proteinverlustkrankheiten erkennbar.'
                            },
                            {
                                value: '9.21',
                                limits: '<15',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'bilig',
                                    short_text: 'Bilirubin, gesamt',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'µmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Bilirubin-Wert normal, keine Anzeichen für Ikterus oder Leberstörungen.'
                            }
                        ]
                    },
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '14.0',
                                limits: '3,1 - 6,8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'FT3 erhöht, weitere Untersuchungen auf Hyperthyreose empfohlen.'
                            },
                            {
                                value: '56.1',
                                limits: '11,9 - 21,6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'FT4-Wert erhöht, mögliche Hyperthyreose.'
                            },
                            {
                                value: '<0.050',
                                limits: '0,27 - 4,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'µIU/ml',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'TSH stark erniedrigt, deutet auf Hyperthyreose hin.'
                            }
                        ]
                    }
                ]
            },
            {
                id: 20978,
                modified_at: '2024-09-04 14:01:41.534029+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Endo',
                received_date_time: '2024-09-04 14:01:41.534255+00:00',
                observation_date_time: '2024-09-04 14:01:41.534287+00:00',
                reported_date_time: '2024-09-04 14:01:41.534313+00:00',
                filler_order_number: '37484878',
                placer_order_number: '37484878',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'H\u00e4ma',
                        text_long: 'H\u00e4matologie',
                        icon_name: '',
                        color: 'rot',
                        sort_order: 20,
                        values: [
                            {
                                value: '8.2',
                                limits: '7,4 - 9,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hb',
                                    short_text: 'H\u00e4moglobin',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '0.39',
                                limits: '0,35 - 0,47',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hk',
                                    short_text: 'H\u00e4matokrit',
                                    long_text: ''
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '5.07',
                                limits: '3,85 - 5,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ery',
                                    short_text: 'Erythrozyten',
                                    long_text: ''
                                },
                                unit: {
                                    code: '10E12/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '77.3',
                                limits: '81 - 96',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mcv',
                                    short_text: 'MCV',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '1.58',
                                limits: '1,55 - 1,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mch',
                                    short_text: 'MCH',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'fmol',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '20.4',
                                limits: '18,5 - 22,5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mchc',
                                    short_text: 'MCHC',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '8.80',
                                limits: '4 - 9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'leuko',
                                    short_text: 'Leukozyten',
                                    long_text: ''
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '378',
                                limits: '150 - 450',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'plt',
                                    short_text: 'Thrombozyten',
                                    long_text: ''
                                },
                                unit: {
                                    code: '10E9/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '10.3',
                                limits: '9 - 13',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mpv',
                                    short_text: 'Mittleres Thrombozytenvolumen',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '15.9',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '15.4',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '16.9',
                                limits: '< 40',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ggt',
                                    short_text: 'Gamma-GT',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '37.5',
                                limits: '35 - 52',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alb',
                                    short_text: 'Albumin',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '71.2',
                                limits: '66 - 87',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'prot',
                                    short_text: 'Protein',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'g/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '9.21',
                                limits: '<15',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'bilig',
                                    short_text: 'Bilirubin, gesamt',
                                    long_text: ''
                                },
                                unit: {
                                    code: '\u00b5mol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    },
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '14.0',
                                limits: '3,1 - 6,8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '56.1',
                                limits: '11,9 - 21,6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '<0.050',
                                limits: '0,27 - 4,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: ''
                                },
                                unit: {
                                    code: '\u00b5IU/ml',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    }
                ]
            },
            {
                id: 21000,
                modified_at: '2024-09-10 10:15:32.123456+00:00',
                modified_by: 'oru-import',
                result_groups: 'H\u00e4ma, Chemie, Endo',
                received_date_time: '2024-09-10 10:15:32.123456+00:00',
                observation_date_time: '2024-09-10 10:15:32.123456+00:00',
                reported_date_time: '2024-09-10 10:15:32.123456+00:00',
                filler_order_number: '37484879',
                placer_order_number: '37484879',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'H\u00e4ma',
                        text_long: 'H\u00e4matologie',
                        icon_name: '',
                        color: 'rot',
                        sort_order: 20,
                        values: [
                            {
                                value: '7.6',
                                limits: '7,4 - 9,9',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hb',
                                    short_text: 'H\u00e4moglobin',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '0.38',
                                limits: '0,35 - 0,47',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'hk',
                                    short_text: 'H\u00e4matokrit',
                                    long_text: ''
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '5.15',
                                limits: '3,85 - 5,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ery',
                                    short_text: 'Erythrozyten',
                                    long_text: ''
                                },
                                unit: {
                                    code: '10E12/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '82.1',
                                limits: '81 - 96',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'mcv',
                                    short_text: 'MCV',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'fl',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '16.7',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '14.9',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    }
                ]
            },
            {
                id: 21001,
                modified_at: '2024-09-11 09:45:21.987654+00:00',
                modified_by: 'oru-import',
                result_groups: 'H\u00e4ma, Chemie, Endo',
                received_date_time: '2024-09-11 09:45:21.987654+00:00',
                observation_date_time: '2024-09-11 09:45:21.987654+00:00',
                reported_date_time: '2024-09-11 09:45:21.987654+00:00',
                filler_order_number: '37484880',
                placer_order_number: '37484880',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '3.9',
                                limits: '3,1 - 6,8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '20.8',
                                limits: '11,9 - 21,6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '0.85',
                                limits: '0,27 - 4,2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: ''
                                },
                                unit: {
                                    code: '\u00b5IU/ml',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '15.3',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'asat',
                                    short_text: 'ASAT (GOT)',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '10.8',
                                limits: '<35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'alat',
                                    short_text: 'ALAT (GPT)',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'U/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    }
                ]
            },
            {
                id: 21002,
                modified_at: '2024-09-12 09:30:45.000000+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Immuno',
                received_date_time: '2024-09-12 09:30:45.000000+00:00',
                observation_date_time: '2024-09-12 09:30:45.000000+00:00',
                reported_date_time: '2024-09-12 09:30:45.000000+00:00',
                filler_order_number: '37484900',
                placer_order_number: '37484900',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Immuno',
                        text_long: 'Immunology',
                        icon_name: '',
                        color: 'green',
                        sort_order: 30,
                        values: [
                            {
                                value: '4.1',
                                limits: '2 - 5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'igG',
                                    short_text: 'IgG',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'g/L',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '0.9',
                                limits: '0.5 - 1.5',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'igM',
                                    short_text: 'IgM',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'g/L',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '3.5',
                                limits: '1.1 - 3.2',
                                status: 'A',
                                abnormal_flags: 'H',
                                method: {
                                    code: 'c-reactive protein',
                                    short_text: 'CRP',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mg/dL',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments:
                                    'Slight elevation noted, further investigation advised.'
                            }
                        ]
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '12.0',
                                limits: '8 - 12',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'bun',
                                    short_text: 'Blood Urea Nitrogen',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mg/dL',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    }
                ]
            },
            {
                id: 21003,
                modified_at: '2024-09-14 11:45:30.000000+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Endo',
                received_date_time: '2024-09-14 11:45:30.000000+00:00',
                observation_date_time: '2024-09-14 11:45:30.000000+00:00',
                reported_date_time: '2024-09-14 11:45:30.000000+00:00',
                filler_order_number: '37484910',
                placer_order_number: '37484910',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '8.5',
                                limits: '3,1 - 6,8',
                                status: 'A',
                                abnormal_flags: 'H',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments:
                                    'Elevated FT3 levels, suggest further evaluation of thyroid function.'
                            },
                            {
                                value: '25.6',
                                limits: '11,9 - 21,6',
                                status: 'A',
                                abnormal_flags: 'H',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '0.02',
                                limits: '0,27 - 4,2',
                                status: 'A',
                                abnormal_flags: 'L',
                                method: {
                                    code: 'tsh',
                                    short_text: 'TSH',
                                    long_text: ''
                                },
                                unit: {
                                    code: '\u00b5IU/ml',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'Low TSH, suggests hyperthyroidism.'
                            }
                        ]
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '1.0',
                                limits: '0.7 - 1.3',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'creatinine',
                                    short_text: 'Creatinine',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mg/dL',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '140.0',
                                limits: '135 - 145',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'sodium',
                                    short_text: 'Sodium',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/L',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    }
                ]
            },
            {
                id: 21004,
                modified_at: '2024-09-15 12:05:15.000000+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Gerinnung',
                received_date_time: '2024-09-15 12:05:15.000000+00:00',
                observation_date_time: '2024-09-15 12:05:15.000000+00:00',
                reported_date_time: '2024-09-15 12:05:15.000000+00:00',
                filler_order_number: '37484920',
                placer_order_number: '37484920',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Gerinnung',
                        text_long: 'Gerinnungsprofil',
                        icon_name: '',
                        color: 'blue',
                        sort_order: 40,
                        values: [
                            {
                                value: '1.2',
                                limits: '0.8 - 1.2',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'inr',
                                    short_text: 'INR',
                                    long_text: ''
                                },
                                unit: {
                                    code: '',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '26.0',
                                limits: '25 - 35',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ptt',
                                    short_text: 'Partial Thromboplastin Time',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'seconds',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '4.5',
                                limits: '3.5 - 5.1',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'potassium',
                                    short_text: 'Potassium',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/L',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '105.0',
                                limits: '98 - 107',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'chloride',
                                    short_text: 'Chloride',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/L',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    }
                ]
            },
            {
                id: 21005,
                modified_at: '2024-09-16 14:20:10.000000+00:00',
                modified_by: 'oru-import',
                result_groups: 'Häma, Chemie, Endo',
                received_date_time: '2024-09-16 14:20:10.000000+00:00',
                observation_date_time: '2024-09-16 14:20:10.000000+00:00',
                reported_date_time: '2024-09-16 14:20:10.000000+00:00',
                filler_order_number: '37484930',
                placer_order_number: '37484930',
                order_control: 'RE',
                verified_by: 'LABOR',
                groups: [
                    {
                        text_short: 'Endo',
                        text_long: 'Endokrinologie',
                        icon_name: '',
                        color: 'violett',
                        sort_order: 70,
                        values: [
                            {
                                value: '7.8',
                                limits: '3.1 - 6.8',
                                status: 'A',
                                abnormal_flags: 'H',
                                method: {
                                    code: 'ft3',
                                    short_text: 'FT3',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: 'FT3 levels slightly elevated.'
                            },
                            {
                                value: '20.3',
                                limits: '11.9 - 21.6',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'ft4',
                                    short_text: 'FT4',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'pmol/l',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    },
                    {
                        text_short: 'Chemie',
                        text_long: 'Klinische Chemie',
                        icon_name: '',
                        color: 'orange',
                        sort_order: 10,
                        values: [
                            {
                                value: '4.2',
                                limits: '3.6 - 4.8',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'albumin',
                                    short_text: 'Albumin',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'g/dL',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            },
                            {
                                value: '135.0',
                                limits: '135 - 145',
                                status: 'F',
                                abnormal_flags: 'N',
                                method: {
                                    code: 'sodium',
                                    short_text: 'Sodium',
                                    long_text: ''
                                },
                                unit: {
                                    code: 'mmol/L',
                                    short_text: '',
                                    long_text: ''
                                },
                                comments: ''
                            }
                        ]
                    }
                ]
            }
        ]
    }
};
