import {Injectable, OnDestroy} from '@angular/core';
import {Capacitor, CapacitorHttp, HttpOptions, HttpResponse} from '@capacitor/core';

import {DeviceRegisterTokenApiResponse} from '../models/auth.model';
import {Device, DeviceRegisterData, PaginatedData, SuccessResponse, User} from '../models/cp2-api.model';

import {Device as CapacitorDevice} from '@capacitor/device';
import {packageJson} from '../../environments/env';
import {environment} from '../../environments/environment';

import {AnonymizationService} from '../helper/anonymization.service';
import {SettingsFacadeService} from './facades/settings-facade.service';
import {Subscription} from 'rxjs';
import {Settings} from '../models/settings.model';
import {
    ExtendedFolder,
    ExtendedForm,
    Folder,
    FolderToForm,
    Form
} from '../pages/admin/form-editor/form-editor.component';

import {
    Case_Overview,
    PatientListGroup,
    VC_Case,
    VC_Consultation,
    VC_Diagnose,
    VC_Diagnostic,
    VC_Discharge,
    VC_Medical_History,
    VC_Post_Treatment,
    VC_Task,
    VC_Therapy,
    VC_Visit_Record,
    VcPatientListItem,
    ViewContent
} from '../models/view-content.models/view-content.model';

import * as dummy from '../shared/view_content_dummy_data';
import {
    Corporation,
    Department,
    Location,
    ServiceUnit
} from '../models/view-content.models/view-content-organization-domain.model';

@Injectable({
    providedIn: 'root'
})
export class Cp2ApiService implements OnDestroy {
    private static readonly TAG = 'Cp2ApiService';
    public activeAnonymous!: boolean;
    private allSubs: Subscription[] = [];

    public constructor(
        private anonymizationService: AnonymizationService,
        private settingsFacade: SettingsFacadeService
    ) {
    }

    ngOnDestroy() {
        this.allSubs.forEach((sub) => sub.unsubscribe());
    }

    //#region Device
    public async requestDeviceRegisterToken(
        accessToken: string,
        userId: number
    ): Promise<DeviceRegisterTokenApiResponse> {
        const response = await CapacitorHttp.post({
            url: environment.apiServer + '/device/token',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}` // Added accessToken in the Authorization header
            },
            data: {userId}
        });

        return response.data;
    }

    //#endregion
    public async getAllCorporations(
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<Corporation[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/authz/auth_data/Corporation',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });
        return response.data.results;
    }

    public async getAllLocations(
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<Location[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/authz/auth_data/Location',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });
        return response.data.results;
    }

    public async getAllDepartments(
        accessToken: string,
        page = 1,
        pageSize = 1000
    ): Promise<Department[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/authz/auth_data/Department',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });
        return response.data.results;
    }

    public async getAllServiceUnits(
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<ServiceUnit[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/authz/auth_data/ServiceUnit',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });
        return response.data.results;
    }

    public async requestDeviceRegister(
        accessToken: string,
        registerToken: string,
        deviceId: string,
        displayName: string,
        user?: User,
        corporation_id?: string,
        location_id?: string,
        department_id?: string,
        service_unit_id?: string
    ): Promise<DeviceRegisterData> {
        const url = environment.apiServer + '/device/register';
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Authorization': `Bearer ${accessToken}`
        };

        const data: any = {
            registerToken,
            deviceId,
            displayName
        };

        if (user) {
            data.user = user;
        }
        if (corporation_id) {
            data.corporation_id = corporation_id;
        }
        if (location_id) {
            data.location_id = location_id;
        }
        if (department_id) {
            data.department_id = department_id;
        }
        if (service_unit_id) {
            data.service_unit_id = service_unit_id;
        }

        const response: SuccessResponse<DeviceRegisterData> =
            await CapacitorHttp.post({
                url,
                headers,
                data
            });

        return response.data;
    }

    public async getAllDevicesPage(
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<PaginatedData<Device>> {
        const response: SuccessResponse<PaginatedData<Device>> =
            await CapacitorHttp.get({
                url: environment.apiServer + '/admin/devices',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'X-Authorization': `Bearer ${accessToken}`
                },
                params: {
                    page: page.toString(),
                    page_size: pageSize.toString()
                }
            });

        return response.data;
    }

    public async getDeviceId(
        deviceId: number,
        accessToken: string
    ): Promise<Device> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/devices/' + deviceId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data;
    }

    public async getDevicesForUserIdPage(
        userId: number,
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<PaginatedData<Device>> {
        const response: SuccessResponse<PaginatedData<Device>> =
            await CapacitorHttp.get({
                url: environment.apiServer + '/admin/users/devices/' + userId,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'X-Authorization': `Bearer ${accessToken}`
                },
                params: {
                    page: page.toString(),
                    page_size: pageSize.toString()
                }
            });

        return response.data;
    }

    public async removeDeviceFromUser(
        deviceId: number,
        userId: number,
        accessToken: string
    ): Promise<{ message: string }> {
        const response: SuccessResponse<{ message: string }> =
            await CapacitorHttp.delete({
                url:
                    environment.apiServer +
                    `/admin/devices/${deviceId}/user/${userId}/`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'X-Authorization': `Bearer ${accessToken}`
                }
            });

        return response.data;
    }

    public async deleteDevice(id: number, accessToken: string): Promise<any> {
        const url = environment.apiServer + `/admin/devices/${id}`;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Authorization': `Bearer ${accessToken}`
        };

        const response = await CapacitorHttp.delete({url, headers});

        return response.data;
    }

    public async patchDevice(
        device: Device,
        accessToken: string
    ): Promise<any> {
        const response = await CapacitorHttp.patch({
            url: environment.apiServer + `/admin/devices/${device.id}/`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            },
            data: {...device}
        });

        return response.data;
    }

    //#region Users
    public async getAllUsersPage(
        accessToken: string,
        page = 1,
        pageSize = 10
    ): Promise<PaginatedData<User>> {
        const httpOptions: HttpOptions = {
            url: environment.apiServer + '/admin/users',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            },
            params: {
                page: page.toString(),
                page_size: pageSize.toString()
            }
        };

        const response: SuccessResponse<PaginatedData<User>> =
            await CapacitorHttp.get(httpOptions);

        return response.data ?? [];
    }

    //#endregion

    // TODO: Rename to getUserInfo
    public async getUsersId(
        userId: number,
        accessToken: string
    ): Promise<User> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/users/' + userId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data;
    }

    public async patchUser(user: User, accessToken: string): Promise<User> {
        const url = environment.apiServer + '/admin/users/' + user.id;
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'X-Authorization': `Bearer ${accessToken}`
        };
        const data = {...user};

        const response: SuccessResponse<User> = await CapacitorHttp.patch({
            url,
            headers,
            data
        });

        return response.data;
    }

    public async postUser(user: User, accessToken: string) {
        const response = await CapacitorHttp.post({
            url: environment.apiServer + '/admin/users',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            },
            data: {...user}
        });

        return response.data;
    }

    // #region viewcontent

    public async getViewContentByLocator(
        locator: string,
        accessToken: string,
        deviceId?: string
    ): Promise<HttpResponse> {
        let header;

        if (deviceId) {
            header = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`,
                'Device-id': deviceId
            };
        } else {
            header = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            };
        }

        const resp = await CapacitorHttp.get({
            url: environment.apiServer + '/viewcontent/locator/' + locator,
            headers: header
        });

        if (resp.status === 400) {
            resp.data = undefined;
            return resp;
        } else {
            return resp;
        }
    }

    public async getCaseListForUser(
        userId: string,
        accessToken: string
    ): Promise<PatientListGroup[]> {
        const response = await CapacitorHttp.get({
            url:
                environment.apiServer +
                '/viewcontent/locator/case.list.' +
                userId,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data;
    }

    // #endregion

    public async getFolder(accessToken: string): Promise<Folder[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/folder/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data.results;
    }

    public async getForms(accessToken: string): Promise<Form[]> {
        const response: any = await CapacitorHttp.get({
            url: environment.apiServer + '/admin/form/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data.results;
    }

    public async getFolderToFormsById(
        folder_id: string,
        accessToken: string
    ): Promise<FolderToForm[]> {
        const response: any = await CapacitorHttp.get({
            url:
                environment.apiServer +
                '/admin/folder_to_form/?folder_id=' +
                folder_id,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data.results;
    }

    public async updateFolderToForm(
        req: FolderToForm,
        accessToken: string
    ): Promise<any> {
        if (accessToken) {
            return await CapacitorHttp.put({
                url:
                    environment.apiServer +
                    '/admin/folder_to_form/' +
                    req.id +
                    '/',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'X-Authorization': `Bearer ${accessToken}`
                },
                data: req
            });
        }
    }

    public async saveNewRelatedFormToFolder(
        req: FolderToForm,
        accessToken: string
    ): Promise<any> {
        if (accessToken) {
            return await CapacitorHttp.post({
                url: environment.apiServer + '/admin/folder_to_form/',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    'X-Authorization': `Bearer ${accessToken}`
                },
                data: req
            });
        }
    }

    public async saveNewFolder(
        folder: Folder,
        accessToken: string
    ): Promise<any> {
        delete folder.id;

        return await CapacitorHttp.post({
            url: environment.apiServer + '/admin/folder/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            },
            data: folder
        });
    }

    public async saveUpdatedFolder(
        folder: Folder,
        accessToken: string
    ): Promise<any> {
        return await CapacitorHttp.put({
            url: environment.apiServer + '/admin/folder/' + folder.id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            },
            data: folder
        });
    }

    public async saveNewForm(form: Form, accessToken: string): Promise<any> {
        delete form.id;

        return await CapacitorHttp.post({
            url: environment.apiServer + '/admin/form/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            },
            data: form
        });
    }

    public async saveUpdatedForm(
        form: Form,
        accessToken: string
    ): Promise<any> {
        return await CapacitorHttp.put({
            url: environment.apiServer + '/admin/form/' + form.id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            },
            data: form
        });
    }

    // TODO: IMPLEMENT ADD FOLDER AND FORM METHOD!!! ASAP!

    public async deleteForm(
        form: ExtendedForm,
        accessToken: string
    ): Promise<any> {
        return await CapacitorHttp.delete({
            url: environment.apiServer + '/admin/form/' + form.id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });
    }

    public async deleteFolder(
        folder: ExtendedFolder,
        accessToken: string
    ): Promise<any> {
        return await CapacitorHttp.delete({
            url: environment.apiServer + '/admin/folder/' + folder.id + '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });
    }

    public async deleteFolderToForm(
        folderToFormId: number,
        accessToken: string
    ): Promise<any> {
        return await CapacitorHttp.delete({
            url:
                environment.apiServer +
                '/admin/folder_to_form/' +
                folderToFormId +
                '/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });
    }

    //#endregion

    public async getPatientFindings(accessToken: string): Promise<any> {
        const response = await CapacitorHttp.get({
            url:
                environment.apiServer +
                '/viewcontent/locator/document.others.10',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data;
    }

    //#region Patient
    // #region Dashboard

    //#endregion

    // #region ViewContent

    public async getAllLocatorsForCaseId(
        caseId: string,
        token: string
    ): Promise<string[]> {
        const path = `/viewcontent/case_locators/${caseId}`;
        const url = environment.apiServer + path;
        const request = {
            url,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Authorization': `Bearer ${token}`
            }
        };
        const response = await CapacitorHttp.get(request);

        return response.data.locators;
    }

    public async getVcForLocator<T>(
        locator: string,
        token: string
    ): Promise<ViewContent<T>> {
        const response = await CapacitorHttp.get({
            url: environment.apiServer + '/viewcontent/locator/' + locator,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Authorization': `Bearer ${token}`
            }
        });

        return response.data;
    }

    public async putViewContent<T>(
        viewContent: ViewContent<T>,
        token: string
    ): Promise<any> {
        const locator = viewContent.locator;
        if (!locator)
            throw Error('Error on PUT ViewContent: no locator found.');

        const path = `/viewcontent/put/${locator}`;
        const request = {
            url: environment.apiServer + path,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Authorization': `Bearer ${token}`
            },
            data: viewContent
        };

        const response = await CapacitorHttp.put(request);
        return response.data;
    }

    public async postViewContent<T>(
        viewContent: ViewContent<T>,
        token: string
    ): Promise<any> {
        const locator = viewContent.locator;
        if (!locator)
            throw Error('Error on POST ViewContent: no locator found.');

        const path = `/viewcontent/post/`;
        const request: HttpOptions = {
            url: environment.apiServer + path,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Authorization': `Bearer ${token}`
            },
            data: viewContent
        };

        const response = await CapacitorHttp.post(request);

        return response.data;
    }

    public async getVcCaseDetails(
        uuid: string,
        accessToken: string
    ): Promise<VC_Case> {
        const response = await CapacitorHttp.get({
            url:
                environment.apiServer +
                '/viewcontent/locator/case.details.' +
                uuid,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data;
    }

    public async getVcCaseListItem(
        uuid: string,
        accessToken: string
    ): Promise<VcPatientListItem> {
        const response = await CapacitorHttp.get({
            url:
                environment.apiServer +
                '/viewcontent/locator/case.list.item.' +
                uuid,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data;
    }

    public async getVcPatientListItem(): Promise<VcPatientListItem[]> {
        return dummy.dummyVC_PatientList;
    }

    public async getVcMedicalHistory(
        uuid: string
    ): Promise<VC_Medical_History> {
        return dummy.dummyVC_Medical_History;
    }

    public async getVcCase(uuid: string): Promise<VC_Case> {
        return dummy.dummyVC_Case;
    }

    public async getVcDiagnose(uuid: string): Promise<VC_Diagnose> {
        return dummy.dummyVC_Diagnose;
    }

    public async getVcTherapy(uuid: string): Promise<VC_Therapy[]> {
        return dummy.dummyVC_Therapy;
    }

    public async getVcVisitRecord(uuid: string): Promise<VC_Visit_Record> {
        return dummy.dummyVC_Visit_Record;
    }

    public async getVcDiagnostic(uuid: string): Promise<VC_Diagnostic[]> {
        return dummy.dummyVC_Diagnostic;
    }

    public async getVcConsultation(uuid: string): Promise<VC_Consultation[]> {
        return dummy.dummyVC_Consultation;
    }

    public async getVcTask(uuid: string): Promise<VC_Task[]> {
        return dummy.dummyVC_Task;
    }

    public async getVcDischarge(uuid: string): Promise<VC_Discharge[]> {
        return dummy.dummyVC_Discharge;
    }

    public async getVcPostTreatment(
        uuid: string
    ): Promise<VC_Post_Treatment[]> {
        return dummy.dummyVC_Post_Treatment;
    }

    public async getVcCaseOverview(uuid: string): Promise<Case_Overview> {
        return dummy.dummyVC_Case_Overview;
    }

    // #endregion

    public async saveTask(task: any, accessToken: string): Promise<any> {
        console.log(task);
        const response = await CapacitorHttp.post({
            url: environment.apiServer + '/communication/task/post/',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'X-Authorization': `Bearer ${accessToken}` // Added accessToken in the Authorization header
            },
            data: task
        });

        return response.data;
    }

    //#region load settings
    private loadInitialSettings(): void {
        const sub: Subscription = this.settingsFacade.settings$.subscribe(
            (settings: Settings) => {
                this.activeAnonymous = settings.activeAnonymous;
            }
        );
        this.allSubs.push(sub);
    }

    private getBuildInformations(): any {
        const germanDateString = new Date().toLocaleDateString('de-DE', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
        return {
            title: 'Build Information',
            data: [
                {key: 'Version', value: packageJson?.version},
                {key: 'Datum des Build\'s', value: germanDateString},
                {key: 'Ersteller', value: packageJson.author?.name},
                {key: 'E-Mail', value: packageJson.author?.email}
            ]
        };
    }

    private async getDeviceInfo() {
        const deviceInfo: any = await CapacitorDevice.getInfo();
        let systemInfo: any;
        try {
            if (Capacitor.getPlatform() === 'ios') {
                systemInfo = {
                    title: 'Device Info',
                    data: [
                        {key: 'Gerätetyp', value: deviceInfo?.model},
                        {key: 'Platform', value: deviceInfo?.platform},
                        {
                            key: 'Betriebssystem',
                            value: deviceInfo?.operatingSystem
                        },
                        {key: 'Hersteller', value: deviceInfo?.manufacturer},
                        {key: 'iOS Version', value: deviceInfo?.iOSVersion},
                        {
                            key: 'Freier Arbeitsspeicher',
                            value: deviceInfo?.memUsed
                        },
                        {
                            key: 'Freier Festplattenspeicher',
                            value: deviceInfo?.realDiskFree
                        }
                    ]
                };
            } else if (Capacitor.getPlatform() === 'android') {
                systemInfo = {
                    title: 'Device Info',

                    data: [
                        {key: 'Gerätetyp', value: deviceInfo?.model},
                        {key: 'Platform', value: deviceInfo?.platform},
                        {
                            key: 'Betriebssystem',
                            value: deviceInfo?.operatingSystem
                        },
                        {key: 'Hersteller', value: deviceInfo?.manufacturer},
                        {
                            key: 'Android Version',
                            value: deviceInfo?.androidSDKVersion
                        },
                        {
                            key: 'Freier Arbeitsspeicher',
                            value: deviceInfo?.memUsed
                        },
                        {
                            key: 'Freier Festplattenspeicher',
                            value: deviceInfo?.realDiskFree
                        }
                    ]
                };
            } else if (Capacitor.getPlatform() === 'electron') {
                systemInfo = {
                    title: 'Device Info',

                    data: [
                        {key: 'Gerätetyp', value: deviceInfo?.model},
                        {key: 'Platform', value: deviceInfo?.platform},
                        {
                            key: 'Betriebssystem',
                            value: deviceInfo?.operatingSystem
                        },
                        {key: 'Hersteller', value: deviceInfo?.manufacturer},
                        {
                            key: 'Betriebssystem Version',
                            value: deviceInfo?.osVersion
                        },
                        {
                            key: 'Freier Arbeitsspeicher',
                            value: deviceInfo?.memUsed
                        },
                        {
                            key: 'Freier Festplattenspeicher',
                            value: deviceInfo?.realDiskFree
                        }
                    ]
                };
            } else if (Capacitor.getPlatform() === 'web') {
                systemInfo = {
                    title: 'Device Info',

                    data: [
                        {key: 'Gerätetyp', value: deviceInfo?.model},
                        {key: 'Platform', value: deviceInfo?.platform},
                        {
                            key: 'Betriebssystem',
                            value: deviceInfo?.operatingSystem
                        },
                        {key: 'Hersteller', value: deviceInfo?.manufacturer},
                        {
                            key: 'Betriebssystem Version',
                            value: deviceInfo?.osVersion
                        }
                    ]
                };
            } else {
                systemInfo = [];
            }
            return systemInfo;
        } catch (err) {
            console.error('Failed to get device infomation:', err);
        }
    }

    private anonymisiertData(data: any) {
    }
}
